<template>
  <span>
    <!-- icon place -->
  </span>
</template>
<script>
/*
  WARN!
  Для корректного наложения цветов!
  В файле SVG свойства fill & stroke нужно устанавливать на "currentColor"
*/
export default {
  name: 'TwIconWrapper',
  props: {
    fileName: {
      type: String,
      require: true
    },
    size: {
      type: Number,
      default: null
    }
  },
  methods: {
    async loadFile () {
      const filePath = await import(`./icon-files/${this.fileName}.svg`)
      if (filePath?.default) {
        const svgStr = await fetch(filePath.default).then(response => response.text())
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(svgStr, 'image/svg+xml')
        const [svgEl] = svgDoc.getElementsByTagName('svg')

        const origWidth = svgEl?.width?.baseVal?.value || null
        const origHeight = svgEl?.height?.baseVal?.value || null

        if (!origWidth || !origHeight) {
          console.warn(this.$options.name, 'BAD icon size: ', origWidth, origHeight)
        }

        if (this.size) {
          svgEl.setAttribute('width', this.size)
          svgEl.setAttribute('height', this.size / (origWidth / origHeight))
        }

        this.$el.appendChild(svgEl)
      } else {
        console.error(this.$options.name, 'filePath - file not found!')
      }
    }
  },
  mounted () {
    if (this.fileName) {
      this.loadFile()
    } else {
      console.error(this.$options.name, 'fileName - required!')
    }
  }
}
</script>

<style scoped>

</style>
