import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Review'

export default {
  show (dealId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`/deal-review/${dealId}/by-realtor`, $config)
  },
  store (reviewId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`/deal-review-data/${reviewId}/by-realtor`, data, $config)
  }
}
