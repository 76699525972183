<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новое письмо
            </div>
            <div v-else-if="data">
              <span>{{ data.theme }}</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> ({{ data.status && data.status.label }})</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
        <template #right>
          <template v-if="!isNew">
            <strong
              v-if="data && data.emailFolder && data.emailFolder.type"
              class="tw-text-h6 tw-text-an-flat-disabled-text"
            >
              {{ data.emailFolder.type.label }}
            </strong>
            <TwPanelActionButton
              v-if="!mainFormEdited && isAllowEditEmail"
              color="blue"
              :disabled="isNew"
              @click.prevent="editMain = !editMain"
            >
              {{ editMain ? 'отменить':'изменить' }}
            </TwPanelActionButton>
          </template>
          <MenuEntityActions
            v-if="permissions"
            :permissions="permissions"
            @delete="deleteCardItem"
          />
        </template>
      </TwPanelTitle>

      <TwPanelContent wrapper-classes="tw-block">
        <EmailMainShow
          v-if="!isNew && !editMain"
          :dictionary="dictionary"
          :info="data"
          @refresh="refresh"
        />
        <EmailMainForm
          v-else
          v-bind="$props"
          :id="data ? data.id : 0"
          :to-person="toPerson"
          :for-deal="forDeal"
          :custom-person-ids="[]"
          @create="onCreated"
          @update="onUpdated"
          @cancel="onCancel"
        />
      </TwPanelContent>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
  </div>
</template>

<script>
import emailAPI from '@/Api/Email/Mail'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import EmailMainForm from '@/views/Email/main/EmailMainForm.vue'
import EmailMainShow from '@/views/Email/main/EmailMainShow.vue'
import MenuEntityActions from '@/components/core/ViewPanel/Card/entity-actions/menuEntityActions.vue'
import PermissionDenied from '@/views/PermissionDenied.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'EmailCard',
  components: {
    TwPanelActionButton,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    PermissionDenied,
    MenuEntityActions,
    EmailMainShow,
    EmailMainForm
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    // инфа о прикрепляемой сделке
    forDeal: {
      default: null
    },
    toPerson: {
      default: null
    }
  },
  data () {
    return {
      API: emailAPI,
      editMain: false,
      mainFormEdited: false
    }
  },
  computed: {
    isAllowEditEmail () {
      // Письмо должно быть в папке черновики и не должно быть ошибок
      return this.data?.emailFolder?.type?.value === 'draft' && this.data?.status?.value.indexOf('error') === -1
    }
  },
  methods: {
    postFetch (data) {
      if (!data.read && data?.emailFolder?.type?.value === 'inbox') {
        this.read()
      }
    }
  }
}
</script>
