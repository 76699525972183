<template>
  <TwFlexCol class="tw-pb-[16px]">
    <TwFlexRow base-classes="tw-flex tw-flex-wrap tw-gap-[28px]">
      <TwFlexCol class="tw-flex-1">
        <template v-if="info.fields && Object.keys(info.fields).length">
          <TwFlexCell
            v-for="(value, key) in info.fields"
            :key="key"
          >
            <TwPanelContentBlockFieldView
              no-data-text="Не указано"
            >
              <template #title>
                {{ fieldsText[info.type.value][key] }}:
              </template>
              <div v-if="value">
                {{ value }}
              </div>
            </TwPanelContentBlockFieldView>
          </TwFlexCell>
        </template>
        <TwFlexCell
          v-else
          class="tw-p-2 tw-text-an-flat-disabled-text tw-text-center"
        >
          Нет полей для заполнения
        </TwFlexCell>
      </TwFlexCol>
      <TwFlexCol class="tw-flex-1">
        <TwFilesListViewByIds
          :file-ids="fileIds"
          title="Прикрепленные файлы:"
          :is-drop-down="false"
        />
      </TwFlexCol>
    </TwFlexRow>
  </TwFlexCol>
</template>

<script>
import { fieldsTextFromTypes } from '@/views/Person/personDict'
import TwFilesListViewByIds from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'

export default {
  name: 'PersonDocumentShow',
  components: {
    TwFilesListViewByIds,
    TwPanelContentBlockFieldView,
    TwFlexCol,
    TwFlexRow,
    TwFlexCell
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  computed: {
    fieldsText () {
      return fieldsTextFromTypes()
    },
    fileIds () {
      return this.info?.files.map(f => f.id) || []
    }
  }
}
</script>

<style scoped>

</style>
