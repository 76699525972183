<template>
  <div class="tw-grid tw-grid-cols-[minmax(250px,_300px),_1fr] 2xl:tw-gap-x-[50px] tw-gap-x-[30px] tw-gap-y-[10px] tw-flex-wrap">
    <div>
      <div class="tw-sticky tw-top-0">
        <h1 class="tw-text-h1 tw-mb-[15px]">
          Заявка на оформление брони
        </h1>
        <template v-if="isReservationTypes">
          <div class="tw-text-[18px] tw-font-medium tw-my-[26px]">
            <span>Текущий статус: </span>
            <div class="tw-text-an-flat-gray-4">
              <template v-if="isNew">
                Новая фиксация
              </template>
              <template v-else-if="showInitData?.draft">
                Черновик
              </template>
              <div
                v-else-if="showInitData?.status"
                class="tw-w-full"
              >
                <div
                  class="tw-mt-[10px]"
                  :style="{ color: showInitData.status?.color }"
                >
                  <b>{{ showInitData.status?.label }}</b>
                </div>
                <div
                  v-if="showInitData.statusChangeDate"
                  class="tw-mt-[5px] tw-text-main-sm2"
                >
                  <div>{{ showInitData.statusChangeDate }}</div>
                </div>
                <div
                  v-if="showInitData.statusComment"
                  class="tw-mt-[5px] tw-border-l-4 tw-p-[5px] tw-text-main-sm2"
                  :style="{ borderColor: showInitData.status?.color }"
                >
                  <i>{{ showInitData.statusComment }}</i>
                </div>
              </div>
              <div
                v-if="showInitData && showInitData.cancelRequest"
                class="tw-mt-[20px]"
              >
                <span class="tw-text-an-flat-black-main">Запрос на отмену:</span>
                <div class="tw-mt-[5px] tw-border-l-4 tw-border-an-flat-red-main tw-p-[5px] tw-text-main-sm2">
                  <i>{{ showInitData.cancelRequest.comment }}</i><br>
                  <i>от {{ showInitData.cancelRequest.createdAt }}</i>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              v-if="!isNew"
              class="tw-text-main-md tw-bg-an-flat-bg-main tw-rounded-2xl tw-px-[25px] tw-py-[18px] tw-my-[26px]"
            >
              <div class="tw-mb-[24px]">
                <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                  Номер бронирования:
                </div>
                <div class="tw-font-semibold">
                  <template v-if="isNew">
                    ~
                  </template>
                  <template v-else-if="showInitData?.id">
                    {{ showInitData.id }}
                  </template>
                </div>
              </div>
              <div class="tw-mb-[24px]">
                <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                  Дата создания:
                </div>
                <div class="tw-font-semibold">
                  <template v-if="isNew">
                    ~
                  </template>
                  <template v-else-if="showInitData?.createdAt">
                    {{ $Global.DateFormat.DateTimeShort(showInitData.createdAt) }}
                  </template>
                  <template v-else>
                    Неизвестно
                  </template>
                </div>
              </div>

              <div v-if="showInitData && showInitData.status && showInitData.status.value === 'confirmed' && (showInitData.confirmedBefore || showInitData.confirmedPrice)">
                <div
                  v-if="showInitData.confirmedBefore"
                  class="tw-mb-[24px]"
                >
                  <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                    Подтверждено до:
                  </div>
                  <div class="">
                    <strong>{{ $Global.DateFormat.DateTimeShort(showInitData.confirmedBefore) }}</strong>
                  </div>
                </div>
                <div v-if="showInitData.confirmedPrice">
                  <div class="tw-text-an-flat-gray-4 tw-mb-[5px]">
                    Потвержденная цена:
                  </div>
                  <div class="">
                    <strong>{{ (showInitData.confirmedPrice || 0).toLocaleString('ru') }}</strong> руб.
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-text-main-lg tw-my-[26px]">
              Заполните следующую информацию:
            </div>
            <div class="tw-text-main-md tw-bg-an-flat-bg-main tw-rounded-2xl tw-px-[25px] tw-py-[28px] tw-my-[26px]">
              <template v-for="(step, index) in stepsList">
                <div
                  v-if="step.visible"
                  :key="`step_${index}`"
                  class="tw-flex tw-gap-[15px] tw-py-[11px] tw-items-center tw-justify-start tw-rounded-sm tw-cursor-pointer hover:tw-bg-an-flat-panel-modal-bg"
                  @click.stop="onClickToStep(index)"
                >
                  <div class="tw-ml-[8px]">
                    <svg
                      v-if="step.value"
                      width="26"
                      height="27"
                      viewBox="0 0 26 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="13"
                        cy="13.0684"
                        r="13"
                        fill="#22C17B"
                      />
                      <path
                        d="M9 13.4387L11.2626 15.5128L16.1111 11.0684"
                        stroke="#F4F6F8"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    <svg
                      v-else
                      width="26"
                      height="27"
                      viewBox="0 0 26 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="13"
                        cy="13.0684"
                        r="13"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                    {{ step.label }}
                  </div>
                </div>
              </template>
            </div>

            <div
              v-if="$v.form.$invalid"
              class="tw-text-an-flat-red-main tw-p-[5px] tw-mt-[5px] tw-text-center"
            >
              * Заполните обязательные поля формы бронирования
            </div>
            <div class="tw-py-[24px] tw-flex tw-flex-wrap tw-gap-2">
              <button
                v-if="isNew || form.draft"
                :disabled="$v.form.$invalid"
                class="tw-w-full tw-rounded-md tw-bg-white tw-border tw-border-solid tw-border-an-flat-green-btn-bg tw-text-an-flat-green-btn-bg tw-py-[18px] tw-font-bold tw-text-[14px] tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
                @click.stop="onSubmit(false)"
              >
                Сохранить черновик
              </button>
              <button
                v-if="isNew || form.draft"
                :disabled="$v.form.$invalid"
                class="tw-w-full tw-rounded-md tw-bg-an-flat-green-btn-bg tw-py-[18px] tw-font-bold tw-text-[14px] tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
                @click.stop="showDialog = true"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5625 6.48828L10.0625 0.988281C9.8125 0.738281 9.4375 0.738281 9.1875 0.988281L7.1875 2.98828C6.875 3.30078 7 3.67578 7.1875 3.86328L7.625 4.30078L5.75 6.17578C4.8125 5.98828 2.25 5.55078 0.875 6.92578C0.625 7.17578 0.625 7.55078 0.875 7.80078L4.4375 11.3633L0.5 15.3008C0.25 15.5508 0.25 15.9258 0.5 16.1758C0.75 16.4258 1.1875 16.3633 1.375 16.1758L5.3125 12.2383L8.875 15.8008C9.25 16.1133 9.625 15.9883 9.75 15.8008C11.125 14.4258 10.6875 11.8633 10.5 10.9258L12.375 9.05078L12.8125 9.48828C13.0625 9.73828 13.4375 9.73828 13.6875 9.48828L15.6875 7.48828C15.8125 7.11328 15.8125 6.73828 15.5625 6.48828ZM13.125 8.05078L12.6875 7.61328C12.4375 7.36328 12.0625 7.36328 11.8125 7.61328L9.25 10.2383C9.0625 10.4258 9 10.6133 9.0625 10.8633C9.25 11.5508 9.5625 13.2383 9.0625 14.3633L2.1875 7.48828C3.25 6.98828 5 7.30078 5.6875 7.48828C5.875 7.55078 6.125 7.48828 6.3125 7.30078L8.875 4.73828C9.25 4.36328 9.0625 4.05078 8.875 3.86328L8.4375 3.42578L9.625 2.30078L14.25 6.92578L13.125 8.05078Z"
                    fill="white"
                  />
                </svg>
                Сохранить и отправить
              </button>
              <RequestToDeveloperCancel
                v-if="form.id && uiParams && uiParams.cancelButton && uiParams.cancelButton.enabled"
                :id="form.id"
                @success="$emit('refresh')"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="tw-overflow-auto">
      <RequestToDeveloperReservationStatus
        v-if="reservationStatus"
        class="tw-mb-5"
        :info="reservationStatus"
      />
      <div
        v-if="!loading"
        class="tw-bg-an-flat-bg-main tw-rounded-2xl lg:tw-p-[44px] tw-p-[22px]"
      >
        <div
          v-if="form.params"
          id="step_0"
        >
          <div class="tw-text-h6 tw-mb-[10px]">
            Объект
          </div>
          <RealtyObjectShortInfoBlock
            v-if="form.params.objectId"
            :id="form.params.objectId"
          />
        </div>
        <template v-if="isReservationTypes">
          <hr class="tw-my-[34px] tw-border-white">
          <div id="step_1">
            <div class="tw-text-h6">
              Способ бронирования
            </div>
            <div class="tw-mb-[20px] tw-mt-[10px]">
              <ReservationTypeSelectRealtyObjectReservation
                v-model="form.params.reservationType"
                :options="allowReservationTypes"
                :disabled="disabledInput"
              />
            </div>
            <div
              v-if="formInfoResult && formInfoResult.data && formInfoResult.data.description"
              class="tw-w-full"
            >
              <TwPanelInfoBlock>
                <template #title>
                  Примечание для риелтора
                </template>
                <template #text>
                  {{ formInfoResult.data.description }}
                </template>
              </TwPanelInfoBlock>
            </div>
          </div>
          <hr class="tw-my-[34px] tw-border-white">
          <div id="step_2">
            <div class="tw-text-h6">
              Данные клиентов
            </div>
            <div class="tw-mb-[20px] tw-mt-[10px]">
              <div
                v-for="(person, index) in personsInfoArray"
                :key="person.id"
                class="tw-flex tw-flex-wrap tw-gap-[16px] tw-mb-[16px] tw-px-[24px] tw-py-[18px] tw-rounded-2xl tw-bg-white"
              >
                <div class="tw-w-full tw-flex tw-justify-between tw-items-center tw-text-main-xs">
                  <div class="tw-text-h5">
                    Клиент №{{ index + 1 }}
                  </div>
                  <ViewPanelLink
                    v-if="form.draft && person.id"
                    :id="person.id"
                    label="Клиент"
                    component="person-show"
                    :callback="createInit"
                  >
                    Изменить данные клиента
                  </ViewPanelLink>
                </div>
                <div class="tw-flex tw-flex-wrap tw-gap-[15px] tw-w-full">
                  <div class="tw-flex-1 tw-min-w-[150px]">
                    <TwInputText
                      class="tw-font-semibold"
                      :error-text="person.surname ? '':'Некорректное значение'"
                      :disabled="true"
                      type="text"
                      placeholder="Фамилия"
                      :value="person.surname"
                      :show-label="true"
                    />
                  </div>
                  <div class="tw-flex-1 tw-min-w-[150px]">
                    <TwInputText
                      class="tw-font-semibold"
                      :error-text="person.name ? '':'Некорректное значение'"
                      :disabled="true"
                      type="text"
                      placeholder="Имя"
                      :value="person.name"
                      :show-label="true"
                    />
                  </div>
                  <div class="tw-flex-1 tw-min-w-[150px]">
                    <TwInputText
                      class="tw-font-semibold"
                      :error-text="person.fatherName ? '':'Некорректное значение'"
                      :disabled="true"
                      type="text"
                      placeholder="Отчество"
                      :value="person.fatherName"
                      :show-label="true"
                    />
                  </div>
                  <div class="tw-flex-1 tw-min-w-[150px]">
                    <TwInputPhone
                      class="tw-font-semibold"
                      :error-text="person.phone ? '':'Некорректное значение'"
                      :disabled="true"
                      placeholder="Номер телефона"
                      :value="person.phone || 'Остутствует'"
                      :show-label="true"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="form.draft"
                class="tw-grow tw-text-an-flat-red-main tw-text-center tw-mt-[10px]"
              >
                Пожалуйста перепроверьте корректность всех данных клиентов
              </div>
            </div>
          </div>
          <hr class="tw-my-[34px] tw-border-white">
          <div id="step_3">
            <div class="tw-text-h6">
              Способ оплаты
            </div>
            <div
              v-if="dictionary && dictionary.paymentMethod"
              class="tw-mb-[20px] tw-mt-[10px]"
            >
              <PaymentMethodSelectRealtyObjectReservation
                v-model="form.params.paymentMethod"
                :options="dictionary.paymentMethod"
                :disabled="disabledInput"
              />
            </div>
          </div>
          <hr class="tw-my-[34px] tw-border-white">
          <template v-if="formInfoResult && formInfoResult.data && formInfoResult.data.needClientDocument">
            <div id="step_4">
              <div class="tw-flex tw-justify-between tw-items-center tw-mb-[26px]">
                <div class="tw-text-h6">
                  Прикрепить документы
                  <div class="tw-font-normal tw-text-main-sm">
                    <span class="tw-text-an-flat-grey-4">Прикрепленные файлы:</span> {{ (form.personFileId && form.personFileId.length) || 0 }} документов
                  </div>
                </div>
                <div>
                  <PersonSelectDocumentFiles
                    v-if="form.personId.length"
                    btn-text="Выбрать файлы клиентов"
                    :value="form.personFileId"
                    :disabled="disabledInput"
                    :target-persons="form.personId"
                    @input="filesChange"
                  />
                </div>
              </div>
              <div class="tw-mb-[20px]">
                <TwFilesListViewByIds
                  :file-ids="form.personFileId || []"
                  no-data-text="Нет прикрепленных документов"
                  :is-drop-down="false"
                  class="tw-pt-[15px] tw-px-[30px] tw-pb-[23px] tw-rounded-[20px] tw-bg-white tw-mb-[17px] last:tw-mb-0"
                />
              </div>
            </div>
            <hr class="tw-my-[34px] tw-border-white">
          </template>
          <template v-if="formInfoResult && formInfoResult.data && formInfoResult.data.needPetition">
            <div id="step_5">
              <div class="tw-text-h6">
                Ходатайство
              </div>
              <div class="tw-mb-[20px] tw-mt-[10px]">
                <div
                  v-if="formInfoResult.dictionary && formInfoResult.dictionary.petitionTemplate"
                  class="tw-mb-[10px] tw-text-an-flat-gray-4 tw-flex tw-flex-wrap tw-gap-[10px]"
                >
                  Скачайте шаблон ходатайства, заполните его и прикрепите к запросу:
                  <template v-for="petition in formInfoResult.dictionary.petitionTemplate">
                    <template v-if="Array.isArray(petition.files) && petition.files.length">
                      <a
                        v-for="file in petition.files"
                        :key="petition.id+ '_' + file.id"
                        :href="file.url"
                        target="_blank"
                        class="tw-underline tw-text-an-flat-dark-blue-link"
                      >{{ file.name }}</a>
                    </template>
                  </template>
                </div>
                <TwUploadFilesList
                  v-model="form.petitionFileId"
                  label="Загрузить ходатайство"
                  file-type="petition"
                  :api="rtdFileApi"
                  :multiple="false"
                  :disabled="disabledInput"
                />
                <div
                  v-if="$v.form.petitionFileId.$invalid"
                  class="tw-mt-[5px] tw-text-an-flat-red-main tw-p-[5px]"
                >
                  {{ $v.form.petitionFileId.$invalid ? 'Обязательное поле - прикрепите ходатайство':'' }}
                </div>
              </div>
            </div>
            <hr class="tw-my-[24px] tw-border-white">
          </template>
          <div>
            <div class="tw-text-h6">
              Примечание
            </div>
            <div class="tw-w-full tw-mb-[20px] tw-mt-[10px]">
              <TwTextarea
                v-model="form.comment"
                :show-label="false"
                :disabled="disabledInput"
                placeholder="Комментарий"
                rows="6"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <!--    Уведомления и ошибки      -->
          <div class="tw-text-an-flat-red-main tw-text-center tw-leading-normal tw-text-h4 tw-mb-[26px]">
            <div class="tw-font-semibold tw-text-main-md">
              Забронировать объект невозможно.
            </div>
            <template v-if="createErrors.length">
              <div
                v-for="(errText, eIndex) in createErrors"
                :key="eIndex"
                class="tw-text-main-sm2"
              >
                * {{ errText }}
              </div>
            </template>
            <div
              v-else
              class="tw-text-main-sm2"
            >
              * для застройщика не указаны способы бронирования
            </div>
          </div>

          <div class="tw-w-full">
            <TwPanelInfoBlock>
              <template #title>
                Что делать?
              </template>
              <template #text>
                Для уточнения информации по застройщику обратитесь в отдел новостроек.
              </template>
            </TwPanelInfoBlock>
          </div>
        </template>
      </div>
    </div>
    <TwDialog
      :value="showDialog"
      max-width="400px"
      @input="showDialog = $event"
    >
      <template #body>
        <div class="tw-flex tw-justify-center tw-mb-[21px]">
          <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
              fill="#EE9644"
            />
            <path
              d="M60 72C57.8272 71.9999 55.7386 71.159 54.1719 69.6535C52.6052 68.1479 51.6819 66.0945 51.5953 63.9234L50.4867 35.9297C50.4422 34.7981 50.6267 33.6693 51.029 32.6107C51.4313 31.5522 52.0432 30.5858 52.8281 29.7694C53.6129 28.9531 54.5545 28.3036 55.5964 27.86C56.6382 27.4163 57.759 27.1876 58.8914 27.1875H61.1086C62.241 27.1876 63.3618 27.4163 64.4036 27.86C65.4455 28.3036 66.3871 28.9531 67.1719 29.7694C67.9568 30.5858 68.5687 31.5522 68.971 32.6107C69.3733 33.6693 69.5578 34.7981 69.5133 35.9297L68.4047 63.9234C68.3181 66.0945 67.3948 68.1479 65.8281 69.6535C64.2614 71.159 62.1728 71.9999 60 72Z"
              fill="white"
            />
            <path
              d="M68.3976 84.4148C68.3976 79.7769 64.6378 76.0172 60 76.0172C55.3621 76.0172 51.6023 79.7769 51.6023 84.4148C51.6023 89.0527 55.3621 92.8125 60 92.8125C64.6378 92.8125 68.3976 89.0527 68.3976 84.4148Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="tw-flex tw-justify-center tw-mb-[15px] tw-text-[24px] tw-font-bold tw-text-an-flat-orange-warning">
          Внимание!
        </div>
        <div class="tw-max-w-[320px] tw-mx-auto tw-mb-[24px] tw-text-h6 tw-text-center">
          После отправки введённые данные изменить не получится
        </div>
        <div class="tw-flex tw-justify-center tw-gap-[20px]">
          <button
            class="tw-w-full tw-rounded-[5px] tw-bg-an-flat-green-btn-bg tw-transition-color tw-duration-150 hover:tw-bg-[#2CD48A] active:tw-bg-[#53DDA0] tw-py-[12px] tw-px-[16px] tw-font-bold tw-text-main-sm tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.prevent="confirmationFunc"
          >
            Отправить
          </button>
          <button
            class="tw-w-full tw-rounded-[5px] tw-bg-an-flat-gray-1 tw-transition-color tw-duration-150 hover:tw-bg-an-flat-gray-11 active:tw-bg-an-flat-gray-12 tw-py-[12px] tw-px-[16px] tw-font-bold tw-text-main-sm tw-text-white tw-flex tw-gap-[12px] tw-justify-center tw-items-center enabled:hover:tw-drop-shadow-lg disabled:tw-opacity-50"
            @click.prevent="cancellationFunc"
          >
            Отменить
          </button>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import API from '@/Api/Rtd/RequestToDeveloper/Request'
import rtdFileApi from '@/Api/Rtd/File'
import personAPI from '@/Api/Crm/Person'
import { required } from 'vuelidate/lib/validators'
import RealtyObjectShortInfoBlock from '@/views/Newbuildings/view/RealtyObjectShortInfoBlock.vue'
import PaymentMethodSelectRealtyObjectReservation
  from '@/views/Newbuildings/view/PaymentMethodSelectRealtyObjectReservation.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import ReservationTypeSelectRealtyObjectReservation
  from '@/views/Newbuildings/view/ReservationTypeSelectRealtyObjectReservation.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import PersonSelectDocumentFiles from '@/components/select/person/PersonSelectDocumentFiles.vue'
import RequestToDeveloperReservationStatus
  from '@/views/Newbuildings/RequestToDeveloper/RequestToDeveloperReservationStatus.vue'
import TwFilesListViewByIds from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'
import RequestToDeveloperCancel from '@/views/Newbuildings/RequestToDeveloper/RequestToDeveloperCancel.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import TwInputPhone from '@/components/tw-ui/ui-kit/custom/TwInputPhone.vue'

export default {
  name: 'RequestToDeveloperReservationUI',
  components: { TwInputPhone, TwPanelInfoBlock, TwDialog, RequestToDeveloperCancel, TwFilesListViewByIds, RequestToDeveloperReservationStatus, PersonSelectDocumentFiles, TwUploadFilesList, TwTextarea, ReservationTypeSelectRealtyObjectReservation, ViewPanelLink, TwInputText, PaymentMethodSelectRealtyObjectReservation, RealtyObjectShortInfoBlock },
  props: {
    createInitData: {
      type: Object,
      default: null
    },
    showInitDict: {
      type: Object,
      default: null
    },
    showInitData: {
      type: Object,
      default: null
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rtdFileApi,
      loading: false,

      personsInfoArray: [],
      form: {
        id: null,
        dealId: null,
        companyId: null,
        complexId: null,
        status: null,
        statusChangeDate: null,
        confirmedBefore: null,
        confirmedPrice: null,
        userId: null,
        responsibleUserId: null,
        comment: '',
        personId: [],
        params: {
          objectId: null,
          paymentMethod: null,
          reservationType: null
        },
        petitionFileId: [],
        personFileId: [],
        draft: true
      },
      formInfoResult: null,
      reservationStatus: null,
      createDictionary: null,
      showDialog: false,
      formResult: false,
      createErrors: []
    }
  },
  validations () {
    return {
      form: {
        params: {
          objectId: {
            required
          },
          paymentMethod: {
            required
          },
          reservationType: {
            required
          }
        },
        companyId: {
          required
        },
        complexId: {
          required
        },
        petitionFileId: {
          ...(this.formInfoResult?.data?.needPetition) && {
            required
          }
        },
        personFileId: {
          ...(this.formInfoResult?.data?.needClientDocument) && {
            required
          }
        }
      }
    }
  },
  computed: {
    allowReservationTypes () {
      return this.dictionary?.reservationType?.filter((type) => {
        return type.active || this.form?.params?.reservationType === type.value
      })
    },
    isReservationTypes () {
      return !!(Array.isArray(this.allowReservationTypes) && this.allowReservationTypes.length)
    },
    disabledInput () {
      return this.loading || !this.form.draft
    },
    complexFilters () {
      return {
        builderId: this.form?.companyId ? [this.form.companyId] : []
      }
    },
    stepsList () {
      return [
        {
          label: 'Объект',
          value: this.form?.params?.objectId || false,
          visible: true
        },
        {
          label: 'Способ бронирования',
          value: this.form?.params?.reservationType || false,
          visible: true
        },
        {
          label: 'Клиенты',
          value: (Array.isArray(this.form.personId) && this.form.personId.length) || false,
          visible: true
        },
        {
          label: 'Способ оплаты',
          value: this.form?.params?.paymentMethod || false,
          visible: true
        },
        {
          label: 'Прикрепленные документы',
          value: (Array.isArray(this.form?.personFileId) && this.form?.personFileId.length) || false,
          visible: this.formInfoResult?.data?.needClientDocument || false
        },
        {
          label: 'Ходатайство',
          value: (Array.isArray(this.form?.petitionFileId) && this.form?.petitionFileId.length) || false,
          visible: this.formInfoResult?.data?.needPetition || false
        }
      ]
    },
    dictionary () {
      return this.isNew ? this.createDictionary : this.showInitDict
    },
    uiParams () {
      return this.showInitData?.ui || null
    }
  },
  watch: {
    'form.personId' (newValue) {
      this.buildViewPersonData(newValue)
    },
    'form.params.reservationType' () {
      this.formInfo()
    }
  },
  mounted () {
    if (this.isNew) {
      this.createInit()
    } else {
      this.showInit()
    }
  },
  methods: {
    filesChange (values) {
      console.warn('filesChange ', values)
      this.$set(this.form, 'personFileId', values || [])
    },
    onClickToStep (index) {
      document.getElementById(`step_${index}`)?.scrollIntoView({ behavior: 'smooth' })
      // this.stepsList[index].value = !this.stepsList[index].value
    },
    onSubmit (saveAndSend = false) {
      console.warn('saveDraft', this.form, this.$v.form.$invalid)
      this.$v.$reset()
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.loading = true
        if (this.isNew) {
          API.draftStore({
            ...this.form,
            saveAndSend
          }, 'reservation').then(({ data: result }) => {
            console.warn('SEND SUCCESS', result)
            this.$emit('created', result.data)
            if (saveAndSend) {
              this.$emit('success-send')
            }
          }).catch((error) => {
            console.error('SEND ERROR', error)
          }).finally(() => {
            this.loading = false
            this.$emit('refresh')
          })
        } else {
          API.update(this.form.id, {
            ...this.form,
            saveAndSend
          }, 'reservation').then(({ data: result }) => {
            console.warn('SEND SUCCESS', result)
            this.$emit('updated', result.data)
            if (saveAndSend) {
              this.$emit('success-send')
            }
          }).catch((error) => {
            console.error('SEND ERROR', error)
          }).finally(() => {
            this.loading = false
            this.$emit('refresh')
          })
        }
      }
    },
    formInfo () {
      console.warn('formInfo', this.form, this.formInfoResult)
      this.formInfoResult = null
      if (this.form?.companyId && this.form?.complexId && this.form?.params?.reservationType) {
        API.formInfo({ companyId: this.form.companyId, complexId: this.form.complexId, reservationType: this.form.params.reservationType }, 'reservation').then(({ data: result }) => {
          console.warn('formInfo SUCCESS', result)
          this.formInfoResult = result || null
        }).catch((error) => {
          // console.log(error.response?.data?.complexId)
          console.error('formInfo ERROR', error)
          if (error.response?.data?.complexId) {
            this.form.complexId = null
          }
        })
      }
    },
    createInit () {
      console.warn('createInit', this.createInitData)
      this.loading = true
      this.createErrors = []
      API.create({
        dealId: this.createInitData?.dealId,
        objectId: this.createInitData?.objectId,
        companyId: this.createInitData?.companyId,
        complexId: this.createInitData?.complexId
      }, this.createInitData?.requestType).then(({ data: result }) => {
        console.warn(result)
        this.createDictionary = result?.dictionary || null
        this.reservationStatus = result?.reservationStatus || null
        this.initFormData(result?.data)
      }).catch((error) => {
        // console.error(error, error.response.status)
        if (error.response.status === 422) {
          if (error?.response?.data && Object.keys(error.response.data).length) {
            this.createErrors = Object.keys(error.response.data).map((key) => {
              if (Array.isArray(error.response.data[key]) && error.response.data[key].length) {
                return error.response.data[key][0]
              } else {
                return error.response.data[key]
              }
            })
          }
        } else if (error.response.status === 523) {
          this.createErrors = ['Не удалось получить информацию о объекте. Подождите несколько минут и попробуйте снова.']
        } else {
          this.createErrors = ['Неописанная ошибка, создайте обращение в техническую поддержку!']
        }
      }).finally(() => {
        this.loading = false
      })
    },
    showInit () {
      console.warn('showInit', this.showInitData)
      this.initFormData(this.showInitData)
    },
    initFormData (presetObject) {
      console.warn('initFormData', presetObject)
      return new Promise((resolve) => {
        if (presetObject) {
          for (const key in this.form) {
            if (key === 'personId') {
              this.$set(this.form, 'personId', presetObject?.person?.map(p => p.id))
            } else if (presetObject[key] !== undefined) {
              this.$set(this.form, key, presetObject[key])
            }
          }
          this.form.params.objectId = this.form?.params?.objectId || this.createInitData?.objectId || null
        }
        this.formInfo()
        resolve()
      })
    },
    async buildViewPersonData (personIds) {
      console.warn('buildViewPersonData', personIds)
      this.personsInfoArray = []
      // список клиентов генерится по разному для черновика и отправленной фиксации
      // Так как в отправленной нужно показать данные клиента на момент фиксации
      if (this.form?.draft) {
        console.warn('buildViewPersonData ЧЕРНОВИК', personIds)
        if (Array.isArray(personIds) && personIds.length) {
          for (const personId of personIds) {
            console.error(personId)
            const personInfo = await personAPI.show(personId).then(({ data }) => data?.data ? { id: data?.data?.id, name: data?.data?.name, fatherName: data?.data?.fatherName, surname: data?.data?.surname, phone: data?.data?.phones?.[0]?.phone } : null)
            console.error('personInfo', personInfo)
            this.personsInfoArray.push(personInfo)
          }
        } else {
          console.error('Отсутствует список клиентов', personIds)
        }
      } else {
        console.warn('buildViewPersonData ОТПРАВЛЕННАЯ ФИКСАЦИЯ', this.showInitData)
        for (const person of this.showInitData.person) {
          // console.error(person)
          console.error('personInfo', person)
          this.personsInfoArray.push(person)
        }
      }
    },
    cancellationFunc () {
      if (!this.formResult) { this.formResult = false }
      this.showDialog = false
    },
    confirmationFunc () {
      if (!this.formResult) {
        this.formResult = true
        this.onSubmit(true)
      }
      this.showDialog = false
    }
  }
}

</script>

<style scoped>

</style>
