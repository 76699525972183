<template>
  <div
    class="tw-text-center tw-font-semibold name-first-letters-container"
    :style="Style"
  >
    <div>
      {{ text | fullNameFirstLetters }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonNameLetters',
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      default: 40
    },
    height: {
      default: 40
    },
    borderRadius: {
      default: '50%'
    },
    isShadow: {
      default: false
    }
  },
  computed: {
    colors () {
      return this.$Global.generateColorsFromString(this.text, false)
    },
    /**
     * @return {string}
     */
    Width () {
      return this.width + 'px'
    },
    /**
     * @return {string}
     */
    Height () {
      return this.height + 'px'
    },
    Style () {
      return {
        border: this.colors.text,
        borderRadius: this.borderRadius,
        color: this.colors.text,
        backgroundColor: this.colors.bg,
        boxShadow: this.isShadow ? '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)' : 'none',
        // margin: 'auto',
        width: this.Width + '!important',
        height: this.Height + '!important',
        MinWidth: this.Width + '!important',
        MinHeight: this.Height + '!important',
        MaxWidth: this.Width + '!important',
        MaxHeight: this.Height + '!important',
        FontSize: this.width / 2.3 + 'px ' + '!important',
        lineHeight: this.Height + '!important'
      }
    }
  }
}
</script>
<style scoped>
.name-first-letters-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: solid 1px;
  color: white;
  background-color: gray;
  white-space: nowrap;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
</style>
