<template>
  <v-card class="py-3">
    <v-overlay :value="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
        />
        <div class="mt-2">
          Документы загружаются...<br>
          {{ uploadedCount }} / {{ selectedFiles.length }}
        </div>
      </div>
    </v-overlay>
    <v-card-text>
      <v-form ref="docForm">
        <v-select
          v-model="form.type"
          label="Тип документа"
          attach=""
          item-text="label"
          item-value="value"
          :items="docTypes"
          :disabled="loading || disabled"
          dense
          :rules="requiredRules"
          outlined
        />
      </v-form>
      <div>
        <div>
          <div class="subtitle-2 font-weight-regular mb-2">
            Выбрать из документов клиента:
          </div>
          <PersonSelectDocumentFiles
            v-if="personIds.length"
            btn-text="Открыть файлы клиентов"
            :value="selectedFiles"
            :disabled="loading || disabled"
            :target-persons="personIds"
            @input="filesChange"
          />
        </div>
        <TwUploadFilesList
          v-model="selectedFiles"
          label="Загрузить с устройства"
          file-type="domclick_document"
          no-data-text="Нет файлов"
          :disabled="loading || disabled"
          :multiple="true"
        />
      </div>
      <v-btn
        :disabled="loading || disabled"
        block
        small
        color="success"
        @click="createProcess"
      >
        загрузить документ
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { docTypes } from '../../DomClickDict'
import dealAPI from '@/Api/Crm/Mortgage/Docmclick/Deals'
import PersonSelectDocumentFiles from '@/components/select/person/PersonSelectDocumentFiles'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'

export default {
  name: 'DomClickDealApplicationDocForm',
  components: { TwUploadFilesList, PersonSelectDocumentFiles },
  props: {
    dealId: {
      type: Number,
      required: true
    },
    applicationId: {
      type: Number,
      required: true
    },
    personIds: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      requiredRules: [
        (v) => !!v || 'Обязательное поле'
      ],
      selectedFiles: [],
      uploadedCount: 0,
      form: {
        fileId: '',
        type: ''
      },
      loading: false,
      docTypes
    }
  },
  mounted () {
    this.$refs.docForm?.validate()
  },
  methods: {
    filesChange (values) {
      console.warn('filesChange ', values)
      this.$set(this, 'selectedFiles', values || [])
    },
    async createProcess () {
      console.warn('createProcess', this.form)
      if (!this.form.type) {
        return alert('Не выбран Тип документа')
      }
      if (!this.selectedFiles || (Array.isArray(this.selectedFiles) && !this.selectedFiles.length)) {
        return alert('Не выбраны Файлы документа')
      }

      this.loading = true
      for (const fileId of this.selectedFiles) {
        await this.applicationDocCreateSend(fileId)
        this.uploadedCount++
      }
      this.loading = false
      this.uploadedCount = 0
      this.$emit('refresh')
      this.$nextTick(() => {
        this.$emit('hide')
      })
    },
    applicationDocCreateSend (fileId) {
      console.warn('applicationDocCreateSend', fileId)
      return dealAPI.applicationDocCreate(this.dealId, this.applicationId, {
        fileId,
        type: this.form.type
      })
    }
  }
}
</script>

<style scoped>

</style>
