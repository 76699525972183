<template>
  <div>
    <div
      v-if="loading"
      class="tw-flex tw-justify-center tw-items-center"
    >
      <TwCircleLoader />
    </div>
    <table>
      <tbody>
        <tr
          v-for="email in list"
          :key="email.id"
        >
          <td class="tw-px-3 tw-py-2">
            {{ email.emailFolder.type && email.emailFolder.type.label }}
          </td>
          <td class="tw-px-3 tw-py-2">
            <div
              v-for="(contact, index) in email.contacts"
              :key="contact.id+'_'+index"
              class="tw-my-1"
            >
              <div
                v-if="contact.person"
                :key="contact.id"
              >
                {{ $Global.peopleName(contact.person, 'fi') }}
              </div>
              <div
                v-else
                :key="contact.id"
                class="tw-flex tw-items-center"
              >
                <PersonNameLetters
                  class="tw-mr-1"
                  :width="30"
                  :height="30"
                  :text="contact.name || contact.email || 'none'"
                />
                {{ contact.name }}
                <span v-text="`<${contact.email}>`" />
              </div>
            </div>
          </td>
          <td class="tw-px-3 tw-py-2">
            <div
              class="panel-view-link"
              @click="show(email)"
            >
              {{ email.theme }}
            </div>
          </td>
          <td class="tw-px-3 tw-py-2 tw-text-right">
            {{ $Global.DateFormat.DateTimeShort(email.dateMailBox || email.updatedAt) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import API from '@/Api/Email/Mail'
import PersonNameLetters from '@/components/other/person/PersonNameLetters.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'

export default {
  name: 'EmailItemChildrens',
  components: { PersonNameLetters, TwCircleLoader },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      loading: false,
      list: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    show (item) {
      console.log('show', item)
      this.showEmailCard(
        {
          id: item.id
        },
        () => {
          console.warn(this.$options.name, 'CALLBACK showEmailCard')
          // this.fetch()
        })
    },
    getList () {
      if (this.loading) return
      this.loading = true
      API.children(this.id)
        .then(({ data }) => {
          console.warn('getList ', data.data)
          this.list = data.data || []
        })
        .catch((error) => {
          console.error('getList ', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
