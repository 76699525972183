<template>
  <div>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <TwFlexCol>
      <TwFlexCell v-if="preset">
        <TwPanelContentBlockFieldView>
          <template #title>
            Пользователь
          </template>
          <div v-if="preset.userId">
            <ViewPanelLink
              :id="preset.userId"
              label="Пользователь"
              component="employee-show"
            >
              <EmployeeName :id="preset.userId" />
            </ViewPanelLink>
          </div>
        </TwPanelContentBlockFieldView>
      </TwFlexCell>
      <TwFlexCell v-if="preset">
        <TwPanelContentBlockFieldView>
          <template #title>
            Начислено
          </template>
          <div v-if="preset.commission">
            {{ (preset.commission).toLocaleString('ru') }} руб.
          </div>
        </TwPanelContentBlockFieldView>
      </TwFlexCell>
      <TwFlexCell v-if="preset">
        <TwPanelContentBlockFieldView>
          <template #title>
            Процент
          </template>
          <div v-if="preset.percent">
            {{ preset.percent }} %
          </div>
        </TwPanelContentBlockFieldView>
      </TwFlexCell>
      <TwFlexCell>
        <TwInputText
          :disabled="true || formProcessing"
          type="text"
          placeholder="Сумма для выплаты"
          max-length="13"
          :persistent-label="true"
          :show-label="true"
          :dense="true"
          :value="$Global.inputFormatPrice.getStringSpaced(form.forPayment)"
          @input="form.forPayment = $Global.inputFormatPrice.getNumber($event)"
        >
          <template #append>
            руб.
          </template>
        </TwInputText>
      </TwFlexCell>
      <TwFlexCell>
        <TwInputRadio
          v-model="form.payStatus"
          :disabled="formProcessing"
          :dense="false"
          :show-label="true"
          label="Выплата"
          :options="payStatusOptionsList"
          @input="payedChange"
        />
      </TwFlexCell>
      <TwFlexCell v-if="form.payStatus === 'payed'">
        <TwInputText
          v-model="form.payDate"
          type="date"
          autocomplete="off"
          label="Дата выплаты"
          min="1920-01-01"
          :dense="true"
          :max="currentDate"
          :disabled="formProcessing"
          :show-label="true"
          :error-text="$v.form.payDate.$invalid ? '*Обязательное поле':''"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwInputText
        v-model="form.name"
        type="text"
        autocomplete="off"
        label="Назначение"
        placeholder="Назначение"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        :error-text="$v.form.name.$invalid ? '*Обязательное поле':''"
      />
      <TwFlexCell>
        <TwTextarea
          v-model="form.comment"
          rows="3"
          label="Комментарий"
          maxlength="250"
          show-length
          resize="y"
          :persistent-label="true"
          :show-label="true"
          :error-text="$v.form.comment.$invalid ? '*Обязательное поле':''"
        />
      </TwFlexCell>
    </TwFlexCol>
    <TwFlexCell
      v-if="serverErrorMessage"
      class="tw-text-an-flat-red-main tw-mt-[20px]"
    >
      {{ serverErrorMessage }}
    </TwFlexCell>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="true"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import API from '@/Api/Accounting/UserCalc'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwInputRadio from '@/components/tw-ui/ui-kit/input/TwInputRadio.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'

export default {
  name: 'UserCalcMainForm',
  components: {
    TwTextarea,
    TwFlexCol,
    TwFlexCell,
    TwInputText,
    TwInputRadio,
    TwCardSimpleFormSaveBlock,
    TwPanelContentBlockFieldView,
    ViewPanelLink,
    EmployeeName
  },
  mixins: [FormCreateEditMixin],
  props: {
    denseFields: {
      type: Boolean,
      default: false
    },
    disableRedirect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      API: API,
      currentDate: new Date().toISOString().slice(0, 10),
      form: {}
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        payDate: {
          ...(this.form.payStatus === 'payed') && {
            required
          }
        },
        comment: {
          ...(this.form.payStatus === 'refusal') && {
            required
          }
        }
      }
    }
  },
  methods: {
    payedChange (val) {
      if (val === 'payed') {
        this.form.payDate = this.currentDate
      } else {
        this.form.payDate = null
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'forPayment') {
              this.$set(this.form, key, 0)
            } else if (key === 'payStatus') {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleResetCustom () {
      this.handleReset()
    },
    handleSubmit () {
      console.warn(this.$options.name, ' handleSubmit ', this.formType)
      if (this.formProcessing) {
        return
      }

      this.formProcessing = true
      this.serverErrorMessage = null

      this.API.update(this.targetId, this.form)
        .then(({ data }) => {
          console.warn(this.$options.name, ' UPDATE ', data.data)
          this.$emit('update', data.data)
        })
        .catch((error) => {
          this.serverErrorMessage = error.serverErrorMessage || null
        })
        .finally(() => {
          this.formProcessing = false
        })
    }
  },
  computed: {
    payStatusOptionsList () {
      const result = Array.isArray(this.dictionary.payStatus) ? this.dictionary.payStatus : []
      result.unshift({
        label: 'Нет',
        value: null
      })
      return result
    }
  }
}
</script>

<style scoped>

</style>
