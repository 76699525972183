<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Компания
      </template>
      <div v-if="info.companyId">
        <ViewPanelLink
          :id="info.companyId"
          label="Компания"
          component="company-show"
        >
          <TwCompanyName :id="info.companyId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Общая сумма
      </template>
      <div>
        {{ (info.amount).toLocaleString('ru') }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Выплачено
      </template>
      <div>
        {{ info.payed ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Сформирован
      </template>
      <div>
        {{ info.formed ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Получен
      </template>
      <div>
        {{ info.received ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Устное согласование
      </template>
      <div>
        {{ info.verballyAgreed ? 'Да':'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView v-if="info.creatorId">
      <template #title>
        Создал
      </template>
      <div>
        <ViewPanelLink
          :id="info.creatorId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.creatorId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView v-if="info.updaterId">
      <template #title>
        Изменил
      </template>
      <div>
        <ViewPanelLink
          :id="info.updaterId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.updaterId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Дата создания
      </template>
      <div v-if="info.createdAt">
        {{ $Global.DateFormat.DateTimeShort(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.updatedAt && (info.updatedAt !== info.createdAt)">
      <template #title>
        Дата изменения
      </template>
      <div v-if="info.updatedAt">
        {{ $Global.DateFormat.DateTimeShort(info.updatedAt) }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwCompanyName from '@/components/tw-ui/other/TwCompanyName.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'

export default {
  name: 'ActMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink, TwCompanyName, EmployeeName },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
