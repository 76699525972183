<template>
  <div
    tabindex="0"
    :style="{ width }"
    style="position: relative"
    class="tw-flex tw-flex-col"
    @click.stop="dragZoneClick"
  >
    <div
      :title="title"
      :style="{ height }"
      @dragenter="dragHandler"
      @dragleave="dragHandler"
      @dragover="dragHandler"
      @drop="dragHandler"
    >
      <input
        ref="upload"
        type="file"
        :value="null"
        :accept="accept"
        :disabled="disabled || processUpload"
        :multiple="multiple"
        style="display:none"
        @change="inputChange"
      >
      <div
        style="height: 100%;"
        class="tw-flex tw-items-center tw-justify-center tw-flex-col"
      >
        <template v-if="!processUpload">
          <template v-if="fileInfo && fileInfo.mime && fileInfo.mime.indexOf('image') !== -1">
            <div
              v-if="imageViewError"
              class="tw-text-center tw-text-an-flat-red-main"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 548.176 548.176"
                width="30"
                height="30"
                style="enable-background:new 0 0 548.176 548.176;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      fill="currentColor"
                      d="M534.75,68.238c-8.945-8.945-19.694-13.417-32.261-13.417H45.681c-12.562,0-23.313,4.471-32.264,13.417 C4.471,77.185,0,87.936,0,100.499v347.173c0,12.566,4.471,23.318,13.417,32.264c8.951,8.946,19.702,13.419,32.264,13.419h456.815 c12.56,0,23.312-4.473,32.258-13.419c8.945-8.945,13.422-19.697,13.422-32.264V100.499 C548.176,87.936,543.699,77.185,534.75,68.238z M511.623,447.672c0,2.478-0.899,4.613-2.707,6.427 c-1.81,1.8-3.952,2.703-6.427,2.703H45.681c-2.473,0-4.615-0.903-6.423-2.703c-1.807-1.813-2.712-3.949-2.712-6.427V100.495 c0-2.474,0.902-4.611,2.712-6.423c1.809-1.803,3.951-2.708,6.423-2.708h456.815c2.471,0,4.613,0.905,6.42,2.708 c1.801,1.812,2.707,3.949,2.707,6.423V447.672L511.623,447.672z"
                    /><path d="M127.91,237.541c15.229,0,28.171-5.327,38.831-15.987c10.657-10.66,15.987-23.601,15.987-38.826 c0-15.23-5.333-28.171-15.987-38.832c-10.66-10.656-23.603-15.986-38.831-15.986c-15.227,0-28.168,5.33-38.828,15.986 c-10.656,10.66-15.986,23.601-15.986,38.832c0,15.225,5.327,28.169,15.986,38.826C99.742,232.211,112.683,237.541,127.91,237.541z " /><polygon points="210.134,319.765 164.452,274.088 73.092,365.447 73.092,420.267 475.085,420.267 475.085,292.36 356.315,173.587 " />
                  </g>
                </g>
              </svg>
              <div>
                <small>
                  Ошибка загрузки файла!
                </small>
              </div>
            </div>
            <img
              v-else
              :src="getFileResizeByKey(fileInfo, 'default')"
              aspect-ratio="1"
              width="100%"
              :style="{ height }"
              class="tw-object-contain tw-cursor-pointer"
              @load="imageSuccessLoad"
              @error="imageErrorLoad"
              @click.stop="viewImage"
            >
          </template>
          <template v-else-if="fileInfo && fileInfo.mime && fileInfo.mime.indexOf('image') === -1">
            <svg
              width="60"
              height="60"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              fill="green"
            ><g><path d="m25.71 9.29-6-6a1.09 1.09 0 0 0 -.71-.29h-12a1 1 0 0 0 -1 1v24a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-18a1.18 1.18 0 0 0 -.29-.71zm-3.12-.29h-2.59v-2.59zm-14.59 18v-22h10v5a1 1 0 0 0 1 1h5v16zm2-18a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 -1-1zm11 6h-10a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2zm0 3h-10a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2zm0 3h-10a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2zm0 3h-10a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2z" /></g></svg>
            <div style="font-size: small">
              {{ fileInfo.name }}
            </div>
          </template>
          <div
            v-else
            class="tw-text-center tw-cursor-pointer"
            style="align-self: center; justify-self: flex-start"
          >
            <svg
              :class="disabled || processUpload ? 'tw-text-an-flat-grey-4': dragover ? 'tw-text-an-flat-green' : 'tw-text-an-flat-blue-btn-active'"
              width="60"
              height="60"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <g>
                <path
                  fill="currentColor"
                  d="M25,22H22a1,1,0,0,1,0-2h3c2.06,0,3-2.17,3-4.19,0-2.31-1.57-4.19-3.5-4.19a3,3,0,0,0-1.21.26,1,1,0,0,1-1-.15,1,1,0,0,1-.33-1A3.32,3.32,0,0,0,22,10a3,3,0,1,0-6,0,2.67,2.67,0,0,0,.07.66,1,1,0,0,1-.23.91,1,1,0,0,1-.88.32,1,1,0,0,1-.64-.36A3,3,0,0,0,12,10.46a3,3,0,0,0-3,2.9,1,1,0,0,1-.52.84,1,1,0,0,1-1,0,2,2,0,0,0-1-.27A2.82,2.82,0,0,0,4,17a2.81,2.81,0,0,0,2.5,3H10a1,1,0,0,1,0,2H6a.94.94,0,0,1-.47-.12A5,5,0,0,1,2,17a4.8,4.8,0,0,1,4.5-5,3.54,3.54,0,0,1,.73.07,5,5,0,0,1,6.89-3.05A5,5,0,0,1,24,9.65l.52,0c3,0,5.5,2.78,5.5,6.19C30,19.4,27.9,22,25,22Z"
                />
                <path
                  fill="currentColor"
                  d="M16,14a1,1,0,0,1,.69.28l4,3.84a1,1,0,0,1-1.38,1.44L17,17.35V23a1,1,0,0,1-2,0V17.35l-2.31,2.21a1,1,0,0,1-1.38-1.44l4-3.84A1,1,0,0,1,16,14Zm0,11a1,1,0,0,1,1,1v1a1,1,0,0,1-2,0V26A1,1,0,0,1,16,25Z"
                />
              </g>
            </svg>
          </div>
        </template>
        <template v-else>
          <TwCircleLoader />
        </template>
      </div>
    </div>
    <div class="tw-text-left">
      <div
        class="tw-mx-[8px] tw-my-[4px]"
        style="font-size: small"
      >
        {{ label }}
      </div>
    </div>
    <div
      class="d-flex justify-space-between"
      style="width: 100%;position: absolute; top: 0; right: 0"
    >
      <button
        v-if="fileInfo"
        class="tw-text-an-flat-blue-btn tw-p-[8px] tw-m-[5px]"
        title="Скачать"
        @click.stop="download"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="20"
          height="20"
          viewBox="0 0 512 512"
          style="enable-background:new 0 0 512 512;"
          xml:space="preserve"
          fill="currentColor"
        ><g><g><polygon points="445.217,378.435 445.217,445.217 66.783,445.217 66.783,378.435 0,378.435 0,512 512,512 512,378.435 " /></g></g><g><g><polygon points="301.078,239.17 301.078,0 210.922,0 210.922,239.17 121.322,239.17 256,373.848 390.678,239.17 " /></g></g></svg>
      </button>
      <button
        v-if="fileInfo"
        class="tw-text-an-flat-red-main tw-p-[8px] tw-m-[5px]"
        title="Удалить"
        @click.stop="clear"
      >
        <svg
          version="1.1"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512 512"
          style="enable-background:new 0 0 512 512;"
          xml:space="preserve"
          fill="currentColor"
        ><g><g><polygon points="189.651,141.039 116.984,141.039 137.165,512 196.378,512 " /></g></g><g><g><polygon points="219.656,141.039 226.383,512 285.617,512 292.344,141.039 " /></g></g><g><g><polygon points="322.349,141.039 315.622,512 374.835,512 395.016,141.039 " /></g></g><g><g><path d="M316.822,54.134V0H195.178v54.134H90.323v56.905h331.354V54.134H316.822z M286.822,54.134h-61.645V30h61.645V54.134z" /></g></g></svg>
      </button>
    </div>
  </div>
</template>

<script>
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import fileAPI from '@/Api/Crm/File'
import docAPI from '@/Api/Crm/Dictionary/General/Document'
import visionAPI from '@/Api/Vision/Image/index'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'PassportUploadFilePages',
  components: { TwCircleLoader },
  props: {
    value: {
      default: null
    },
    title: {
      type: String,
      default: 'Загрузка файла'
    },
    label: {
      type: String,
      default: 'Название документа'
    },
    fileType: {
      type: [String, null],
      default: null
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '130px'
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/bmp, application/pdf, image/svg+xml'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isContain: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileInfo: null,
      dragover: false,
      processUpload: false,
      imageViewError: false
    }
  },
  watch: {
    value: {
      handler (newValue, OldValue) {
        if (!OldValue && newValue) {
          console.warn('Value INIT change:  ', newValue, OldValue)
          this.fetchValueInfo(newValue)
        }
      },
      immediate: true
    }
  },
  methods: {
    getFileResizeByKey: getFileResizeByKey,
    detectImage (file) {
      visionAPI.detectImageFields('passport', 1, file)
        .then(({ data }) => {
          console.warn('detectImage', data)
          this.$emit('detectedPassportFields', data || {})
        })
        .catch((error) => {
          console.error('detectImage', error)
        })
    },
    imageSuccessLoad () {
      this.imageViewError = false
    },
    imageErrorLoad () {
      this.imageViewError = true
    },
    download () {
      docAPI.download(this.fileInfo.url, this.fileInfo.name)
    },
    clear () {
      this.fileInfo = null
      this.$emit('input', 0)
    },
    dragZoneClick () {
      // console.warn('++', e)
      this.$refs.upload.click()
    },
    inputChange (e) {
      // console.warn('++', this.inputSelected, e)
      this.filesSelected(e.target.files)
    },
    dragHandler (e) {
      // console.warn('dragHandler', e)
      e.preventDefault()

      if (this.disabled) return

      if (e && e.type) {
        switch (e.type) {
          case 'dragenter':
            this.dragover = true
            break
          case 'dragleave':
            this.dragover = false
            break
          case 'dragover':
            this.dragover = true
            break
          case 'drop':
            if (e.dataTransfer) {
              this.filesSelected(e.dataTransfer.files)
            }
            break
        }
      }
    },
    filesSelected (files) {
      console.log('filesSelected ', files, files.length)
      this.dragover = false
      if (files && files.length) {
        this.upload(files[0])
      }
    },
    upload (file) {
      this.processUpload = true
      console.log(this.$options.name, 'upload', file)
      // если это первая траница паспорта - нужно отправить на распознание
      if (this.fileType === 'passport_1' || this.fileType === 'passport_foreign_1') {
        this.detectImage(file)
      }
      const formData = new FormData()
      formData.append('file', file)
      if (this.fileType) formData.append('type', this.fileType)

      fileAPI.upload(formData)
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.fileInfo = data.data
          console.log('link: ', data.data)
          this.$emit('input', data.data.id)
        })
        .catch((error) => {
          console.warn(this.$options.name, error)
        })
        .finally(() => {
          this.processUpload = false
        })
    },
    viewImage () {
      this.$store.dispatch('imageViewer/showImages', {
        files: [
          {
            path: this.fileInfo.url
          }
        ],
        startIndex: 0
      })
    },
    fetchValueInfo (id) {
      this.processUpload = true
      fileAPI.show(id)
        .then(({ data: { data } }) => {
          // console.warn(data)
          this.fileInfo = data
        })
        .catch((error) => {
          console.error({ error })
        })
        .finally(() => {
          this.processUpload = false
        })
    }
  }
}
</script>
