<template>
  <PageViewDefaultWrapper
    header-class="mb-2"
    header-bg-class="tw-text-an-flat-disabled-text"
    :permission-denied="permissionDenied"
  >
    <template #header>
      <div class="tw-flex-1">
        <TwPanelActionButton
          :disabled="isUserEmailBoxesNone || isUserEmailFoldersNone"
          color="blue"
          size="md"
          class="tw-mt-[21px]"
          @click.prevent="create"
        >
          Новое письмо
        </TwPanelActionButton>
      </div>
      <Filters
        class="tw-w-[400px]"
        :disabled="isUserEmailBoxesNone || isUserEmailFoldersNone"
        :from-panel="fromPanel"
        type="emails"
        :show-filter-panel="false"
        @change="fetch"
      />
      <EmailBoxAndFolderSelector
        :value-folder="defaultFilters.folderId"
        :disabled="isUserEmailBoxesNone"
        @changeFolder="fetch"
        @inputFolder="defaultFilters.folderId = $event"
      />
    </template>
    <template>
      <template v-if="isUserEmailBoxesNone || isUserEmailFoldersNone">
        <div class="tw-text-center tw-p-3">
          <div
            v-if="isUserEmailBoxesNone"
            class="tw-flex tw-flex-col"
          >
            Не удалось получить конфигурации почтовых ящиков пользователя
            <TwPanelActionButton
              :disabled="loading"
              color="gray"
              size="sm"
              class="tw-m-auto"
              @click.prevent="showProfile"
            >
              Открыть профиль
            </TwPanelActionButton>
          </div>
          <div
            v-if="isUserEmailFoldersNone"
            class="tw-flex tw-flex-col"
          >
            Пожалуйста подождите<br>
            Почта собирается<br>
            <small>
              (Email Folders None)
            </small><br>
            <TwPanelActionButton
              :disabled="loading"
              color="gray"
              size="sm"
              class="tw-m-auto"
              @click.prevent="$store.dispatch('profile/fetchEmailConfigs')"
            >
              Обновить
            </TwPanelActionButton>
          </div>
        </div>
      </template>

      <TwTableBase
        v-else-if="!isUserEmailBoxesNone || isUserEmailFoldersNone"
        :schema-key="filterSchemaKey"
        :loading-error-text="loadingErrorText"
        tbody-classes="tw-text-xs"
        tbody-row-classes="tw-cursor-pointer"
        :items="data"
        :headers="columns"
        :table-fixed="false"
        :loading="loading"
        :fixed-header-margin="0"
        :table-columns-management="false"
        :no-data-text="noResultsText"
        :pagination="pagination"
        @changePagination="changePagination"
      >
        <template #tbody="{ items }">
          <tbody>
            <template v-for="(item, iIndex) of items">
              <tr
                :key="iIndex"
                class="hover:tw-bg-an-flat-gray-8"
                :class="item.emailFolder.type.value === 'inbox' ? [!!item.read ? 'email-table-read-true':'email-table-read-false']:[]"
              >
                <td class="tw-py-[8px] tw-px-[12px]">
                  <ListMenu
                    v-if="listItemActions.length"
                    offset-y
                    :action-list="listItemActions"
                    :target="item"
                  />
                </td>
                <td class="tw-py-[8px] tw-px-[12px]">
                  <div
                    v-for="(contact, index) in item.contacts"
                    :key="contact.id+'_'+index"
                    class="tw-my-[8px] tw-whitespace-nowrap"
                  >
                    <div
                      v-if="contact.person"
                      :key="contact.id"
                      class="panel-view-link"
                      @click="showPersonCard({ id: contact.person.id })"
                    >
                      {{ $Global.peopleName(contact.person, 'fi') }}
                    </div>
                    <div
                      v-else
                      :key="contact.id"
                      class="d-flex align-center"
                    >
                      <PersonNameLetters
                        class="mr-1"
                        :width="30"
                        :height="30"
                        :text="contact.name || contact.email || 'none'"
                      />
                      {{ contact.name }}
                      <span v-text="`<${contact.email}>`" />
                    </div>
                  </div>
                </td>
                <td class="tw-py-[8px] tw-px-[12px]">
                  <div
                    class="panel-view-link"
                    @click="show(item)"
                  >
                    {{ item.theme }}
                  </div>
                </td>
                <td class="tw-py-[8px] tw-px-[12px]">
                  <div class="tw-flex tw-flex-col tw-justify-end tw-whitespace-nowrap">
                    <div class="tw-text-right">
                      {{ $Global.DateFormat.DateTimeShort(item.dateMailBox || item.updatedAt) }}
                      <div
                        v-if="item.status && item.status.value === 'send'"
                        :class="item.read ? 'tw-text-an-flat-green' : 'tw-text-an-flat-disabled-text'"
                      >
                        <TwIconWrapper
                          :size="20"
                          :style="{ transform: expandedItems.includes(item.id) ? 'rotate(90deg)':'rotate(0deg)' }"
                          file-name="checked-1"
                        />
                        <small>
                          {{ item.read ? 'Прочитано' : 'Не прочитано' }}
                        </small>
                        <div v-if="item.read && item.dateRead">
                          <small>{{ $Global.DateFormat.DateTimeShort(item.dateRead) }}</small>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="item.childrenCount"
                      class="tw-py-[8px]"
                    >
                      <button @click="toggleExpanded(item.id)">
                        {{ item.childrenCount }}
                        <TwIconWrapper
                          :size="20"
                          :style="{ transform: expandedItems.includes(item.id) ? 'rotate(90deg)':'rotate(0deg)' }"
                          file-name="arrow-right-1"
                        />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                v-if="item.childrenCount && expandedItems.includes(item.id)"
                :key="iIndex + '-child'"
              >
                <td
                  class="tw-py-[8px] tw-px-[12px]"
                  colspan="99"
                >
                  <EmailItemChildrens :id="item.id" />
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </TwTableBase>
    </template>
  </PageViewDefaultWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import DataListMixin from '@/mixins/DataListMixin'
import API from '@/Api/Email/Mail'
import tableOptions from './tableOptions'
import Filters from '@/components/filters/Filters'
import ListMenu from '@/components/popup/menu/ListMenu'
import PageViewDefaultWrapper from '@/components/core/ViewDefault/PageViewDefaultWrapper'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import EmailBoxAndFolderSelector from '@/components/select/email/EmailBoxAndFolderSelector'
import PersonNameLetters from '@/components/other/person/PersonNameLetters'
import EmailItemChildrens from '@/views/Email/EmailItemChildrens'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwTableBase from '@/components/tw-ui/table/TwTableBase.vue'
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'

export default {
  name: 'UserEmailList',
  components: {
    TwTableBase,
    TwIconWrapper,
    TwPanelActionButton,
    EmailItemChildrens,
    PersonNameLetters,
    EmailBoxAndFolderSelector,
    PageViewDefaultWrapper,
    ListMenu,
    Filters
  },
  mixins: [DataListMixin, ViewPanelShowMixin],
  data () {
    return {
      filterSchemaKey: 'CardEmailsList',
      columns: tableOptions,
      API,
      noResultsText: 'Нет писем',
      defaultFilters: {
        folderId: 0,
        sortDirection: 'desc', // asc || desc
        sortField: 'createdAt'
      },
      expandedItems: []
    }
  },
  methods: {
    toggleExpanded (id) {
      console.warn('toggleExpanded', id, this.expandedItems)
      const expItemIndex = this.expandedItems.indexOf(id)
      if (expItemIndex !== -1) {
        this.expandedItems.splice(expItemIndex, 1)
      } else {
        this.expandedItems.push(id)
      }
    },
    create () {
      this.showEmailCard({})
    },
    show (item) {
      console.log('show', item)
      this.showEmailCard(
        {
          id: item.id
        },
        () => {
          console.warn('CALLBACK showEmailCard')
          this.refresh()
        })
    },
    showProfile () {
      this.showMyProfilePanel({}, () => {
        //
      })
    }
  },
  computed: {
    ...mapGetters({
      UserEmailConfigs: 'profile/UserEmailConfigs'
    }),
    isUserEmailBoxesNone () {
      return !this.UserEmailConfigs || !Array.isArray(this.UserEmailConfigs) || !this.UserEmailConfigs.length
    },
    isUserEmailFoldersNone () {
      return !this.defaultFilters.folderId
    }
  },
  watch: {
    isUserEmailBoxesNone: {
      handler (newValue) {
        // console.error('isUserEmailBoxesNone ', newValue, oldValue)
        if (!newValue) {
          // console.error('isUserEmailBoxesNone ', this.defaultFilters.folderId)
          if (!this.isUserEmailFoldersNone) {
            this.fetch()
          }
        }
      },
      immediate: true
    }
  }
}
</script>
