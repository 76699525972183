import { tableItemStyleOneGen, TBaseTableHeaderItem } from '@/components/tw-ui/table/TableTypes'

const options: TBaseTableHeaderItem[] = [
  {
    ...tableItemStyleOneGen(true),
    text: '',
    dataKey: 'menu',
    colStyle: 'width: 50px;'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Контакты',
    dataKey: 'contacts'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Тема',
    dataKey: 'theme'
  },
  {
    ...tableItemStyleOneGen(true),
    text: 'Дата',
    dataKey: 'dateMailBox',
    colStyle: 'width: 120px;'
  }
]

export default options
