<template>
  <div>
    <TwPanelContentBlock
      no-data-text="Отсутствует"
      class="tw-mb-[6px]"
    >
      <template #title>
        {{ type.label }}
      </template>
      <template #action>
        <button
          v-if="!(hasByRealtor && !isNew)"
          :disabled="isEdit"
          class="tw-text-main-xs enabled:tw-text-an-flat-gray-4 enabled:hover:tw-text-an-flat-dark-blue-link-active disabled:tw-text-an-flat-disabled-text"
          @click.prevent="isEdit = true"
        >
          {{ isNew ? 'Добавить' : 'Изменить' }}
        </button>
      </template>
      <template #default>
        <div
          v-if="isEdit || !isNew"
          class="tw-flex tw-gap-[12px] tw-flex-col"
        >
          <div
            v-if="isEdit"
            class="tw-flex tw-gap-[20px] tw-flex-col"
          >
            <TwSelect
              v-if="hasNeedlessSourceAndStatus"
              label="Статус"
              :persistent-label="true"
              :multi="false"
              disable-error-border
              :value="form.status"
              :disabled="formProcessing"
              :error-text="$v.form.status.$invalid ? 'Выберите статус':''"
              :options="allowInputStatusList"
              @input="changeStatus"
            />
            <TwSelect
              v-if="hasNeedlessSourceAndStatus && dictionary.agencyDiscoveryMethod"
              label="Откуда пришёл клиент"
              :persistent-label="true"
              :multi="false"
              disable-error-border
              :value="form.agencyDiscoveryMethod"
              :disabled="formProcessing"
              :error-text="form.agencyDiscoveryMethod ? '' : 'Выберите значение'"
              :options="dictionary.agencyDiscoveryMethod"
              @input="form.agencyDiscoveryMethod = $event"
            />
            <TwTextarea
              v-if="form.agencyDiscoveryMethod === 'own_option'"
              v-model="form.agencyDiscoveryMethodText"
              label="Свой вариант"
              placeholder="Свой вариант"
              :persistent-label="true"
              :show-label="true"
              :disabled="formProcessing"
              :input-class-error="''"
              :error-text="$v.form.agencyDiscoveryMethodText.$invalid ? 'Описание, откуда пришёл клиент' : ''"
            />
            <TwSelectButton
              v-model="form.rating"
              :dense="false"
              :multi="false"
              label="Оценка"
              disable-error-border
              :disabled="formProcessing || form.status === 'not_pick_up_phone'"
              :error-text="$v.form.rating.$invalid ? 'Выберите оценку' : ''"
              :options="ratingList"
            />
            <span
              v-if="form.rating === 0"
              class="tw-text-an-flat-red-main"
            >* Отказался давать оценку</span>
            <TwTextarea
              v-model="form.comment"
              rows="5"
              persistent-label
              placeholder="Комментарий"
              :disabled="formProcessing"
              :show-label="true"
            />

            <div>
              <div
                v-if="$v.form.$invalid"
                class="tw-text-an-flat-red-main tw-text-center tw-text-main-xs"
              >
                Заполните обязательные поля
              </div>
              <TwCardBlockFormActions
                title-submit="Сохранить"
                :use-cancel-alert="false"
                :handle-submit="handleSubmit"
                :handle-cancel="handleCancel"
                :disable-submit="formProcessing || $v.form.$invalid"
                :disable-cancel="formProcessing"
              />
            </div>
          </div>

          <div
            v-else
            class="tw-flex tw-gap-[12px] tw-flex-col"
          >
            <TwPanelContentBlockFieldView v-if="hasNeedlessSourceAndStatus">
              <template #title>
                Статус
              </template>
              <div
                v-if="reviewDataStatusObj[info.status]"
                class="tw-font-semibold"
              >
                {{ reviewDataStatusObj[info.status].label }}
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Оценка
              </template>
              <div v-if="info.rating !== null">
                <span
                  v-if="info.rating === 0"
                  class="tw-text-an-flat-disabled-text"
                >Отказался давать оценку</span>
                <span
                  v-else
                  class="tw-font-semibold"
                >{{ info.rating }}</span>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView v-if="info && hasNeedlessSourceAndStatus">
              <template #title>
                Откуда узнали об агентстве
              </template>
              <div
                v-if="resultAgencyDiscoveryMethod"
                class="tw-font-semibold"
              >
                {{ resultAgencyDiscoveryMethod }}
              </div>
              <div v-else>
                Не указано
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView v-if="info && info.agencyDiscoveryMethod === 'own_option'">
              <template #title>
                Описание, откуда узнали об агентстве
              </template>
              <div
                v-if="info.agencyDiscoveryMethodText"
                class="tw-font-semibold"
              >
                {{ info.agencyDiscoveryMethodText }}
              </div>
              <div v-else>
                Не указано
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView v-if="hasNeedlessSourceAndStatus">
              <template #title>
                Источник данных
              </template>
              <div
                v-if="info.source_data"
                class="tw-font-semibold"
              >
                {{ info.source_data }}
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Комментарий
              </template>
              <div v-if="info.comment">
                <ReadMoreExpander
                  :is-nl-trigger="false"
                  :text="info.comment"
                  :less-length="100"
                />
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Дата
              </template>
              <div v-if="info.updatedAt">
                {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt) }}
              </div>
              <div v-else-if="info.createdAt">
                {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Создал
              </template>
              <ViewPanelLink
                v-if="info.creatorId"
                :id="info.creatorId"
                label="Сотрудник"
                component="employee-show"
              >
                <EmployeeName :id="info.creatorId" />
              </ViewPanelLink>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView v-if="info.updateUserId && info.updateUserId !== info.creatorId">
              <template #title>
                Изменил
              </template>
              <ViewPanelLink
                v-if="info.updateUserId"
                :id="info.updateUserId"
                label="Сотрудник"
                component="employee-show"
              >
                <EmployeeName :id="info.updateUserId" />
              </ViewPanelLink>
            </TwPanelContentBlockFieldView>
          </div>
        </div>
      </template>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import dealReviewDataAPI from '@/Api/Review/DealReviewData'
import dealReviewByRealtorAPI from '@/Api/Review/DealReviewByRealtor'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import { required } from 'vuelidate/lib/validators'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'

export default {
  name: 'DealReviewTypeBlock',
  components: {
    ViewPanelLink,
    EmployeeName,
    TwSelect,
    TwSelectButton,
    ReadMoreExpander,
    TwTextarea,
    TwCardBlockFormActions,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock
  },
  props: {
    reviewId: {
      type: Number,
      default: 0
    },
    type: {
      type: Object,
      default: null
    },
    info: {
      type: Object,
      default: null
    },
    needEditAuto: { // Нужно открывать редактирование автоматически
      type: Boolean,
      default: false
    },
    hasByRealtor: { // ИСпользовать апи для риелтора - для контроля прав на беке
      type: Boolean,
      default: false
    },
    dictionary: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      API: this.hasByRealtor ? dealReviewByRealtorAPI : dealReviewDataAPI,
      isEdit: false,
      formProcessing: false,
      form: {
        status: null,
        rater: null,
        rating: null,
        comment: null,
        agencyDiscoveryMethod: null,
        agencyDiscoveryMethodText: null
      }
    }
  },
  validations () {
    return {
      form: {
        status: {
          ...(this.hasNeedlessSourceAndStatus) && { required }
        },
        rating: {
          ...(this.hasNeedlessSourceAndStatus && this.form.status === 'success' && this.form.status !== 'not_pick_up_phone') && {
            required
          }
        },
        agencyDiscoveryMethodText: {
          ...(this.form.agencyDiscoveryMethod === 'own_option') && {
            required
          }
        }
      }
    }
  },
  methods: {
    changeStatus (eventValue) {
      this.form.status = eventValue
      if (eventValue === 'not_pick_up_phone') {
        this.form.rating = null
      }
    },
    handleSubmit () {
      this.formProcessing = true

      if (this.form.agencyDiscoveryMethod !== 'own_option') {
        this.form.agencyDiscoveryMethodText = null
      }
      if (this.isNew) {
        this.API.store(this.reviewId, this.form)
          .then(() => {
            this.$emit('refresh')
            this.isEdit = false
          })
          .catch((error) => {
            console.error('handleSubmit', error)
          })
          .finally(() => {
            this.formProcessing = false
          })
      } else {
        this.API.update(this.info.id, this.form)
          .then(() => {
            this.$emit('refresh')
            this.isEdit = false
          })
          .catch((error) => {
            console.error('handleSubmit', error)
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    },
    handleCancel () {
      this.isEdit = false
    }
  },
  computed: {
    isNew () {
      return this.info === null
    },
    hasNeedlessSourceAndStatus () {
      return !!(this.type && this.type.value !== 'juristByRealtor' && this.type.value !== 'mortgageBrokerByRealtor')
    },
    reviewDataStatusObj () {
      return Array.isArray(this.dictionary.reviewDataStatus) ? this.dictionary.reviewDataStatus.reduce((result, item) => {
        return {
          ...result,
          [item.value]: item
        }
      }, {}) : {}
    },
    allowInputStatusList () {
      return Array.isArray(this.dictionary.reviewDataStatus) ? this.dictionary.reviewDataStatus.filter((item) => {
        return item.value === 'not_pick_up_phone' || item.value === 'success'
      }) : []
    },
    ratingList () {
      return Array.from({ length: 11 }, (v, i) => {
        return {
          label: String(i),
          value: i
        }
      })
    },
    resultAgencyDiscoveryMethod () {
      if (this.dictionary.agencyDiscoveryMethod && this.info.agencyDiscoveryMethod) {
        for (const item of this.dictionary.agencyDiscoveryMethod) {
          if (item.value === this.info.agencyDiscoveryMethod) {
            return item.label
          }
        }
      }
      return null
    }
  },
  watch: {
    isEdit (newValue) {
      if (newValue) {
        this.form.rater = this.type?.value || null
        this.form.status = this.info?.status || null
        this.form.rating = typeof this.info?.rating === 'number' ? this.info.rating : null
        this.form.comment = this.info?.comment || null
        this.form.agencyDiscoveryMethod = this.info?.agencyDiscoveryMethod || null
        this.form.agencyDiscoveryMethodText = this.info?.agencyDiscoveryMethodText || null
      }
    }
  },
  mounted () {
    if (this.needEditAuto && !this.info) {
      this.isEdit = true
    }
  }
}
</script>
