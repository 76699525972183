<template>
  <div
    v-if="formProcessing"
    class="tw-py-3 tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    class="tw-font-manrope"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-grid tw-gap-[50px] tw-grid-cols-2">
      <div>
        <div class="tw-mt-[12px] tw-flex tw-gap-[10px]">
          <TwInputText
            v-model="form.name"
            max-length="50"
            type="text"
            class="tw-flex-1"
            placeholder="Название"
            :error-text="$v.form.name.$invalid ? '*Обязательное поле':''"
            :disabled="formProcessing"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
          />

          <TwCheckBox
            v-model="form.isExclusive"
            :disabled="formProcessing"
            label="Эксклюзивный ЖК"
          />
        </div>

        <TwSelectRelations
          v-if="!isCottageVillage"
          v-model="form.builderId"
          class="tw-mt-[12px]"
          :disabled="formProcessing"
          :multi="false"
          :dense="true"
          label="Застройщик"
          list-label="Найти застройщика"
          relation-name="developer"
          :error-text="$v.form.builderId.$invalid ? '*Обязательное поле':''"
        />
        <TwInputText
          v-if="!isCottageVillage"
          v-model="form.link"
          type="text"
          class="tw-mt-[12px]"
          placeholder="Ссылка на сайт застройщика"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
        />

        <div class="tw-mt-[15px]">
          <div class="tw-text-an-flat-blue-btn-active tw-text-sm2 tw-mb-[5px] tw-mt-[10px]">
            Генеральный план:
          </div>
          <TwUploadFilesList
            label="Файл генеральный плана"
            :value="form.genPlanFileId ? [form.genPlanFileId] : []"
            :api="osFileAPI"
            file-type="complexGenPlan"
            :has-inline="false"
            :multiple="false"
            @input="form.genPlanFileId = $event.length ? $event[0] : null"
          />
        </div>

        <!--    При создании формируется из name при его изменении, при редактировании только вручную    -->
        <TwInputText
          v-model="complexCodeTranslate"
          type="text"
          max-length="50"
          class="tw-mt-[12px]"
          placeholder="ЧПУ код ЖК для сайта"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
        />

        <div class="tw-mt-[12px] tw-flex tw-gap-[35px]">
          <TwCheckBox
            v-model="form.active"
            class="tw-flex-1"
            :disabled="formProcessing"
            label="Учавствует в подборе и показывается на сайте"
          />
          <TwCheckBox
            v-model="form.hidePrice"
            class="tw-flex-1"
            :disabled="formProcessing"
            label="Скрытие цен на сайте"
          />
        </div>
      </div>
      <!--  Ввод свойств  -->
      <RealtyObjectPropsForm
        v-if="scheme && form.properties"
        :scheme="scheme"
        :properties="form.properties"
        @invalid="hasInvalidProps = $event"
        @changeProperty="changeProperty"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="true"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing || formType === 'create'"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    >
      <template #info>
        <div
          v-if="serverErrorMessage"
          class="tw-text-an-flat-red-main tw-text-center tw-p-[8px] tw-mb-[5px] tw-bg-white"
        >
          {{ serverErrorMessage }}
        </div>
      </template>
    </TwCardSimpleFormSaveBlock>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import osFileAPI from '@/Api/ObjectStorage/File'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import RealtyObjectPropsForm from '@/views/Newbuildings/Editor/RealtyObjectPropsForm.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import { preparePropertiesForForm } from '@/views/Newbuildings/Editor/utils'

export default {
  name: 'RealtyComplexMainForm',
  components: {
    TwUploadFilesList,
    TwCheckBox,
    TwCardSimpleFormSaveBlock,
    TwSelectRelations,
    TwInputText,
    TwCircleLoader,
    RealtyObjectPropsForm
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      osFileAPI,
      API: API.Complex,
      form: {},
      hasInvalidProps: false,
      createQueryParams: {
        type: null
      }
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations () {
    return {
      form: {
        // Название поля должно совпадать с полем в form
        name: {
          required
        },
        builderId: {
          ...(!this.isCottageVillage) && {
            required
          }
        }
      }
    }
  },
  computed: {
    isCottageVillage () {
      return this.form.type === 'cottage_village'
    },
    complexCodeTranslate: {
      get () {
        return this.form?.code
      },
      set (val) {
        this.$set(this.form, 'code', this.$Global.generateUrlCode(val))
      }
    },
    adaptedMapGeoPoint: {
      get () {
        return this.$Global.geoPointArrayToObject(this.form?.mapPoint)
      },
      set (val) {
        this.$set(this.form, 'mapPoint', this.$Global.geoPointObjectToArray(val))
      }
    }
  },
  watch: {
    'form.name': {
      handler (newVal) {
        if (this.formType === 'create') {
          this.$set(this.form, 'code', this.$Global.generateUrlCode(newVal))
        }
      }
    },
    createQueryParams: {
      handler (newValue) {
        if (newValue) {
          this.fetchData()
        }
      },
      deep: true
    }
  },
  created () {
    this.createQueryParams = this.defaultFormFields
  },
  methods: {
    mapPointChange (pointData) {
      console.warn('mapPointChange', pointData)
      this.adaptedMapGeoPoint = pointData
    },
    changeProperty ({ key, value }) {
      console.warn('changeProperty ', { key, value })
      if (!this.form?.properties) {
        this.$set(this.form, 'properties', {})
      }
      this.$set(this.form.properties, key, value)
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            builder: 'builderId',
            genPlanFile: 'genPlanFileId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                this.$set(this.form, adaperMap[key], (this.preset[key] && this.preset[key].id) || this.defaultFormFields[adaperMap[key]])
              }
            } else if (key === 'gallery') {
              this.$set(this.form, key, null)
            } else if (key === 'type') {
              this.$set(this.form, key, this.preset[key]?.value || null)
            } else if (key === 'properties') {
              const preparedProperties = preparePropertiesForForm(this.preset[key])
              this.$set(this.form, key, {
                ...preparedProperties
              })
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.form.$reset()
      this.$v.form.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (!this.$v.form.$invalid && !this.hasInvalidProps) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true

        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).catch((error) => {
          this.serverErrorMessage = error?.serverErrorMessage || 'Ошибка!'
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
