<template>
  <div
    v-if="formProcessing"
    class="py-3 text-center ma-auto"
  >
    <v-progress-circular
      size="50"
      color="primary"
      indeterminate
    />
  </div>
  <div v-else>
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="tw-my-[12px]">
      <div class="tw-mb-[7px]">
        Изображение:
      </div>
      <TwUploadFilesList
        class="tw-mt-[26px]"
        label="Загрузить файл изображения"
        no-data-text="Нет прикрепленнных файлов"
        file-type="complex_gallery"
        :api="osFileAPI"
        :value="form.fileId ? [form.fileId] : []"
        :multiple="false"
        :disabled="formProcessing"
        @input="form.fileId = $event[0] || null"
      />
    </div>
    <div>
      <TwTextarea
        v-model="form.description"
        rows="3"
        class="tw-mt-[12px]"
        :disabled="formProcessing"
        placeholder="Описание слайда"
        :dense="true"
        persistent-label
        :show-label="true"
      />
      <TwInputText
        v-model.number="form.sort"
        type="number"
        class="tw-mt-[12px]"
        placeholder="Номер для сортировки порядка показа слайдов"
        :show-label="true"
        :dense="true"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      success-title="Сохранить"
      :is-sticky="true"
      :disable-success="formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import osFileAPI from '@/Api/ObjectStorage/File'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'RealtyComplexGalleryForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwInputText,
    TwTextarea,
    TwUploadFilesList
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      osFileAPI,
      API: API.ComplexGallery,
      form: {
        complexId: this.defaultFormFields?.complexId || null,
        fileId: null,
        description: null
      }
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'file') {
              this.$set(this.form, 'fileId', this.preset[key]?.id || null)
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.formProcessing === true) {
        return
      }

      this.formProcessing = true
      const request = this.formType === 'create'
        ? this.API.store(this.form)
        : this.API.update(this.targetId, this.form)

      request.then(({ data }) => {
        if (this.formType === 'create') {
          this.$emit('create', data.data)
          // если текущий путь равен пути создания то редирект в просмотр
          if (this.$route.name === `${RouteKeys.lead}-create`) {
            this.$router.replace({
              name: `${RouteKeys.lead}-show`,
              params: { id: data.data.id }
            })
          }
        } else {
          this.$emit('update', data.data)
        }
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>
