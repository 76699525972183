import { RouteKeys } from '@/router/types'
export default {
  path: `${RouteKeys.seoPage}`,
  name: RouteKeys.seoPage,
  redirect: { name: `${RouteKeys.seoPage}-list` },
  meta: { name: 'SEO страницы ЖК', icon: 'admin-newbuildings-seo' },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.seoPage}-list`,
      meta: { name: 'Список SEO страницы ЖК', icon: 'admin-newbuildings-seo', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Newbuildings/SeoPage/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.seoPage}-edit`,
      meta: { name: 'Редактирование SEO страницы ЖК', icon: 'admin-newbuildings-seo', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Newbuildings/SeoPage/Edit.vue'),
      props: (route: any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.seoPage}-create`,
      meta: { name: 'Добавление SEO страницы ЖК', icon: 'admin-newbuildings-seo', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Newbuildings/SeoPage/Edit.vue'),
      props: (route: any) => ({ id: Number(route.params.id) })
    }
  ]
}
