<template>
  <div>
    <TwFlexCol>
      <TwFlexCell>
        <TwInputText
          v-model="fields.number"
          type="text"
          autocomplete="off"
          label="Номер"
          placeholder="Номер"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
        />
      </TwFlexCell>
      <TwFlexRow base-classes="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-[12px]">
        <TwFlexCell>
          <TwInputText
            v-model="fields.dateIssued"
            type="date"
            label="Дата выдачи"
            :max="new Date().toISOString().slice(0, 10)"
            min="1920-01-01"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="fields.dateExpire"
            type="date"
            label="Срок действия"
            min="1920-01-01"
            :show-label="true"
            :dense="true"
            :persistent-label="true"
          />
        </TwFlexCell>
      </TwFlexRow>

      <TwFlexCell>
        <TwTextarea
          v-model="fields.whoIssued"
          rows="3"
          label="Кем выдан"
          placeholder="Кем выдан"
          persistent-label
          :show-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwTextarea
          v-model="fields.placeBirth"
          rows="3"
          label="Место рождения"
          placeholder="Место рождения"
          persistent-label
          :show-label="true"
        />
      </TwFlexCell>
      <TwFlexCell>
        <TwTextarea
          v-model="fields.placeRegistration"
          rows="3"
          label="Место регистрации"
          placeholder="Место регистрации"
          persistent-label
          :show-label="true"
        />
      </TwFlexCell>
    </TwFlexCol>
  </div>
</template>

<script>
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'ForeignPassportType',
  components: {
    TwTextarea,
    TwFlexCell,
    TwFlexCol,
    TwFlexRow,
    TwInputText
  },
  props: {
    formType: {},
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    fields: {
      get () {
        return this.value
      }
    }
  }
}
</script>

<style scoped>

</style>
