<template>
  <div
    v-if="info"
  >
    <div class="tw-my-[20px]">
      <div class="tw-flex tw-font-medium">
        <EmailFromToShow
          :email-folder="info.emailFolder"
          :email-config-contact="info.emailConfigContact"
          :contacts="info.contacts"
        />
        <div class="tw-flex-1" />
        <div class="tw-text-right">
          <div>
            {{ $Global.DateFormat.DateTimeShort(info.dateMailBox || info.updatedAt) }}
          </div>
          <div
            v-if="calcFilesSize"
            class="tw-capitalize"
          >
            Сообщение: {{ $Global.formatBytes(calcFilesSize) }}
          </div>
        </div>
      </div>
      <hr class="tw-my-[20px]">
      <div>
        <div
          class="email-body"
          v-html="info.body"
        />
        <hr class="tw-my-3">
        <div class="tw-mb-[15px]">
          <span class="tw-text-h6">Прикреплённые файлы:</span>
          <TwFilesListViewByIds
            :file-ids="emailFileIds"
            :api="emailFileApi"
            :is-drop-down="false"
            view-type="list"
          />
        </div>
        <div v-if="Array.isArray(info.crmFileId) && info.crmFileId.length">
          <span class="tw-text-h6">Файлы клиентов:</span>
          <TwFilesListViewByIds
            :file-ids="info.crmFileId || []"
            :is-drop-down="false"
            view-type="list"
            no-data-text="Файлы клиентов не прикреплены"
          />
        </div>
      </div>
    </div>
    <div
      v-if="(info.status && info.status.value === 'got') && (info.emailFolder.type && info.emailFolder.type.value === 'inbox')"
    >
      <div
        v-if="!fastReplyAll"
        class="panel-view-link tw-my-[20px]"
        @click="fastReplyAll = true"
      >
        Написать быстрый ответ всем участникам переписки
      </div>
      <EmailMainReply
        v-if="fastReplyAll"
        :reply-id="info.id"
        :reply-from="info"
        @hide="fastReplyAll = false"
      />
    </div>
  </div>
</template>

<script>
import emailFileApi from '@/Api/Email/File'
import EmailFromToShow from '@/views/Email/main/EmailFromToShow.vue'
import EmailMainReply from '@/views/Email/main/EmailMainReply.vue'
import TwFilesListViewByIds from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'

export default {
  name: 'EmailMainShow',
  components: { TwFilesListViewByIds, EmailMainReply, EmailFromToShow },
  props: {
    info: {},
    dictionary: {}
  },
  data () {
    return {
      emailFileApi,
      fastReplyAll: false
    }
  },
  computed: {
    emailFileIds () {
      return Array.isArray(this.info.files) && this.info.files.length ? this.info.files.map(f => f.id) : []
    },
    calcFilesSize () {
      return Array.isArray(this.info?.files) && this.info.files.length ? this.info.files.reduce(function (accumulate, item) {
        return accumulate + item.size || 0
      }, 0) : 0
    }
  }
}
</script>
