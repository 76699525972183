import DealReview from '@/Api/Review/DealReview'
import CrmDealBuyNbResultManagement from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import CrmRequestToDeveloperManagement from '@/Api/Rtd/RequestToDeveloper/Management'
import { API as ObjectStorage } from '@/Api/ObjectStorage'
import { API as BuilderImporter } from '@/Api/BuilderImporter'
import CrmDealBounceHandling from '@/Api/Crm/DealBounceHandling'
import DealDistributionResponsibleUser from '@/Api/Crm/Company/DealDistributionResponsibleUser'
import DealDistributionChangesComplexes from '@/Api/Crm/Company/DealDistributionChangesComplexes'
import Lead from '@/Api/Crm/Lead'
import Deal from '@/Api/Crm/Deal'
import Person from '@/Api/Crm/Person'
import User from '@/Api/Auth/User'
import CompanyReview from '@/Api/Site/Admin/Company/Review'
import TelephonyCall from '@/Api/Telephony/Call'
import CompanyList from '@/Api/Crm/Company/index'
import MortgageProgramList from '@/Api/Mortgage/Program'
import MortgageProgramCategoryList from '@/Api/Mortgage/ProgramCategory'
import MortgageUserCommentsList from '@/Api/Mortgage/UserComments'
import MortgageRequirementList from '@/Api/Mortgage/Requirement'
import MortgageComplexParamsList from '@/Api/Mortgage/ComplexParams'
import SupportTaskList from '@/Api/Support/Task'
import LeadSourceList from '@/Api/Crm/Lead/Source'
import LeadStatusList from '@/Api/Crm/Lead/Status'
import DealStatusList from '@/Api/Crm/Deal/Status'
import { API as Accounting } from '@/Api/Accounting'
import RealtorSurvey from '@/Api/Review/RealtorSurvey'
import DealContractList from '@/Api/Crm/Deal/Contract'
import SurveyDeals from '@/Api/Review/RealtorSurvey/Deal'
import ComplexPlatformId from '@/Api/ObjectStorage/Ads/ComplexPlatformId'
import BuilderProject from '@/Api/ObjectStorage/Builder/project'
import LandingAdminLanding from '@/Api/Landing/Admin/Landing'
import CrmSystemTagMessageList from '@/Api/Crm/System/TagMessage'
import DocCheckKonturList from '@/Api/DocCheck/Kontur/Check'
import OsLandHouseList from '@/Api/ObjectStorage/Realty/Editor/NewBuilding/Land'
import AccountingBuyNbActList from '@/Api/Accounting/BuyNb/Act'
import AccountingBuyNbResultList from '@/Api/Accounting/BuyNb/Result'
import AccountingUserCalcList from '@/Api/Accounting/UserCalc'
import AdsComplexPhotoList from '@/Api/ObjectStorage/Ads/ComplexPhoto'

export const ServicesSchema = {
  RealtyEditorPlan: {
    key: 'RealtyEditorPlan',
    listInit: ObjectStorage.Editor.Plan.listInit
  },
  RealtyBuilderList: {
    key: 'RealtyBuilderList',
    listInit: ObjectStorage.Builder.listInit
  },
  DealReview: {
    key: 'DealReview',
    listInit: DealReview.listInit
  },
  CrmRequestToDeveloperManagement: {
    key: 'CrmRequestToDeveloperManagement',
    listInit: CrmRequestToDeveloperManagement.listInit
  },
  CrmDealBuyNbResultManagement: {
    key: 'CrmDealBuyNbResultManagement',
    listInit: CrmDealBuyNbResultManagement.listInit
  },
  CrmDealBounceHandling: {
    key: 'CrmDealBounceHandling',
    listInit: CrmDealBounceHandling.listInit
  },
  DealDistributionResponsibleUser: {
    key: 'DealDistributionResponsibleUser',
    listInit: DealDistributionResponsibleUser.listInit
  },
  DealDistributionChangesComplexes: {
    key: 'DealDistributionChangesComplexes',
    listInit: DealDistributionChangesComplexes.listInit
  },
  LeadList: {
    key: 'LeadList',
    listInit: Lead.listInit
  },
  DealList: {
    key: 'DealList',
    listInit: Deal.listInit
  },
  PersonList: {
    key: 'PersonList',
    listInit: Person.listInit
  },
  UserList: {
    key: 'UserList',
    listInit: User.listInit
  },
  ComplexList: {
    key: 'ComplexList',
    listInit: ObjectStorage.Complex.listInit
  },
  ImportBuilderFile: {
    key: 'ImportBuilderFile',
    listInit: BuilderImporter.ImportBuilderFile.listInit
  },
  ImportBuilderSite: {
    key: 'ImportBuilderSite',
    listInit: BuilderImporter.importBuilderSite.listInit
  },
  ImportBuilderDocumentFile: {
    key: 'ImportBuilderDocumentFile',
    listInit: BuilderImporter.ImportDocumentFile.listInit
  },
  CompanyReviewList: {
    key: 'CompanyReviewList',
    listInit: CompanyReview.listInit
  },
  TelephonyCallList: {
    key: 'TelephonyCallList',
    listInit: TelephonyCall.listInit
  },
  CompanyList: {
    key: 'CompanyList',
    listInit: CompanyList.listInit
  },
  MortgageProgramList: {
    key: 'MortgageProgramList',
    listInit: MortgageProgramList.listInit
  },
  MortgageProgramCategoryList: {
    key: 'MortgageProgramCategoryList',
    listInit: MortgageProgramCategoryList.listInit
  },
  MortgageUserCommentsList: {
    key: 'MortgageUserCommentsList',
    listInit: MortgageUserCommentsList.listInit
  },
  MortgageRequirementList: {
    key: 'MortgageRequirementList',
    listInit: MortgageRequirementList.listInit
  },
  MortgageComplexParamsList: {
    key: 'MortgageComplexParamsList',
    listInit: MortgageComplexParamsList.listInit
  },
  SupportTaskList: {
    key: 'SupportTaskList',
    listInit: SupportTaskList.listInit
  },
  LeadSourceList: {
    key: 'LeadSourceList',
    listInit: LeadSourceList.listInit
  },
  LeadStatusList: {
    key: 'LeadStatusList',
    listInit: LeadStatusList.listInit
  },
  DealStatusList: {
    key: 'DealStatusList',
    listInit: DealStatusList.listInit
  },
  AccountingDealCommissionList: {
    key: 'AccountingDealCommissionList',
    listInit: Accounting.DealCommission.listInit
  },
  UserSurveysList: {
    key: 'UserSurveysList',
    listInit: RealtorSurvey.listInit
  },
  SurveyDealsList: {
    key: 'SurveyDealsList',
    listInit: SurveyDeals.listInit
  },
  DealContractList: {
    key: 'DealContractList',
    listInit: DealContractList.listInit
  },
  AdsComplexPlatformIdList: {
    key: 'AdsComplexPlatformIdList',
    listInit: ComplexPlatformId.listInit
  },
  BuilderProjectsList: {
    key: 'BuilderProjectsList',
    listInit: BuilderProject.listInit
  },
  LandingAdminLandingList: {
    key: 'LandingAdminLandingList',
    listInit: LandingAdminLanding.listInit
  },
  CrmSystemTagMessageList: {
    key: 'CrmSystemTagMessageList',
    listInit: CrmSystemTagMessageList.listInit
  },
  DocCheckKonturList: {
    key: 'DocCheckKonturList',
    listInit: DocCheckKonturList.listInit
  },
  OsLandHouseList: {
    key: 'OsLandHouseList',
    listInit: OsLandHouseList.listInit
  },
  AccountingBuyNbActList: {
    key: 'AccountingBuyNbActList',
    listInit: AccountingBuyNbActList.listInit
  },
  AccountingBuyNbResultList: {
    key: 'AccountingBuyNbResultList',
    listInit: AccountingBuyNbResultList.listInit
  },
  AccountingUserCalcList: {
    key: 'AccountingUserCalcList',
    listInit: AccountingUserCalcList.listInit
  },
  AdsComplexPhotoList: {
    key: 'AdsComplexPhotoList',
    listInit: AdsComplexPhotoList.listInit
  }
}

export type TServicesSchemaKeys = keyof typeof ServicesSchema
