<template>
  <div>
    <button
      :disabled="!isDropDown"
      class="tw-flex tw-justify-between tw-w-[100%]"
      @click="active = !active"
    >
      <b
        v-if="title"
        class="tw-block tw-mb-[10px]"
      >{{ title }}</b>
      <div v-if="isDropDown">
        <span
          v-show="active"
          class="tw-block tw-rotate-180"
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 10.2979L9 7.79785L6 10.2979"
              stroke="#545961"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </span>
        <span
          v-show="!active"
          class="tw-block tw-rotate-0"
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 10.2979L9 7.79785L6 10.2979"
              stroke="#545961"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </span>
      </div>
    </button>
    <div
      v-show="active"
      :class="[isDropDown ? 'tw-pt-[22px] tw-border-t tw-border-[#ECECED] tw-mt-[10px]' : '']"
    >
      <template v-if="Array.isArray(files) && files.length">
        <template v-if="viewType === 'grid'">
          <ul
            v-if="Array.isArray(imageFiles) && imageFiles.length"
            class="tw-flex tw-flex-wrap"
          >
            <li
              v-for="(file, index) in imageFiles"
              :key="index"
              class="tw-mb-[10px]"
            >
              <div class="tw-mr-[11px] ">
                <img
                  class="tw-block tw-h-[90px] tw-w-[86px] tw-mb-[8px] tw-bg-an-flat-bg-main tw-cursor-zoom-in"
                  :src="getFileResizeByKey(file.fileServer, 'default')"
                  :alt="file.fileServer.name"
                  @click="viewImages(imageFiles, index)"
                  @error="imageLoadError(index)"
                >
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span class="tw-block tw-text-an-flat-not-active-input tw-truncate">{{ $Global.formatBytes(file.fileServer.size) }}</span>
                  <div class="tw-ml-auto tw-flex tw-gap-[6px]">
                    <div
                      v-if="hasDownload"
                    >
                      <button
                        class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm enabled:hover:tw-bg-an-flat-blue-btn  enabled:hover:tw-text-white"
                        @click.stop="download(file.fileServer)"
                      >
                        <TwIconWrapper
                          file-name="download-1"
                          :size="15"
                        />
                      </button>
                    </div>
                    <slot
                      name="other-actions"
                      v-bind="{ fileId: file.id }"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul
            v-if="Array.isArray(otherFiles) && otherFiles.length"
            class="tw-flex tw-flex-wrap tw-gap-[12px]"
          >
            <li
              v-for="(file, index) in otherFiles"
              :key="index"
              class="tw-flex tw-gap-[8px] tw-items-center"
            >
              <div>
                <TwIconWrapper
                  class="tw-text-an-flat-gray-3"
                  file-name="document-1"
                  :size="20"
                />
              </div>
              <div class="tw-block tw-text-main-sm2 tw-whitespace-nowrap">
                {{ file.fileServer.name }}
              </div>
              <div class="tw-block tw-text-an-flat-not-active-input tw-leading-[22px]">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <span class="tw-block tw-text-an-flat-not-active-input tw-truncate">{{ $Global.formatBytes(file.fileServer.size) }}</span>
                  <div class="tw-ml-auto tw-flex tw-gap-[6px]">
                    <div
                      v-if="hasDownload"
                    >
                      <button
                        class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm enabled:hover:tw-bg-an-flat-blue-btn  enabled:hover:tw-text-white"
                        @click.stop="download(file.fileServer)"
                      >
                        <TwIconWrapper
                          file-name="download-1"
                          :size="15"
                        />
                      </button>
                    </div>
                    <slot
                      name="other-actions"
                      v-bind="{ fileId: file.id }"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <template v-if="viewType === 'list'">
          <ul class="tw-flex tw-flex-col tw-gap-[4px]">
            <li
              v-for="(file, index) in files"
              :key="index"
              class="tw-bg-white tw-rounded-sm tw-p-[10px] tw-flex tw-gap-[10px] tw-items-center"
              @click.passive="file.fileServer.mime.includes('image') ? viewImages(imageFiles, index) : ''"
            >
              <div>
                <img
                  v-if="file.fileServer.mime.includes('image')"
                  class="tw-rounded-xs tw-block tw-h-[20px] tw-min-w-[20px] tw-w-[20px] tw-bg-an-flat-bg-main tw-cursor-zoom-in"
                  :src="getFileResizeByKey(file.fileServer, 'default')"
                  :alt="file.fileServer.name"
                  @error="imageLoadError(index)"
                >
                <TwIconWrapper
                  v-else
                  class="tw-text-an-flat-gray-3"
                  file-name="document-1"
                  :size="20"
                />
              </div>
              <div class="tw-truncate">
                {{ file.fileServer.name }}
              </div>
              <div class="tw-whitespace-nowrap tw-text-an-flat-disabled-text">
                {{ $Global.formatBytes(file.fileServer.size) }}
              </div>
              <div class="tw-ml-auto tw-flex tw-gap-[6px]">
                <div
                  v-if="hasDownload"
                >
                  <button
                    class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm enabled:hover:tw-bg-an-flat-blue-btn  enabled:hover:tw-text-white"
                    @click.stop="download(file.fileServer)"
                  >
                    <TwIconWrapper
                      file-name="download-1"
                      :size="15"
                    />
                  </button>
                </div>
                <slot
                  name="other-actions"
                  v-bind="{ fileId: file.id }"
                />
              </div>
            </li>
          </ul>
        </template>
        <template v-if="viewType === 'list-wrapped'">
          <ul class="tw-flex tw-flex-wrap tw-gap-[4px]">
            <li
              v-for="(file, index) in files"
              :key="index"
              class="tw-min-w-[240px] tw-bg-white tw-rounded-sm tw-p-[10px] tw-flex tw-gap-[10px] tw-items-center"
              @click.passive="file.fileServer.mime.includes('image') ? viewImages(imageFiles, index) : ''"
            >
              <div>
                <img
                  v-if="file.fileServer.mime.includes('image')"
                  class="tw-rounded-xs tw-block tw-h-[20px] tw-min-w-[20px] tw-w-[20px] tw-bg-an-flat-bg-main tw-cursor-zoom-in"
                  :src="getFileResizeByKey(file.fileServer, 'default')"
                  :alt="file.fileServer.name"
                  @error="imageLoadError(index)"
                >
                <TwIconWrapper
                  class="tw-text-an-flat-gray-3"
                  file-name="document-1"
                  :size="20"
                />
              </div>
              <div class="tw-truncate">
                {{ file.fileServer.name }}
              </div>
              <div class="tw-whitespace-nowrap tw-text-an-flat-disabled-text">
                {{ $Global.formatBytes(file.fileServer.size) }}
              </div>
              <div class="tw-ml-auto tw-flex tw-gap-[6px]">
                <div
                  v-if="hasDownload"
                >
                  <button
                    class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm enabled:hover:tw-bg-an-flat-blue-btn  enabled:hover:tw-text-white"
                    @click.stop="download(file.fileServer)"
                  >
                    <TwIconWrapper
                      file-name="download-1"
                      :size="15"
                    />
                  </button>
                </div>
                <slot
                  name="other-actions"
                  v-bind="{ fileId: file.id }"
                />
              </div>
            </li>
          </ul>
        </template>
      </template>
      <div
        v-else
        class="tw-text-center tw-text-an-flat-disabled-text tw-p-2"
      >
        {{ noDataText }}
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/File'
import FileUploadMixin from '@/mixins/FileUploadMixin'
import { getFileResizeByKey } from '@/plugins/helpers/fileHelpers'
import TwIconWrapper from '../../svg/TwIconWrapper.vue'

export default {
  name: 'TwFilesListViewByIds',
  mixins: [FileUploadMixin],
  components: { TwIconWrapper },
  props: {
    fileIds: {
      type: Array,
      default: () => []
    },
    viewType: {
      type: String,
      default: 'grid'
    },
    noDataText: {
      type: String,
      default: 'Отсутствуют'
    },
    title: {
      type: String,
      default: null
    },
    hasDownload: {
      type: Boolean,
      default: true
    },
    isDropDown: {
      type: Boolean,
      default: true
    },
    api: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      API: this.api || API,
      active: true,
      files: []
    }
  },
  computed: {
    imageFiles () {
      return this.files?.filter((file) => file?.fileServer?.mime?.includes('image'))
    },
    otherFiles () {
      return this.files?.filter((file) => !file?.fileServer?.mime?.includes('image'))
    }
  },
  watch: {
    fileIds: {
      handler () {
        this.initFiles()
      },
      immediate: true
    }
  },
  methods: {
    download (file) {
      this.API.download(file.url, file.name)
    },
    showFileInfo (fileId) {
      if (this.files.some(i => i.id === fileId)) {
        return
      }
      this.API.show(fileId)
        .then(({ data }) => {
          if (data?.data) {
            this.files.push({
              id: fileId,
              error: false,
              loading: false,
              fileServer: data.data
            })
          }
        })
    },
    initFiles () {
      for (const file of this.fileIds) {
        this.showFileInfo(file)
      }
      // clean ids
      for (const index in this.files) {
        const { id } = this.files[index]
        if (id) {
          if (!this.fileIds.includes(id)) {
            this.files.splice(index, 1)
          }
        }
      }
    },
    getFileResizeByKey: getFileResizeByKey
  }
}
</script>

<style scoped>

</style>
