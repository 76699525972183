import { AxiosResponse } from 'axios'
import HTTP, { buildInitialHttp, baseParamsSerializer } from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/ObjectStorage'

const path = '/realty/selection/new-building'

export default {
  search (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/search`, $config)
  },
  searchMultiplyField (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/search-field`, $config)
  },
  filters (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/filters`, $config)
  },
  showBuildingChess (buildingId: string, otherConfig?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}/building-chess/${buildingId}`, $config)
  },
  showObject (objectId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/object/${objectId}`, $config)
  },
  showObjectStock (objectId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/object/${objectId}/stock`, $config)
  },
  objectPriceHistory (objectId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/price-history/${objectId}`, $config)
  },
  showComplex (complexId: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/complex/${complexId}`, $config)
  },
  showPdfData (uuid: string): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/object/${uuid}/pdf-data`, $config)
  },
  selectionDownloadPdf (params: any = {}): void {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params
    }

    const link = document.createElement('a')
    link.href = `${buildInitialHttp($config).defaults.baseURL}${path}/pdf/?${baseParamsSerializer($config.params)}`
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    // удаляю ссылку и чищу память
    document.body.removeChild(link)
  }
}
