import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Accounting'

const path = (id: number) => `/accounting-buy-nb/result/${id}/payment-refusal`

export default {
  store (resultId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(path(resultId), data, $config)
  },
  update (resultId: number, data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(path(resultId), data, $config)
  },
  delete (resultId: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(path(resultId), $config)
  }
}
