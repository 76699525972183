<template>
  <TwPanelContentBlock
    no-data-text="Отсутствует"
    class="tw-mb-[6px]"
  >
    <template #title>
      Подтверждение документов
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        v-if="info && ui && ui.canEdit"
        type="edit"
        @click.stop="isEdit = !isEdit"
      >
        {{ isEdit ? 'отменить':'изменить' }}
      </TwPanelContentBlockActionButton>
      <div
        v-else-if="info && ui.canEditBlockMessage"
        class="tw-cursor-help"
      >
        <TwPopoverShort
          :open-by-hover="true"
          parent-boundary
        >
          <template #trigger>
            <TwIconWrapper
              class="tw-text-an-flat-red-main"
              file-name="question-mark-sqare-1"
              :size="20"
            />
          </template>
          <template #content>
            <div style="max-width: 250px;">
              {{ ui.canEditBlockMessage }}
            </div>
          </template>
        </TwPopoverShort>
      </div>
    </template>
    <template #default>
      <div
        v-if="loading"
        class="tw-flex tw-justify-center tw-items-center"
      >
        <TwCircleLoader
          width="30px"
          height="30px"
        />
      </div>
      <div
        v-else-if="info"
        class="tw-flex tw-gap-[12px] tw-flex-col"
      >
        <div
          v-if="isEdit"
          class="tw-flex tw-gap-[16px] tw-flex-col"
        >
          <div>
            <TwSelect
              v-if="dictionary"
              v-model="form.checkStatus"
              label="Статус подтверждения куратором"
              placeholder="Не установлен"
              :multi="false"
              :dense="true"
              :persistent-label="true"
              :disabled="formProcessing"
              :error-text="$v.form.checkStatus.$invalid ? 'Установите статус' : ''"
              :options="dictionary.checkStatus"
            />
          </div>
          <TwTextarea
            v-model="form.description"
            rows="5"
            class="tw-mt-[15px]"
            label="Комментарий"
            placeholder="Комментарий"
            :error-text="$v.form.description.$invalid ? 'Опишите причину' : ''"
            :disabled="formProcessing"
            :persistent-label="true"
            :show-label="true"
          />
          <!--    Вывод ошибок      -->
          <div class="tw-p-2 tw-text-center tw-text-an-flat-red-main">
            <div v-if="$v.form.$invalid">
              Заполните все поля формы
            </div>
            <div v-if="serverError">
              {{ serverError }}
            </div>
          </div>
          <div>
            <TwCardBlockFormActions
              title-submit="Сохранить"
              :use-cancel-alert="false"
              :disable-submit="$v.form.$invalid"
              :handle-submit="handleSubmit"
              :handle-cancel="handleCancel"
              :disable-cancel="formProcessing"
            />
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-gap-[12px] tw-flex-col"
        >
          <div class="tw-flex tw-gap-[12px] tw-flex-col">
            <TwPanelContentBlockFieldView no-data-text="Не установлен">
              <template #title>
                Статус подтверждения куратором
              </template>
              <div
                v-if="info && info.checkStatus"
                class="tw-font-medium tw-flex"
              >
                <div
                  v-if="!info.checkStatus || (info.checkStatus && !info.checkStatus.value)"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-disabled-text"
                >
                  Нет
                </div>
                <div
                  v-else-if="info.checkStatus.value === 'adjustment'"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-orange-warning tw-bg-an-flat-orange-warning/20"
                >
                  {{ info.checkStatus.label }}
                </div>
                <div
                  v-else-if="info.checkStatus.value === 'confirmed'"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-green-btn-bg tw-bg-an-flat-green-btn-bg/20"
                >
                  {{ info.checkStatus.label }}
                </div>
                <div
                  v-else-if="info.checkStatus.value === 'rejected'"
                  class="tw-font-bold tw-rounded-sm tw-px-[10px] tw-py-[4px] tw-block tw-text-an-flat-red-main tw-bg-an-flat-red-main/20"
                >
                  {{ info.checkStatus.label }}
                </div>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView
              v-if="info && info.checkUserId"
              no-data-text="Отсутствует"
            >
              <template #title>
                Куратор
              </template>
              <ViewPanelLink
                :id="info.checkUserId"
                label="Пользователь"
                component="employee-show"
              >
                <EmployeeName :id="info.checkUserId" />
              </ViewPanelLink>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView no-data-text="Отсутствует">
              <template #title>
                Комментарий
              </template>
              <div
                v-if="info.description"
                class="tw-font-medium"
              >
                <ReadMoreExpander
                  :is-nl-trigger="false"
                  :text="info.description"
                  :less-length="100"
                />
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView
              v-if="info && info.checkStatus && info.checkStatus.value === 'confirmed'"
              no-data-text="Отсутствует"
            >
              <template #title>
                Подтверждение комиссии
              </template>
              <div>
                <button
                  class="tw-text-an-flat-blue-btn hover:tw-underline"
                  @click.prevent="showAccountingLink"
                >
                  # {{ id }}
                </button>
              </div>
            </TwPanelContentBlockFieldView>
          </div>
        </div>
      </div>
    </template>
  </TwPanelContentBlock>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import API from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'
import TwPopoverShort from '@/components/tw-ui/popover/TwPopoverShort.vue'

export default {
  name: 'DealNbResultManagementStatusBlock',
  components: {
    ViewPanelLink,
    EmployeeName,
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    ReadMoreExpander,
    TwSelect,
    TwTextarea,
    TwCardBlockFormActions,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock,
    TwIconWrapper,
    TwPopoverShort
  },
  mixins: [ViewPanelShowMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isEdit: false,
      formProcessing: false,
      loading: false,
      form: {
        checkStatus: null,
        description: null
      },
      serverError: null,
      info: null,
      ui: null,
      dictionary: null
    }
  },
  validations () {
    return {
      form: {
        checkStatus: {
          required
        },
        description: {
          ...(this.form.checkStatus && (this.form.checkStatus === 'adjustment' || this.form.checkStatus === 'rejected')) && { required }
        }
      }
    }
  },
  watch: {
    isEdit (newValue) {
      if (newValue) {
        this.form.checkStatus = this.info?.checkStatus?.value || null
        this.form.description = this.info?.description || null
      }
    }
  },
  mounted () {
    this.fetchStatusInfo()
  },
  methods: {
    showAccountingLink () {
      this.showAccountingNbResultCard({ id: this.id })
    },
    fetchStatusInfo () {
      this.loading = true
      API.showStatus(this.id)
        .then(({ data: result }) => {
          this.info = result?.data || null
          this.ui = result?.ui || null
          this.dictionary = result?.dictionary || null
        })
        .catch((error) => {
          console.error('handleSubmit', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit () {
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.serverError = null
        if (this.info?.companyPeriodId) {
          API.updateStatus(this.id, this.form)
            .then(() => {
              this.fetchStatusInfo()
              this.isEdit = false
            })
            .catch((error) => {
              this.serverError = error?.response?.data
            })
            .finally(() => {
              this.formProcessing = false
            })
        } else {
          API.storeStatus(this.id, this.form)
            .then(() => {
              this.fetchStatusInfo()
              this.isEdit = false
            })
            .catch((error) => {
              this.serverError = error?.response?.data
            })
            .finally(() => {
              this.formProcessing = false
            })
        }
      }
    },
    handleCancel () {
      this.isEdit = false
    }
  }
}
</script>
