<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    no-data-text="Без рассрочки"
  >
    <template #title>
      <div>
        Рассрочка
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        :disabled="formProcessing"
        :type="hasEdit ? 'delete':'edit'"
        @click.prevent="hasEdit = !hasEdit"
      >
        {{ hasEdit ? 'отмена':'изменить' }}
      </TwPanelContentBlockActionButton>
    </template>
    <div
      v-if="info && !hasEdit"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <TwPanelContentBlockFieldView>
        <template #title>
          Полностью выплачена
        </template>
        <div
          class="tw-flex tw-gap-[12px] tw-items-center tw-justify-between"
          :class="[info.finished ? 'tw-font-bold tw-text-an-flat-green':'']"
        >
          {{ info.finished ? 'Да':'Нет' }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView no-data-text="Нет примечания">
        <template #title>
          Примечание
        </template>
        <div v-if="info.comment">
          {{ info.comment }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Создал
        </template>
        <div v-if="info.creatorId">
          <ViewPanelLink
            :id="info.creatorId"
            label="Сотрудник"
            component="employee-show"
          >
            <EmployeeName
              :id="info.creatorId"
              is-avatar
            />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.updateUserId && info.creatorId !== info.updateUserId">
        <template #title>
          Изменил
        </template>
        <div>
          <ViewPanelLink
            :id="info.updateUserId"
            label="Сотрудник"
            component="employee-show"
          >
            <EmployeeName
              :id="info.updateUserId"
              is-avatar
            />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView no-data-text="Нет данных">
        <template #title>
          Дата изменения
        </template>
        <div>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt || info.createdAt) }}
        </div>
      </TwPanelContentBlockFieldView>
      <div
        v-if="!info.finished"
        class="tw-flex tw-w-full tw-mt-[15px]"
      >
        <TwPanelActionButton
          :disabled="formProcessing"
          class="tw-m-auto"
          color="white"
          @click.prevent="confirmFinishedDialog = true"
        >
          Рассрочка полностью выплачена
        </TwPanelActionButton>
      </div>
    </div>
    <div
      v-else-if="hasEdit"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <TwTextarea
        v-model="form.comment"
        rows="3"
        label="Примечание"
        placeholder="Примечание"
        maxlength="250"
        show-length
        resize="y"
        persistent-label
        :disabled="formProcessing"
        :show-label="true"
      />
      <div
        v-if="serverErrorMessage"
        class="tw-text-an-flat-red-main"
      >
        {{ serverErrorMessage }}
      </div>

      <TwCardSimpleFormSaveBlock
        :loading="formProcessing"
        :cancel-confirm="true"
        :is-sticky="false"
        :disable-success="formProcessing"
        :disable-cancel="formProcessing"
        :visible="true"
        :dense="true"
        @success="handleSubmit"
        @cancel="handleCancel"
      />
    </div>

    <!--  Подтверждение о полной выплате рассрочки -->
    <TwDialog
      v-if="!!info"
      v-model="confirmFinishedDialog"
      max-width="500px"
    >
      <template #header>
        <div>
          <div class="tw-text-h4 tw-font-semibold">
            Подтверждение
          </div>
        </div>
      </template>
      <template #body>
        <div>
          <div class="tw-font-medium tw-text-main-md">
            Подтвердить полную выплату рассрочки?
          </div>
          <div
            v-if="serverErrorMessage"
            class="tw-my-[15px]"
          >
            <div class="tw-p-[8px] tw-bg-an-flat-red-main tw-text-white">
              {{ serverErrorMessage }}
            </div>
          </div>
          <div class="tw-mt-[15px] tw-flex tw-gap-[10px]">
            <button
              :disabled="formProcessing"
              class="tw-grow tw-px-[15px] tw-py-[7px] tw-bg-white tw-rounded-sm tw-text-an-flat-green-btn-bg disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-green-btn-bg enabled:hover:tw-text-white"
              @click.prevent="submitFinished"
            >
              Да
            </button>
            <button
              :disabled="formProcessing"
              class="tw-w-[100px] tw-px-[15px] tw-py-[7px] tw-bg-an-flat-gray-4 tw-rounded-sm tw-text-white"
              @click.prevent="confirmFinishedDialog = false"
            >
              Нет
            </button>
          </div>
        </div>
      </template>
    </TwDialog>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Accounting/BuyNb/Result/PartialPayment'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  components: {
    TwDialog,
    TwPanelActionButton,
    ViewPanelLink,
    EmployeeName,
    TwTextarea,
    TwPanelContentBlockFieldView,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwCardSimpleFormSaveBlock
  },
  props: {
    resultId: {
      type: Number,
      required: true
    },
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      confirmFinishedDialog: false,
      hasEdit: false,
      formProcessing: false,
      form: {
        comment: this.info?.comment || null
      },
      serverErrorMessage: null
    }
  },
  methods: {
    refreshParent () {
      this.hasEdit = false
      this.confirmFinishedDialog = false
      this.$emit('refresh')
    },
    handleCancel () {
      this.hasEdit = false
    },
    submitFinished () {
      this.serverErrorMessage = null
      this.formProcessing = true
      API.finish(this.resultId).then(() => {
        this.refreshParent()
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.formProcessing = false
      })
    },
    handleSubmit () {
      this.serverErrorMessage = null
      this.formProcessing = true
      const request = this.info?.id ? API.update : API.store
      request(this.resultId, this.form).then(() => {
        this.refreshParent()
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.formProcessing = false
      })
    }
  }
}
</script>

<style scoped>

</style>
