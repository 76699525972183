<template>
  <div class="tw-flex tw-gap-[14px] tw-flex-col">
    <div class="tw-flex tw-w-full tw-justify-end">
      <TwPanelContentBlockActionButton
        :disabled="formProcessing || !allowToOfficeUpdate"
        type="edit"
        @click="editToOffice = !editToOffice"
      >
        {{ editToOffice ? 'отменить' : 'Изменить статус сдачи в Офис' }}
      </TwPanelContentBlockActionButton>
    </div>
    <TwPanelContentBlockFieldView>
      <template #title>
        Сдано в офис
      </template>
      <div>
        <div v-if="editToOffice">
          <TwSelect
            v-model="formToOffice.status"
            label="Статус сдачи в офис"
            dense
            placeholder="Выбрать"
            :options="dictionary.status"
            :disabled="formProcessing"
            :error-text="$v.formToOffice.status.$invalid ? 'Обязательное поле':''"
            :disable-error-border="true"
            :persistent-label="true"
          />
          <TwTextarea
            v-model="formToOffice.comment"
            class="tw-mt-[14px]"
            rows="5"
            dense
            maxlength="250"
            autocomplete="off"
            persistent-label
            placeholder="Примечание"
            :disabled="formProcessing"
            :error-text="$v.formToOffice.comment.$invalid ? 'Обязательное поле':''"
            :show-label="true"
          />

          <template v-if="formToOffice.status === 'lost'">
            <TwUploadFilesList
              label="Загрузить скан объяснительной"
              class="tw-mt-[14px]"
              no-data-text="Нет прикрепленнных файлов"
              :value="formToOffice.fileId ? [formToOffice.fileId] : []"
              file-type="contract_scan_explanatory_note"
              :multiple="false"
              :disabled="formProcessing"
              @input="formToOffice.fileId = $event[0] || null"
            />
            <div
              v-if="$v.formToOffice.fileId.$invalid"
              class="tw-ml-[10px] tw-my-[10px] tw-text-an-flat-red-main tw-text-main-sm2"
            >
              * Загрузите скан объяснительной
            </div>
          </template>
          <div class="tw-mt-[14px] tw-flex tw-w-full tw-justify-end">
            <TwPanelActionButton
              :disabled="formProcessing || $v.formToOffice.$invalid"
              color="green"
              @click.prevent="toOffice"
            >
              Сохранить
            </TwPanelActionButton>
          </div>
        </div>
        <div
          v-else-if="info.toOffice && info.toOffice.status"
          class="tw-flex tw-flex-col tw-gap-[5px]"
        >
          <strong>{{ info.toOffice.status.label }}</strong>
          <div
            v-if="info.toOffice.comment"
            class="tw-text-an-flat-red-main"
          >
            {{ info.toOffice.comment }}
          </div>
          <template v-if="info.toOffice.file">
            <div class="tw-mt-[6px] tw-text-an-flat-disabled-text">
              Объяснительная:
            </div>
            <TwSimpleImageViewById
              class="tw-w-[90px]"
              :value="info.toOffice.file.id ? [info.toOffice.file.id] : []"
              :is-public="true"
              :multiple="false"
            />
          </template>
          <ViewPanelLink
            :id="info.toOffice.creatorId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.toOffice.creatorId" />
          </ViewPanelLink>
        </div>
        <div
          v-else
          class="tw-text-an-flat-disabled-text"
        >
          Не установлен
        </div>
      </div>
    </TwPanelContentBlockFieldView>
    <div class="tw-flex tw-w-full tw-justify-end">
      <TwPanelContentBlockActionButton
        :disabled="formProcessing || current.toOffice !== 'accept' || !allowArchiveUpdate"
        type="edit"
        @click="editArchive = !editArchive"
      >
        {{ editArchive ? 'отменить' : 'Изменить статус сдачи в Архив' }}
      </TwPanelContentBlockActionButton>
    </div>
    <TwPanelContentBlockFieldView>
      <template #title>
        Архив
      </template>
      <div>
        <div v-if="editArchive">
          <TwSelect
            v-model="formArchive.status"
            label="Статус сдачи в архив"
            dense
            placeholder="Выбрать"
            :options="dictionary.status"
            :disabled="formProcessing"
            :error-text="$v.formArchive.status.$invalid ? 'Обязательное поле':''"
            :disable-error-border="true"
            :persistent-label="true"
          />
          <TwTextarea
            v-model="formArchive.comment"
            class="tw-mt-[14px]"
            rows="5"
            dense
            maxlength="250"
            autocomplete="off"
            persistent-label
            placeholder="Примечание"
            :disabled="formProcessing"
            :error-text="$v.formArchive.comment.$invalid ? 'Обязательное поле':''"
            :show-label="true"
          />

          <template v-if="formArchive.status === 'lost'">
            <TwUploadFilesList
              label="Загрузить скан объяснительной"
              class="tw-mt-[14px]"
              no-data-text="Нет прикрепленнных файлов"
              :value="formArchive.fileId ? [formArchive.fileId] : []"
              file-type="contract_scan_explanatory_note"
              :multiple="false"
              :disabled="formProcessing"
              @input="formArchive.fileId = $event[0] || null"
            />
            <div
              v-if="$v.formArchive.fileId.$invalid"
              class="tw-ml-[10px] tw-my-[10px] tw-text-an-flat-red-main tw-text-main-sm2"
            >
              * Загрузите скан объяснительной
            </div>
          </template>
          <div class="tw-mt-[14px] tw-flex tw-w-full tw-justify-end">
            <TwPanelActionButton
              :disabled="formProcessing || $v.formArchive.$invalid"
              color="green"
              @click.prevent="archive"
            >
              Сохранить
            </TwPanelActionButton>
          </div>
        </div>
        <div
          v-else-if="info.archive && info.archive.status"
          class="tw-flex tw-flex-col tw-gap-[5px]"
        >
          <strong>{{ info.archive.status.label }}</strong>
          <div
            v-if="info.archive.comment"
            class="tw-text-an-flat-red-main"
          >
            {{ info.archive.comment }}
          </div>
          <template v-if="info.archive.file">
            <div class="tw-mt-[6px] tw-text-an-flat-disabled-text">
              Объяснительная:
            </div>
            <TwSimpleImageViewById
              class="tw-w-[90px]"
              :value="info.archive.file.id ? [info.archive.file.id] : []"
              :is-public="true"
              :multiple="false"
            />
          </template>
          <ViewPanelLink
            :id="info.archive.creatorId"
            label="Пользователь"
            component="employee-show"
          >
            <EmployeeName :id="info.archive.creatorId" />
          </ViewPanelLink>
        </div>
        <span
          v-else-if="current.toOffice !== 'accept'"
          class="tw-ml-1 tw-text-an-flat-orange-text"
        >* Для перемещения в архив сначала нужно сдать в Офис</span>
        <div v-else>
          Не установлен
        </div>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/Contract/index'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink'
import EmployeeName from '@/components/other/employee/EmployeeName'
import { mapGetters } from 'vuex'
import dictPermissionRules from '@/Permissions/dictRules'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import { required } from 'vuelidate/lib/validators'
import TwSimpleImageViewById from '@/components/tw-ui/ui-kit/files/TwSimpleImageViewById.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'ContractDealActionsBlock',
  components: {
    TwPanelActionButton,
    TwSelect,
    TwTextarea,
    TwPanelContentBlockActionButton,
    TwPanelContentBlockFieldView,
    TwSimpleImageViewById,
    TwUploadFilesList,
    EmployeeName,
    ViewPanelLink
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      formProcessing: false,
      editToOffice: false,
      editArchive: false,
      formToOffice: {
        status: null,
        comment: '',
        fileId: null
      },
      formArchive: {
        status: null,
        comment: '',
        fileId: null
      }
    }
  },
  validations () {
    return {
      formToOffice: {
        status: {
          required
        },
        comment: {
          ...(this.formToOffice.status === 'lost') && {
            required
          }
        },
        fileId: {
          ...(this.formToOffice.status === 'lost') && {
            required
          }
        }
      },
      formArchive: {
        status: {
          required
        },
        comment: {
          ...(this.formArchive.status === 'lost') && {
            required
          }
        },
        fileId: {
          ...(this.formArchive.status === 'lost') && {
            required
          }
        }
      }
    }
  },
  methods: {
    toOffice () {
      this.formProcessing = true
      API.toOffice(this.info.id, this.formToOffice)
        .then(({ data }) => {
          console.warn(this.$options.name, ' toOffice ', data)
          this.$emit('refresh')
          this.editToOffice = false
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    archive () {
      this.formProcessing = true
      API.archive(this.info.id, this.formArchive)
        .then(({ data }) => {
          console.warn(this.$options.name, ' archive ', data)
          this.$emit('refresh')
          this.editArchive = false
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.formProcessing = false
        })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin',
      allowNamespacesDict: 'profile/allowNamespacesDict'
    }),
    current () {
      return {
        toOffice: this.info?.toOffice?.status?.value || null,
        archive: this.info?.archive?.status?.value || null
      }
    },
    allowToOfficeUpdate () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsContractContractDealTypeToOfficeRule]
    },
    allowArchiveUpdate () {
      return this.isAdmin || !!this.allowNamespacesDict[dictPermissionRules.AppServicePermissionRulesCRMDealsContractContractDealTypeArchiveRule]
    }
  },
  mounted () {
    this.formToOffice.status = this.current.toOffice || null
    this.formToOffice.comment = this.info?.toOffice?.comment || ''
    this.formToOffice.fileId = this.info?.toOffice?.file?.id || null
    this.formArchive.status = this.current.archive || null
    this.formArchive.comment = this.info?.archive?.comment || ''
    this.formArchive.fileId = this.info?.archive?.file?.id || null
  }
}
</script>
