import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: `${RouteKeys.siteArticles}`,
  name: RouteKeys.siteArticles,
  redirect: { name: `${RouteKeys.siteArticles}-list` },
  meta: { name: 'Статьи', icon: 'admin-article', isAdmin: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesSITEContentSiteArticlesRule },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.siteArticles}-list`,
      meta: { name: 'Список статей', icon: 'admin-article', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Article/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.siteArticles}-edit`,
      meta: { name: 'Редактирование статьи', icon: 'admin-article', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Article/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.siteArticles}-create`,
      meta: { name: 'Добавление статьи', icon: 'admin-article', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Article/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
