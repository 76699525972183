import { render, staticRenderFns } from "./innType.vue?vue&type=template&id=f4ddb6aa"
import script from "./innType.vue?vue&type=script&lang=js"
export * from "./innType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports