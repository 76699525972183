import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys.siteServiceItem}`,
  name: RouteKeys.siteServiceItem,
  redirect: { name: `${RouteKeys.siteServiceItem}-list` },
  meta: { name: 'Список услуг', icon: 'admin-service-list', isAdmin: false },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.siteServiceItem}-list`,
      meta: { name: 'Список услуг', icon: 'admin-service-list', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Service/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.siteServiceItem}-edit`,
      meta: { name: 'Редактирование услуги', icon: 'admin-service-list', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Service/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.siteServiceItem}-create`,
      meta: { name: 'Добавление услуги', icon: 'admin-service-list', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Service/Service/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
