<template>
  <div class="tw-flex tw-gap-[10px]">
    <TwSelect
      v-model="selectedEmail"
      class="tw-min-w-[250px]"
      label="Email"
      placeholder="Email"
      dense
      :multi="false"
      :options="userEmails"
      :disabled="disabled"
      :disable-error-border="true"
    />
    <TwSelect
      v-model="selectedFolderId"
      class="tw-min-w-[250px]"
      label="Папка"
      placeholder="Папка"
      dense
      :multi="false"
      :options="userEmailFoldersDict[selectedEmail]"
      :disabled="!selectedEmail || disabled"
      :disable-error-border="true"
    />
  </div>
</template>

<script>
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EmailBoxAndFolderSelector',
  components: { TwSelect },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    valueFolder: {
      default: ''
    }
  },
  data () {
    return {
      email: ''
    }
  },
  computed: {
    ...mapGetters({
      UserEmailConfigs: 'profile/UserEmailConfigs'
    }),
    selectedEmail: {
      get () {
        // console.warn('get', this.email)
        if (!this.email && this.userEmails.length) {
          return this.userEmails?.[0]?.value
        }
        return this.email
      },
      set (val) {
        // console.warn('set', val)
        this.email = val
        this.$emit('inputFolder', this.userEmailFoldersDict[this.selectedEmail]?.[0]?.value || null)
        this.$emit('changeFolder')
      }
    },
    selectedFolderId: {
      get () {
        // console.warn('get', this.value)
        if (!this.valueFolder && Array.isArray(this.userEmailFoldersDict[this.selectedEmail]) && this.userEmailFoldersDict[this.selectedEmail].length) {
          this.$emit('inputFolder', this.userEmailFoldersDict[this.selectedEmail][0].value)
          this.$emit('changeFolder')
        }
        return this.valueFolder
      },
      set (val) {
        // console.warn('set', val)
        this.$emit('inputFolder', val)
        this.$emit('changeFolder')
      }
    },
    userEmailFoldersDict () {
      const result = {}
      for (const emailConfig of this.UserEmailConfigs) {
        result[emailConfig.email] = []
        for (const folder of emailConfig.emailFolders) {
          result[emailConfig.email].push({
            label: folder.type?.label || folder.name,
            value: folder.id
          })
        }
      }
      return result
    },
    userEmails () {
      const result = []
      for (const item of this.UserEmailConfigs) {
        result.push({
          label: item.email,
          value: item.email
        })
      }
      return result
    }
  }
}
</script>
