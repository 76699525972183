<template>
  <div
    class="px-2 py-1 d-flex lighten-2 justify-space-between"
    :class="[confidenceQuality ? 'yellow':'red']"
  >
    <div
      v-if="visionValue"
      title="Распознано на изображении"
    >
      <small>
        {{ visionValue }}
      </small>
    </div>
    <div class="text-center">
      <span :title="`Процент качества распознания: ${confidenceQuality ? 'Хороший':'Низкое качество - проверьте ввод'}`">
        {{ confidencePercent }}%
      </span>
      <TwPanelActionButton
        title="Применить распознанные данные"
        color="blue"
        @click.stop="$emit('apply')"
      >
        Применить
      </TwPanelActionButton>
    </div>
  </div>
</template>

<script>
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'DocumentVisionFieldWarning',
  components: { TwPanelActionButton },
  props: {
    visionValue: {
      type: String,
      default: ''
    },
    confidence: {
      type: Number,
      default: 0
    }
  },
  computed: {
    confidencePercent () {
      return (this.confidence && Math.floor(this.confidence * 100)) || 0
    },
    confidenceQuality () {
      return this.confidencePercent > 85 // good\bad
    }
  }
}
</script>

<style scoped>

</style>
