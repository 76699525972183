<template>
  <div
    v-if="info"
    class="tw-flex tw-items-center"
  >
    <PersonNameLetters
      class="tw-mr-2"
      :width="40"
      :height="40"
      :text="info.from.name || info.from.email"
    />
    <div :style="{fontSize: fontSize}">
      <div class="tw-flex tw-flex-wrap">
        <span
          v-if="info.from.name"
          class="tw-font-medium tw-mr-2"
        >{{ info.from.name }}</span>
        <span class="tw-font-normal">{{ info.from.email }}</span>
      </div>
      <div class="tw-flex">
        <div class="tw-mr-2 tw-font-bold">
          Кому:
        </div>
        <div
          v-for="(contact, index) in info.to"
          :key="index + '-' + contact.id"
          class="tw-flex tw-items-center"
        >
          <PersonNameLetters
            class="tw-mr-1"
            :width="20"
            :height="20"
            :text="contact.name || contact.email"
          />
          <span
            class="tw-font-normal"
            :title="contact.name"
          >{{ contact.email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonNameLetters from '@/components/other/person/PersonNameLetters'
export default {
  name: 'EmailFromToShow',
  components: { PersonNameLetters },
  props: {
    fontSize: {
      default: 'small'
    },
    emailFolder: {},
    emailConfigContact: {},
    contacts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    info () {
      return {
        from: this.isOut ? {
          name: this.emailConfigContact?.name || 'От меня',
          email: this.emailConfigContact?.email || 'Мой Email'
        } : this.contacts[0],
        to: this.isOut ? this.contacts : [
          {
            name: this.emailConfigContact?.name || 'Мне',
            email: this.emailConfigContact?.email || 'Мой Email'
          }
        ]
      }
    },
    isOut () {
      const type = this.emailFolder?.type?.value
      return type === 'sent' || type === 'draft'
    }
  }
}
</script>
