
<template>
  <div>
    <TwPanelContentBlock
      class="tw-mb-[6px]"
    >
      <template #title>
        <div>
          Действия
        </div>
      </template>
      <div class="tw-flex tw-gap-[10px] tw-flex-wrap">
        <TwPanelActionButton
          class="tw-w-full"
          color="gray"
          :disabled="actionProcess || info.formed"
          @click.prevent="onDialog(actActions.ready)"
        >
          <TwIconWrapper
            v-if="info.formed"
            file-name="checked-1"
          />
          Завершить редактирование
        </TwPanelActionButton>
        <TwPanelActionButton
          class="tw-w-full"
          color="gray"
          :disabled="actionProcess || info.received"
          @click.prevent="onDialog(actActions.received)"
        >
          <TwIconWrapper
            v-if="info.received"
            file-name="checked-1"
          />
          Подтвердить получение акта
        </TwPanelActionButton>
        <TwPanelActionButton
          class="tw-w-full"
          color="gray"
          :disabled="actionProcess || info.payed"
          @click.prevent="onDialog(actActions.payed)"
        >
          <TwIconWrapper
            v-if="info.payed"
            file-name="checked-1"
          />
          Подтвердить оплату
        </TwPanelActionButton>
      </div>
    </TwPanelContentBlock>

    <TwDialog
      v-model="confirmDialog"
      max-width="500px"
      @close="onCalcel"
    >
      <template #header>
        <div>
          <div class="tw-text-h4 tw-font-semibold">
            Подтверждение
          </div>
        </div>
      </template>
      <template #body>
        <div>
          <div class="tw-mb-[20px]">
            Данное действие требует подтверждения!
          </div>
          <div
            v-if="currentAction.key === 'received'"
            class="tw-mb-[20px]"
          >
            <div class="tw-font-bold tw-mb-[6px]">
              Устное согласование:
            </div>
            <TwInputSwitch
              v-model="currentAction.form.verballyAgreed"
              :disabled="actionProcess"
            >
              <template #label>
                <span class="tw-relative tw-z-10 tw-font-manrope tw-font-semibold tw-text-[14px]">
                  {{ currentAction.form.verballyAgreed ? 'Да - отсутствуют подтверждающие документы':'Нет' }}
                </span>
              </template>
            </TwInputSwitch>
          </div>
          <div class="tw-my-[5px] tw-flex tw-gap-[20px] tw-w-full tw-justify-end">
            <TwPanelActionButton
              color="gray"
              size="md"
              @click.stop="onCalcel"
            >
              Отмена
            </TwPanelActionButton>
            <TwPanelActionButton
              :disabled="actionProcess"
              color="green"
              size="md"
              @click.prevent="onSubmitAction"
            >
              Подтверждаю
            </TwPanelActionButton>
          </div>
        </div>
      </template>
    </TwDialog>
  </div>
</template>

<script>
import AccountingBuyNbActActionAPI from '@/Api/Accounting/BuyNb/Act/Action'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwDialog from '@/components/tw-ui/modal/TwDialog.vue'
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'
import TwInputSwitch from '@/components/tw-ui/ui-kit/input/TwInputSwitch.vue'

export default {
  components: {
    TwPanelContentBlock,
    TwPanelActionButton,
    TwDialog,
    TwIconWrapper,
    TwInputSwitch
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      confirmDialog: false,
      actionProcess: false,
      actActions: {
        ready: {
          key: 'ready',
          method: AccountingBuyNbActActionAPI.ready
        },
        received: {
          key: 'received',
          method: AccountingBuyNbActActionAPI.received,
          form: {
            verballyAgreed: false
          }
        },
        payed: {
          key: 'payed',
          method: AccountingBuyNbActActionAPI.payed
        }
      },
      currentAction: null,
      serverErrorMessage: null
    }
  },
  methods: {
    onCalcel () {
      this.confirmDialog = false
      this.currentAction = null
    },
    onDialog (action) {
      this.confirmDialog = true
      this.currentAction = { ...action }
    },
    onSubmitAction () {
      this.serverErrorMessage = null
      this.actionProcess = true
      this.actActions[this.currentAction.key].method(this.info.id, this.currentAction?.form || {}).then(() => {
        this.refreshParent()
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.actionProcess = false
      })
    },
    refreshParent () {
      this.confirmDialog = false
      this.currentAction = null
      this.$emit('refresh')
    },
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
