<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Пользователь
      </template>
      <div v-if="info.userId">
        <ViewPanelLink
          :id="info.userId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.userId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Выплата
      </template>
      <div v-if="info.payStatus">
        {{ info.payStatus && info.payStatus.value ? info.payStatus.label : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.payStatus && info.payStatus.value === 'payed'">
      <template #title>
        Дата выплаты
      </template>
      <div v-if="info.payDate">
        {{ info.payDate }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.payStatus && info.payStatus.value === 'payed'">
      <template #title>
        Подтвердил выплату
      </template>
      <div v-if="info.paySetUserId">
        <ViewPanelLink
          :id="info.paySetUserId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.paySetUserId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Откуда\Источник
      </template>
      <div v-if="info.entity">
        {{ info.entity && info.entity?.label ? info.entity.label : info.entity || '' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info && info.entity && info.entity?.value === 'buy_nb_result'">
      <template #title>
        Результат
      </template>
      <div v-if="info.entityId">
        <ViewPanelLink
          :id="info.entityId"
          :no-link-style="false"
          component="accounting-nb-result-show"
        >
          #{{ info.entityId }}
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Начислено
      </template>
      <div v-if="info.commission">
        {{ (info.commission).toLocaleString('ru') }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Сумма выплаты
      </template>
      <div v-if="info.forPayment">
        {{ (info.forPayment).toLocaleString('ru') }} руб.
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Процент
      </template>
      <div v-if="info.percent">
        {{ info.percent }} %
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Назначение
      </template>
      <div v-if="info.name">
        {{ info.name }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Комментарий
      </template>
      <div v-if="info.comment">
        <ReadMoreExpander
          :text="info.comment"
          :is-nl-trigger="false"
          :less-length="150"
        />
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Создал запись
      </template>
      <div v-if="info.creatorId">
        <ViewPanelLink
          :id="info.creatorId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.creatorId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Дата создания
      </template>
      <div v-if="info.createdAt">
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Изменил запись
      </template>
      <div v-if="info.updateUserId">
        <ViewPanelLink
          :id="info.updateUserId"
          label="Пользователь"
          component="employee-show"
        >
          <EmployeeName :id="info.updateUserId" />
        </ViewPanelLink>
      </div>
    </TwPanelContentBlockFieldView>

    <TwPanelContentBlockFieldView>
      <template #title>
        Дата изменения
      </template>
      <div v-if="info.updatedAt">
        {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt) }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import ReadMoreExpander from '@/components/other/text/ReadMoreExpander.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'UserCalcMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink, EmployeeName, ReadMoreExpander },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
