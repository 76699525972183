<template>
  <div>
    <div
      v-if="loading"
      class="tw-flex tw-justify-center tw-w-full tw-p-[35px]"
    >
      <TwCircleLoader />
    </div>
    <TwPanelContentBlock
      v-if="ui.hasFileData"
      :content-classes="''"
    >
      <template #title>
        Загруженные данные
      </template>
      <template #action>
        <button
          :disabled="loading"
          class="tw-underline hover:tw-no-underline tw-text-an-flat-blue-btn enabled:tw-text-an-flat-dark-blue-link-active enabled:hover:tw-text-an-flat-dark-blue-link-active disabled:tw-text-an-flat-disabled-text"
          @click.prevent="fetchFileData"
        >
          Обновить
        </button>
      </template>
      <template #default>
        <div class="">
          <div>
            <table class="tw-w-full tw-bg-white tw-rounded-[10px] tw-border-none">
              <thead>
                <tr class="tw-border-collapse">
                  <th class="tw-border-r tw-border-b tw-px-[20px] tw-py-[15px] tw-text-start">
                    Жилой комплекс
                  </th>
                  <th class="tw-border-r tw-border-b tw-px-[20px] tw-py-[15px] tw-text-start">
                    Строение в ЖК
                  </th>
                  <th
                    v-if="ui.uploadFileDataToNbBase"
                    class="tw-border-r tw-border-b tw-px-[20px] tw-py-[15px] tw-min-w-[450px] tw-text-start"
                  >
                    Строение в нашей системе
                  </th>
                  <th class="tw-border-b tw-px-[20px] tw-py-[15px] tw-text-center">
                    Кол-во загруженных объектов
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="(item, index) in uploadForm"
                :key="index"
                :class="[fileData[index].visible ? 'tw-bg-an-flat-bg-main/40' : '']"
              >
                <tr
                  valign="top"
                  :data-index="index"
                >
                  <td class="tw-border-r tw-border-t tw-p-[20px]">
                    <div class="tw-flex tw-gap-[8px]">
                      <div title="Название ЖК в файле застройщика">
                        <strong
                          v-if="item.complex"
                          class="tw-text-blue-700"
                        >{{ item.complex }}</strong>
                        <span
                          v-else
                          class="tw-text-an-flat-disabled-text"
                        >Не указан в файле</span>
                      </div>
                      <div
                        v-if="fileData[index].comparison"
                        title="Сопоставление"
                      >
                        <svg
                          width="20"
                          height="15"
                          viewBox="0 0 56 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M39.5051 0.576721L55.2742 13.1077C56.2419 13.8766 56.2419 15.1234 55.2742 15.8923L39.5051 28.4233C38.5375 29.1922 36.9686 29.1922 36.0009 28.4233C35.0332 27.6543 35.0332 26.4076 36.0009 25.6386L47.54 16.469H0V12.531H47.54L36.0009 3.36138C35.0332 2.59242 35.0332 1.34568 36.0009 0.576721C36.9686 -0.19224 38.5375 -0.19224 39.5051 0.576721Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div
                        v-if="fileData[index].comparison"
                        title="Название в CRM"
                      >
                        <strong
                          v-if="fileData[index].comparison && fileData[index].comparison.complex"
                          class="tw-text-green-700"
                        >
                          {{ fileData[index].comparison.complex }}
                        </strong>
                        <span
                          v-else
                          class="tw-text-an-flat-disabled-text"
                        >Не указан</span>
                      </div>
                    </div>
                  </td>
                  <td
                    valign="top"
                    class="tw-border-r tw-border-t tw-p-[20px]"
                  >
                    <div class="tw-flex tw-gap-[8px]">
                      <div title="Номер дома в файле застройщика">
                        <strong
                          v-if="item.building"
                          class="tw-text-blue-700"
                        >Дом {{ item.building }}</strong>
                        <span
                          v-else
                          class="tw-text-an-flat-disabled-text"
                        >Не указан в файле</span>
                      </div>
                      <div
                        v-if="fileData[index].comparison"
                        title="Сопоставление"
                      >
                        <svg
                          width="20"
                          height="15"
                          viewBox="0 0 56 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M39.5051 0.576721L55.2742 13.1077C56.2419 13.8766 56.2419 15.1234 55.2742 15.8923L39.5051 28.4233C38.5375 29.1922 36.9686 29.1922 36.0009 28.4233C35.0332 27.6543 35.0332 26.4076 36.0009 25.6386L47.54 16.469H0V12.531H47.54L36.0009 3.36138C35.0332 2.59242 35.0332 1.34568 36.0009 0.576721C36.9686 -0.19224 38.5375 -0.19224 39.5051 0.576721Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div
                        v-if="fileData[index].comparison"
                        title="Название в CRM"
                      >
                        <strong
                          v-if="fileData[index].comparison && fileData[index].comparison.buildingId"
                          class=""
                        >
                          <span :id="fileData[index].comparison.buildingId">
                            <span class="tw-text-green-700">
                              {{ fileData[index].comparison.buildingId }}
                            </span>
                          </span>
                        </strong>
                        <span
                          v-else
                          class="tw-text-an-flat-disabled-text"
                        >Не указано</span>
                      </div>
                    </div>
                  </td>
                  <td
                    v-if="ui.uploadFileDataToNbBase"
                    class="tw-border-r tw-border-t tw-p-[20px]"
                  >
                    <div v-if="!uploadForm[index].complexId">
                      <TwCheckBox
                        v-model="uploadForm[index].disableLoad"
                        label="Не подгружать"
                        :dense="true"
                        :disabled="false"
                      />
                    </div>
                    <div v-if="!uploadForm[index].disableLoad">
                      <div class="tw-min-w-[250px]">
                        <TwSelectRelations
                          v-model="uploadForm[index].complexId"
                          :disabled="false"
                          :dense="true"
                          :error-text="uploadForm[index].complexId ? '' : '* Выберите ЖК'"
                          label="Выбор ЖК"
                          list-label="Выбрать ЖК"
                          relation-name="complex"
                          :default-filters="complexFilters"
                        />
                      </div>
                      <CustomBuildingSelector
                        v-model="uploadForm[index].buildingId"
                        class="tw-mt-[12px] tw-min-w-[250px]"
                        :disabled="!item.complexId"
                        :dense="true"
                        :error-text="uploadForm[index].complexId ? uploadForm[index].buildingId ? '' : '* Выберите Дом' : ''"
                        :complex-id="item.complexId"
                      />
                    </div>
                  </td>
                  <td
                    valign="bottom"
                    align="left"
                    class="tw-text-center tw-border-t"
                  >
                    <div class="tw-w-full tw-text-center tw-pt-[20px] tw-pb-[64px] tw-text-[20px]">
                      <strong>
                        {{ (Array.isArray(fileData[index].objects) && fileData[index].objects.length) || 0 }}
                      </strong>
                    </div>
                    <button
                      class="tw-flex tw-items-center tw-justify-between tw-border-solid tw-border-t tw-border-an-flat-border-solid tw-w-full tw-text-left tw-p-[10px] tw-text-an-flat-disabled-input-text tw-uppercase tw-font-bold"
                      :class="[fileData[index].visible ? 'tw-text-an-flat-blue-btn':'tw-text-an-flat-gray-4']"
                      @click.prevent="fileData[index].visible = !fileData[index].visible"
                    >
                      <div>{{ fileData[index].visible ? 'Скрыть' : 'Показать' }} список объектов</div>
                      <div
                        class="tw-transition-all"
                        :class="{ 'tw-rotate-180' : fileData[index].visible }"
                      >
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 3.92856L6 6.42856L9 3.92856"
                            stroke="#343942"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </td>
                </tr>
                <template v-if="(Array.isArray(fileData[index].objects) && fileData[index].objects.length) && fileData[index].visible">
                  <tr>
                    <td class="tw-border-r tw-p-2" />
                    <td class="tw-border-r tw-p-2" />
                    <td
                      class="tw-border-t"
                      colspan="99"
                    >
                      <div class="tw-grid tw-grid-cols-5 tw-text-left tw-text-an-flat-text-gray tw-font-bold">
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r tw-border-b tw-border-an-flat-border-solid tw-border-solid">
                          № квартиры
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r tw-border-b tw-border-an-flat-border-solid tw-border-solid">
                          Статус
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r tw-border-b tw-border-an-flat-border-solid tw-border-solid">
                          Комнатность
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r tw-border-b tw-border-an-flat-border-solid tw-border-solid">
                          Площадь
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-b tw-border-an-flat-border-solid tw-border-solid">
                          Цена
                        </div>
                      </div>
                      <div
                        v-for="(object, oIndex) in fileData[index].objects"
                        :key="oIndex"
                        class="tw-grid tw-grid-cols-5 tw-text-an-flat-black-table tw-font-semibold"
                      >
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r">
                          {{ object.num || '~' }}
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r">
                          {{ object.status && object.status.label }}
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r">
                          {{ object.roomCount || '~' }}
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px] tw-border-r">
                          {{ object.area || '~' }} м²
                        </div>
                        <div class="tw-px-[12px] tw-py-[10px]">
                          {{ object.price ? (object.price || 0).toLocaleString('ru') : '~' }} ₽
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="tw-mt-2 tw-flex tw-justify-end">
              <div
                v-if="ui.uploadFileDataToNbBase"
                class="tw-flex tw-gap-[20px] tw-items-center"
              >
                <div>
                  <TwCheckBox
                    v-model="checkTable"
                    label="Мной проверены данные"
                  />
                </div>
                <button
                  :disabled="loading || uploadProcess || !checkTable"
                  class="tw-text-main-xs tw-bg-an-flat-dark-blue-link tw-p-4 tw-rounded-sm tw-text-white disabled:tw-bg-an-flat-gray-4 enabled:hover:tw-bg-an-flat-dark-blue-link-active"
                  @click.prevent="uploadFileData"
                >
                  {{ uploadProcess ? 'Идет сохранение... Подождите!':'Сохранить объекты из файла в CRM' }}
                </button>
              </div>
              <div
                v-else-if="info.processStatus && info.processStatus.value === 'success'"
                class="tw-p-2 tw-text-an-flat-green-btn-bg tw-font-semibold"
              >
                Файл загружен в CRM
              </div>
            </div>
          </div>
        </div>
      </template>
    </TwPanelContentBlock>
  </div>
</template>

<script>
import { statusColors } from '@/views/BuilderImporter/ImportDocumentFile/tableOptions'
import API from '@/Api/BuilderImporter/ImportDocument/File'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import CustomBuildingSelector from '@/views/BuilderImporter/ImportDocumentFile/CustomBuildingSelector.vue'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'

export default {
  name: 'ImportDocumentUploadedBlock',
  components: {
    TwCheckBox,
    CustomBuildingSelector,
    TwSelectRelations,
    TwPanelContentBlock,
    TwCircleLoader
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    ui: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      statusColors,
      API,
      fileData: null,
      uploadForm: [],
      uploadProcess: false,
      checkTable: false
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.info.companyId ? [this.info.companyId] : []
      }
    },
    fileId () {
      return this.data?.fileId ? [this.data?.fileId] : null
    }
  },
  mounted () {
    this.fetchFileData()
  },
  methods: {
    fetchFileData () {
      this.loading = true
      this.API.getDataById(this.info.id).then((result) => {
        this.fileData = result?.data?.data || null
        if (Array.isArray(this.fileData) && this.fileData.length) {
          this.fileData = this.fileData.map((item) => {
            return {
              ...item,
              visible: false
            }
          })
          this.uploadForm = this.fileData.map((item) => {
            return {
              complex: item.complex,
              building: item.building,
              buildingId: null,
              disableLoad: item?.disableLoad || false
            }
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    validateUpload () {
      const badIndex = this.uploadForm.findIndex(i => !i.buildingId && !i.disableLoad)
      if (badIndex !== -1) {
        this.$el.querySelector(`[data-index="${badIndex}"]`).scrollIntoView({ block: 'center', behavior: 'smooth' })
        return false
      }
      return true
    },
    uploadFileData () {
      if (this.validateUpload() && confirm('Подтвердите отправку')) {
        this.uploadProcess = true
        this.API.uploadDataById(this.info.id, this.uploadForm || [])
          .then((result) => {
            console.warn('uploadDataById', result)
          })
          .finally(() => {
            this.uploadProcess = false
          })
      }
    },
    postFetch (data) {
      console.warn('postFetch', data, this.ui)
      if (this.ui?.hasFileData) {
        this.fetchFileData()
      }
    }
  }
}
</script>
