<template>
  <div>
    <div
      v-if="formProcessing"
      class="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]"
    >
      <TwCircleLoader class="tw-m-auto" />
    </div>
    <div
      v-else-if="defaultEmailConfig"
    >
      <div>
        <div style="display: none">
          {{ isEdited }}
        </div>
        <div>
          <strong>От кого:</strong>
          <span
            class="tw-mx-2 tw-text-an-flat-blue-btn"
            v-text="`${currentUserName} <${defaultEmailConfig.email}>`"
          />
        </div>
        <hr class="tw-my-[16px]">
        <div>
          <div class="tw-my-[16px]">
            <strong>
              Кому:
            </strong>
            <div class="tw-mt-2">
              <EmailsContactsAsyncSelect
                v-model="form.contacts"
                placeholder="Выберите получателей"
                chip-color=""
                :rules="requireArrayRules"
                multiple
              />
            </div>
          </div>
          <div class="tw-my-[16px]">
            <div>
              <TwInputText
                v-model="form.theme"
                type="text"
                autocomplete="off"
                :disabled="formProcessing"
                :show-label="true"
                :dense="true"
                :persistent-label="true"
                label="Тема"
                placeholder="Введите тему сообщения"
                :error-text="$v.form.theme.$invalid ? 'Заполните поле':''"
              />
            </div>
          </div>
        </div>
        <div class="tw-my-[16px]">
          <QuillEmailEditor
            v-model="form.body"
            :rules="requireRules"
            min-height="100px"
            max-height="500px"
          />
          <div
            v-if="preset.signature"
            style="border: 1px solid #cccccc; border-top: none"
            v-html="preset.signature"
          />
        </div>
        <template v-if="forDeal">
          <hr class="tw-my-[16px]">
          <div>
            Заявка:
            <TwBadgeLabel
              size="sm"
              rounded="full"
              color="grey"
              class="tw-ml-2"
            >
              {{ forDeal.type && forDeal.type.label }} #{{ forDeal.id }}
            </TwBadgeLabel>
          </div>
          <hr class="tw-my-[16px]">
        </template>
        <div>
          <div class="tw-my-3 tw-flex tw-items-center">
            <strong>Прикреплённые файлы:</strong>
            <div class="tw-flex-1" />
            <PersonSelectDocumentFiles
              v-if="customPersonIds.length"
              :value="form.crmFileId"
              :target-persons="customPersonIds"
              @input="onChangePersonFileIds"
            />
          </div>
          <div
            v-if="customPersonIds.length"
            class="tw-mb-[16px]"
          >
            <TwFilesListViewByIds
              :file-ids="form.crmFileId || []"
              :is-drop-down="false"
              view-type="list"
              no-data-text="Файлы клиентов не прикреплены"
            >
              <template #other-actions="{ fileId }">
                <button
                  class="tw-p-[8px] tw-bg-an-flat-bg-main tw-rounded-sm enabled:hover:tw-bg-an-flat-red-main  enabled:hover:tw-text-white"
                  @click.stop="removeCrmFile(fileId)"
                >
                  <TwIconWrapper
                    file-name="remove-1"
                    :size="12"
                  />
                </button>
              </template>
            </TwFilesListViewByIds>
          </div>
          <TwUploadFilesList
            v-model="form.filesId"
            :api="emailFileAPI"
            label="Прикрепленные файлы"
            file-type="email_attachment"
            :no-data-text="false"
            :has-inline="true"
            :multiple="true"
          />
        </div>
      </div>
      <div style="position: sticky;bottom: 0;">
        <hr class="tw-my-[16px]">
        <div class="tw-flex tw-items-center tw-gap-[10px]">
          <TwPanelActionButton
            :disabled="sendingProcess"
            color="blue"
            size="md"
            @click="handleSubmit('sent')"
          >
            Сохранить и отправить
          </TwPanelActionButton>
          <TwPanelActionButton
            :disabled="sendingProcess"
            color="gray"
            size="md"
            @click="handleSubmit('draft')"
          >
            Сохранить черновик
          </TwPanelActionButton>
          <div class="tw-flex-1" />
          <TwPanelActionButton
            :disabled="sendingProcess"
            color="white"
            size="md"
            @click="$emit('cancel')"
          >
            Отменить
          </TwPanelActionButton>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <strong>Email не настроен для отправки писем!</strong>
      </div>
      <hr class="tw-my-[16px]">
      <div class="tw-text-center">
        Добавьте почтовый ящик в настройках профиля!
        <TwPanelActionButton
          color="blue"
          size="md"
          @click.prevent="showProfile"
        >
          Открыть профиль
        </TwPanelActionButton>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import profileAPI from '@/Api/Auth/Profile/index'
import emailAPI from '@/Api/Email/Mail'
import emailFileAPI from '@/Api/Email/File'
import QuillEmailEditor from '@/components/text-editor/QuillEmailEditor'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import EmailsContactsAsyncSelect from '@/components/select/email/EmailsContactsAsyncSelect'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import PersonSelectDocumentFiles from '@/components/select/person/PersonSelectDocumentFiles'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwFilesListViewByIds from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'EmailMainForm',
  components: {
    TwPanelActionButton,
    TwInputText,
    TwCircleLoader,
    TwIconWrapper,
    TwUploadFilesList,
    TwFilesListViewByIds,
    PersonSelectDocumentFiles,
    EmailsContactsAsyncSelect,
    QuillEmailEditor
  },
  mixins: [FormCreateEditMixin, ViewPanelShowMixin],
  props: {
    // инфа о прикрепляемой сделке
    forDeal: {
      default: null
    },
    toPerson: {
      default: null
    }
  },
  data () {
    return {
      emailFileAPI,
      API: emailAPI,
      form: {
        folderId: '',
        theme: '',
        body: '',
        contacts: [],
        filesId: [],
        crmFileId: []
      },
      sendingProcess: false
    }
  },
  validations () {
    return {
      form: {
        theme: {
          required
        },
        contacts: {
          required
        }
      }
    }
  },
  methods: {
    removeCrmFile (fileId) {
      console.warn(fileId, this.form.crmFileId.findIndex(id => id === fileId))
      if (fileId) {
        this.form.crmFileId.splice(this.form.crmFileId.findIndex(id => id === fileId), 1)
      }
    },
    onChangePersonFileIds (data) {
      console.warn('onChangePersonFileIds:', data)
      // this.form.filesId = data || []
      this.$set(this.form, 'crmFileId', data || [])
    },
    handleSubmit (type) {
      if (this.sendingProcess) {
        return
      }
      this.$v.$reset()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sendingProcess = true
        this.form.folderId = type === 'draft' ? this.defaultDraftFolder?.id : this.defaultOutFolder?.id
        if (this.formType === 'create') {
          console.warn(this.$options.name, ' CREATE ', this.form)
          this.API.store(this.form).then(({ data }) => {
            console.warn(this.$options.name, ' CREATE+ ', data)
            this.$emit('create', data.data)
            this.$store.commit('notify/ADD', {
              title: type === 'draft' ? 'Черновик создан' : 'Email отправлен',
              type: 'success',
              timeout: 3000,
              visible: true
            })
          }).finally(() => {
            this.sendingProcess = false
          })
        } else if (this.formType === 'edit' && this.targetId) {
          // если как черновик то редактирую
          if (type === 'draft') {
            this.API.update(this.targetId, this.form).then(({ data }) => {
              console.warn(this.$options.name, ' UPDATE ', data.data)
              this.$emit('update', data.data)
              this.$store.commit('notify/ADD', {
                title: 'Черновик отредактирован',
                type: 'success',
                timeout: 3000,
                visible: true
              })
            })
              .catch((error) => {
                console.error('update draft:', error)
              })
              .finally(() => {
                this.sendingProcess = false
              })
          } else {
            // если как отправка то отправляю
            this.API.send_draft(this.targetId || this.id, this.form)
              .then((data) => {
                this.$emit('update', data.data)
                this.$store.commit('notify/ADD', {
                  title: 'Email отправлен',
                  type: 'success',
                  timeout: 3000,
                  visible: true
                })
              })
              .catch((error) => {
                console.error('send_draft:', error)
              })
              .finally(() => {
                this.sendingProcess = false
              })
          }
        } else {
          alert(`Error handleSubmit: ${this.$options.name}`)
        }
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            emailFolder: 'emailFolderId',
            files: 'filesId',
            crmFileId: 'crmFileId',
            contacts: 'contacts',
            body: 'body',
            theme: 'theme'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files' && Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(f => f.id) || [])
              } else if (key === 'contacts') {
                this.$set(this.form,
                  adaperMap[key],
                  Array.isArray(this.preset[key]) && this.preset[key].length ? this.preset[key]
                    .filter(c => {
                      return !!c.email
                    })
                    .map(c => {
                      return {
                        id: c.id,
                        name: c.name,
                        email: c.email
                      }
                    }) : [])
              } else {
                this.$set(this.form, adaperMap[key], this.preset[key])
              }
            }
          }
        }

        this.initProps()
        resolve()
      })
    },
    initProps () {
      if (Array.isArray(this.toPerson?.additionalContacts)) {
        const emails = this.toPerson.additionalContacts.filter(contact => contact.type === 'email')
        if (emails.length) {
          this.form.contacts.push({
            name: `${this.toPerson.name} ${this.toPerson.surname}`,
            email: emails[0].contact
          })
        }
      }
    },
    showProfile () {
      this.showMyProfilePanel({}, () => {
        profileAPI.getProfile()
          .then((responseProfile) => {
            console.warn(responseProfile.data.data)
            this.$store.commit('profile/setUser', responseProfile.data.data)
            this.$store.dispatch('profile/fetchEmailConfigs')
          })
      })
    }
  },
  computed: {
    ...mapGetters({
      currentUserName: 'profile/fullName',
      UserEmailConfigs: 'profile/UserEmailConfigs',
      emails: 'profile/emails'
    }),
    // список ID клиентов - будет использован для прикрепления файлов клиента к письму
    customPersonIds () {
      return this.forDeal ? [this.forDeal?.person?.id, ...(this.forDeal?.otherPersons || []).map(p => p.id)] : []
    },
    defaultEmailConfig () {
      return Array.isArray(this.UserEmailConfigs) &&
        this.UserEmailConfigs.length &&
        this.UserEmailConfigs.find(item => {
          return item.useDefault
        })
    },
    defaultDraftFolder () {
      return Array.isArray(this.UserEmailConfigs) && this.UserEmailConfigs.length && this.UserEmailConfigs[0]?.emailFolders.find(f => f.type && f.type.value === 'draft')
    },
    defaultOutFolder () {
      return Array.isArray(this.UserEmailConfigs) && this.UserEmailConfigs.length && this.UserEmailConfigs[0]?.emailFolders.find(f => f.type && f.type.value === 'sent')
    }
  },
  mounted () {
    this.form.folderId = this?.defaultOutFolder?.id
  }
}
</script>
