<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <div
      v-if="loading"
      class="tw-flex tw-justify-center tw-items-center"
    >
      <TwCircleLoader
        width="50px"
        height="50px"
      />
    </div>
    <template v-else-if="data">
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            Результат по заявке №{{ (data.deal && data.deal.value) || '~' }} <span
              v-if="data.id"
              class="tw-text-an-flat-disabled-text tw-font-medium"
            ># {{ data.id }}</span>
          </div>
        </template>
        <template #right />
      </TwPanelTitle>
      <TwPanelTabs>
        <button class="tw-bg-white tw-rounded-sm tw-px-[14px] tw-py-[8px]">
          Общая информация
        </button>
      </TwPanelTabs>
      <TwPanelContent>
        <div class="tw-flex-1">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              Заявка
            </template>
            <template #action>
              <TwPanelContentBlockActionButton
                type="edit"
                @click.stop="isEdit = !isEdit"
              >
                {{ isEdit ? 'отменить':'изменить' }}
              </TwPanelContentBlockActionButton>
            </template>
            <!--     Минимальная форма - при расширении набора полей - вынести в отдельный компонент       -->
            <div
              v-if="isEdit"
              class="tw-flex tw-gap-[12px] tw-flex-col"
            >
              <TwInputText
                v-model="form.contractDate"
                type="date"
                label="Дата сделки"
                placeholder="Дата сделки"
                :persistent-label="true"
                :show-label="true"
                :error-text="!form.contractDate ? 'Обязательное поле' : ''"
                :dense="true"
              />
              <TwInputText
                v-model="form.contractNum"
                type="text"
                class="tw-mt-[25px]"
                label="Номер договора"
                placeholder="Номер договора"
                :persistent-label="true"
                :show-label="true"
                :error-text="!form.contractNum ? 'Обязательное поле' : ''"
                :dense="true"
              />
              <TwTextarea
                v-model="form.comment"
                rows="3"
                class="tw-mt-[25px]"
                label="Причина изменений"
                placeholder="Причина изменений"
                :disabled="formProcessing"
                :persistent-label="true"
                :error-text="!form.comment ? 'Обязательное поле' : ''"
                :show-label="true"
              />
              <!--    Вывод ошибок      -->
              <div class="tw-p-2 tw-text-center tw-text-an-flat-red-main">
                <div v-if="serverError">
                  {{ serverError }}
                </div>
              </div>
              <TwCardBlockFormActions
                title-submit="Сохранить"
                :use-cancel-alert="false"
                :disable-submit="!form.contractDate"
                :handle-submit="onSubmitUpdate"
                :handle-cancel="() => isEdit = false"
                :disable-cancel="formProcessing"
              />
            </div>
            <div
              v-else
              class="tw-flex tw-gap-[12px] tw-flex-col"
            >
              <TwPanelContentBlockFieldView>
                <template #title>
                  Номер договора
                </template>
                <div>
                  {{ data.contractNum || '-' }}
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView>
                <template #title>
                  Дата сделки
                </template>
                <div v-if="data.contractDate">
                  {{ $Global.DateFormat.DateShort(data.contractDate) }}
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView>
                <template #title>
                  Стоимость
                </template>
                <div v-if="data.objectPrice">
                  {{ (data.objectPrice || 0).toLocaleString('ru') }} ₽
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView>
                <template #title>
                  Предварительная комиссия
                </template>
                <div v-if="data.preliminaryCommission">
                  {{ (data.preliminaryCommission || 0).toLocaleString('ru') }} ₽
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView>
                <template #title>
                  Заявка
                </template>
                <div v-if="data.deal && data.deal.value">
                  <ViewPanelLink
                    :id="data.deal.value"
                    label="Заявка"
                    component="deal-show"
                  >
                    #{{ data.deal.label }}
                  </ViewPanelLink>
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView>
                <template #title>
                  Ответственный
                </template>
                <div v-if="data.responsibleUserId">
                  <ViewPanelLink
                    :id="data.responsibleUserId"
                    label="Пользователь"
                    component="employee-show"
                  >
                    <EmployeeName :id="data.responsibleUserId" />
                  </ViewPanelLink>
                </div>
              </TwPanelContentBlockFieldView>
              <TwPanelContentBlockFieldView>
                <template #title>
                  Клиент
                </template>
                <div v-if="data.person && data.person.value">
                  <ViewPanelLink
                    :id="data.person.value"
                    label="Клиент"
                    component="person-show"
                  >
                    <PersonName
                      :id="data.person.value"
                      format="fio"
                    />
                  </ViewPanelLink>
                </div>
              </TwPanelContentBlockFieldView>
            </div>
          </TwPanelContentBlock>
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              Приобретенный объект
            </template>
            <template #default>
              <div class="tw-flex tw-gap-[12px] tw-flex-col">
                <TwPanelContentBlockFieldView>
                  <template #title>
                    Объект
                  </template>
                  <div>
                    <div
                      class="tw-text-an-flat-dark-blue-link tw-cursor-pointer"
                      @click.prevent="openPanelApartment"
                    >
                      {{ data.realtyObject?.name || '-' }}
                    </div>
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView>
                  <template #title>
                    Застройщик
                  </template>
                  <div>
                    {{ data.realtyObject?.builder?.label || '-' }}
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView>
                  <template #title>
                    Инфо по брони
                  </template>
                  <div
                    v-if="data.reservation"
                    class="tw-flex tw-gap-[12px] tw-flex-col"
                  >
                    <div
                      v-if="data.reservation.requestId"
                      class="tw-text-an-flat-dark-blue-link tw-cursor-pointer"
                      @click="openPanelReservation(data.reservation.requestId)"
                    >
                      Бронирование № {{ data.reservation.requestId }}
                    </div>
                    <TwFilesListViewByIds
                      v-if="Array.isArray(data.reservation.petitionFiles) && data.reservation.petitionFiles.length"
                      :file-ids="data.reservation.petitionFiles.map(i => i.id)"
                      :is-drop-down="false"
                      view-type="list"
                    />
                  </div>
                </TwPanelContentBlockFieldView>
              </div>
            </template>
          </TwPanelContentBlock>
        </div>
        <div class="tw-flex-1">
          <TwPanelContentBlock
            class="tw-mb-[6px]"
            :content-classes="''"
          >
            <template #title>
              Сканы договора
            </template>
            <template #default>
              <div
                v-if="Array.isArray(data.contractFiles) && data.contractFiles.length"
                class="tw-flex tw-gap-[12px] tw-flex-col tw-max-h-[400px] tw-overflow-auto"
              >
                <TwFilesListViewByIds
                  :file-ids="data.contractFiles.map(i => i.id)"
                  :is-drop-down="false"
                  view-type="list"
                />
              </div>
            </template>
          </TwPanelContentBlock>
          <DealNbResultManagementStatusBlock
            :id="data.id"
            @refresh="refresh"
          />
        </div>
      </TwPanelContent>
    </template>
    <div v-else>
      Не удалось получить объект данных
    </div>
  </div>
</template>

<script>
import API from '@/Api/Crm/Deal/BuyNb/ResultManagement'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabs from '@/components/tw-ui/panels/TwPanelTabs.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwFilesListViewByIds from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import PersonName from '@/components/other/person/PersonName.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import { mapActions } from 'vuex'
import DealNbResultManagementStatusBlock
  from '@/views/Newbuildings/ResultManagement/DealNbResultManagementStatusBlock.vue'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  name: 'DealNbResultManagementCard',
  components: {
    TwTextarea,
    TwInputText,
    TwCardBlockFormActions,
    TwPanelContentBlockActionButton,
    TwCircleLoader,
    DealNbResultManagementStatusBlock,
    EmployeeName,
    PersonName,
    ViewPanelLink,
    TwFilesListViewByIds,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabs,
    TwPanelTitle
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  data () {
    return {
      API: API || null,
      isEdit: false,
      formProcessing: false,
      form: {
        contractDate: null, // "2023-11-01"
        contractNum: null,
        comment: null
      },
      serverError: null
    }
  },
  methods: {
    ...mapActions({
      showPanel: 'viewPanels/showPanel'
    }),
    onSubmitUpdate () {
      this.formProcessing = true
      API.update(this.id, this.form)
        .then(() => {
          this.isEdit = false
          this.refresh()
        })
        .catch((error) => {
          this.serverError = error?.response?.data
          // console.warn(error)
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    openPanelApartment () {
      this.showPanel({
        label: 'Квартира',
        isCloseConfirm: false,
        viewType: 'modal',
        component: 'nb-selection-apartment-modal-show',
        props: {
          id: this.data?.realtyObject?.id
        },
        callback: () => {
          console.warn(this.$options.name, '- showPanel')
        }
      })
    },
    openPanelReservation (id = 0) {
      this.showManagementRequestToDeveloperCard({ id })
    }
  },
  watch: {
    isEdit (newValue) {
      if (newValue) {
        this.form.contractDate = this.data?.contractDate || null
        this.form.contractNum = this.data?.contractNum || null
        this.form.comment = this.data?.comment || null
      }
    }
  }
}
</script>
