import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'

export default {
  path: `${RouteKeys.siteFaq}`,
  name: RouteKeys.siteFaq,
  redirect: { name: `${RouteKeys.siteFaq}-list` },
  meta: { name: 'Частые вопросы', icon: 'admin-faq', isAdmin: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesSITEContentSiteFaqRule },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.siteFaq}-list`,
      meta: { name: 'Список вопросов', icon: 'admin-faq', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Faq/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.siteFaq}-edit`,
      meta: { name: 'Редактирование вопроса', icon: 'admin-faq', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Faq/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.siteFaq}-create`,
      meta: { name: 'Добавление вопроса', icon: 'admin-faq', hideInMenu: true },
      component: () => import('@/views/Site/Admin/Content/Faq/Edit.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
