<template>
  <div
    :style="{ padding }"
    class="card-block-field card-block-field-field-select card-block-field-click-editable"
  >
    <div class="card-block-field-title">
      <span class="card-block-field-title-text">
        <slot name="title">Заголовок</slot>
      </span>
    </div>
    <div class="card-block-field-inner">
      <div class="card-block-field-inner-text">
        <slot name="content">
          Содержимое
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardBlockField',
  props: {
    padding: {
      type: String,
      default: '0 15px'
    }
  }
}
</script>

<style scoped>
.card-block-field-title-text {
  width: 100%;
}
.card-block-field {
  box-sizing: border-box;
  margin: 15px 0;
  padding: 0;
}
.card-block-field-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  margin-bottom: 5px;
  /*padding-right: 20px;*/
  color: #a3a9b1;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.card-block-field-inner {
  color: #424956;
  word-break: break-word;
}

.card-block-field-inner {
  position: relative;
  margin: 0 0 10px;
  transition: 220ms opacity linear;
}
</style>
