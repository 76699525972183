<template>
  <div class="tw-flex tw-gap-[16px]">
    <div class="tw-grow">
      <DeveloperRequestGeneralInfo
        class="tw-sticky tw-top-0"
        :data="data"
        @showHistory="$emit('showHistory')"
      />
    </div>
    <div class="tw-grow tw-bg-an-flat-bg-main tw-rounded-[10px] tw-text-[14px] tw-pt-[33px] tw-pr-[30px] tw-pb-[30px] tw-pl-[36px]">
      <div class="tw-flex tw-justify-between tw-mb-[17px] tw-border-b-[2px] tw-border-white tw-pb-[17px] last:tw-mb-0 last:tw-border-b-0 last:tw-pb-0">
        <div
          v-if="data.params.objectId"
          class="tw-grow"
        >
          <b class="tw-block tw-text-[16px] tw-font-bold tw-leading-[22px] tw-mb-[15px]">Информация об объекте</b>
          <InformationCommitsReservation
            :id="data.params.objectId"
            :company-id="data.companyId"
            @changeReservationPrice="$emit('changeReservationPrice', $event)"
          />
        </div>
        <div class="tw-flex tw-flex-col">
          <!--  Кнопка копирования текста -->
          <ClickBoard
            v-if="data.textForCopy"
            class="tw-mb-[7px]"
            :disabled="false"
          >
            <template #default="{ copyText, clipboardSuccessHandler, clipboardErrorHandler }">
              <button
                v-clipboard="copyText(data.textForCopy)"
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
                class="tw-group tw-p-[10px] tw-bg-an-flat-panel-modal-bg tw-rounded-[5px] tw-w-[44px] tw-h-[44px] disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-dark-blue-link"
              >
                <svg
                  class="tw-mx-auto"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="group-enabled:group-hover:tw-fill-white"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.425781 3.77762C0.426013 3.53519 0.522407 3.30274 0.693814 3.1313C0.865221 2.95985 1.09764 2.8634 1.34007 2.86311H7.69834L10.7107 5.87617V13.0855C10.7105 13.3279 10.6141 13.5603 10.4427 13.7318C10.2713 13.9032 10.0389 13.9997 9.79646 14H1.34007C1.09764 13.9997 0.865221 13.9033 0.693814 13.7318C0.522407 13.5604 0.426013 13.3279 0.425781 13.0855V3.77768V3.77762ZM3.28933 0.914512V2.04302H7.59384C7.73496 2.04189 7.87486 2.06929 8.00513 2.12357C8.13541 2.17785 8.25337 2.25789 8.35194 2.35889L11.2164 5.222C11.3172 5.32079 11.3969 5.43892 11.4509 5.56929C11.5048 5.69966 11.5319 5.83959 11.5304 5.98068V11.1346H12.6601C12.9021 11.1345 13.1343 11.0384 13.3057 10.8674C13.477 10.6964 13.5736 10.4645 13.5744 10.2224V3.01312L10.5619 0H4.20384C3.96138 0.000282234 3.72894 0.0967229 3.55749 0.268166C3.38605 0.439609 3.28961 0.672055 3.28933 0.914512Z"
                    fill="#34363B"
                  />
                </svg>
              </button>
            </template>
          </ClickBoard>
          <!--  Кнопка скачивания документа -->
          <button
            v-if="data.showDownloadButton"
            title="Скачать файлы"
            class="tw-group tw-p-[10px] tw-bg-an-flat-panel-modal-bg tw-rounded-[5px] tw-w-[44px] tw-max-h-[44px] tw-h-[100%] disabled:tw-opacity-50 enabled:hover:tw-bg-an-flat-dark-blue-link"
            @click="$emit('downloadFiles')"
          >
            <svg
              class="tw-mx-auto"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="group-enabled:group-hover:tw-fill-white"
                d="M11.6666 15H3.33332C2.44302 15 1.60595 14.6533 0.976328 14.0236C0.346732 13.3941 0 12.557 0 11.6666V10.8333C0 10.373 0.373077 9.99991 0.833338 9.99991C1.2936 9.99991 1.66668 10.373 1.66668 10.8333V11.6666C1.66668 12.1118 1.84006 12.5303 2.1548 12.8451C2.46962 13.1599 2.88814 13.3333 3.33332 13.3333H11.6666C12.1118 13.3333 12.5303 13.1599 12.8451 12.8451C13.1599 12.5303 13.3333 12.1118 13.3333 11.6666V10.8333C13.3333 10.373 13.7064 9.99991 14.1666 9.99991C14.6269 9.99991 15 10.373 15 10.8333V11.6666C15 12.557 14.6533 13.394 14.0236 14.0236C13.394 14.6533 12.557 15 11.6666 15ZM7.49998 11.6666C7.38471 11.6666 7.27496 11.6432 7.17512 11.6009C7.08196 11.5616 6.99443 11.5044 6.91784 11.4296C6.91781 11.4296 6.91781 11.4295 6.91778 11.4295C6.91723 11.429 6.91667 11.4285 6.91612 11.4279C6.91598 11.4278 6.9158 11.4276 6.91566 11.4274C6.91519 11.427 6.91478 11.4266 6.91435 11.4262C6.91405 11.4259 6.91379 11.4256 6.9135 11.4253C6.91321 11.425 6.91283 11.4247 6.91257 11.4244C6.91199 11.4238 6.91135 11.4232 6.91077 11.4226L3.57741 8.08923C3.25199 7.76381 3.25199 7.23616 3.57741 6.91071C3.90284 6.58528 4.43052 6.58525 4.75594 6.91071L6.66668 8.82144V0.833338C6.66665 0.373077 7.03972 0 7.49998 0C7.96025 0 8.33335 0.373077 8.33335 0.833338V8.82141L10.2441 6.91071C10.5695 6.58528 11.0972 6.58528 11.4226 6.91071C11.748 7.23613 11.748 7.76381 11.4226 8.08923L8.08923 11.4226C8.08865 11.4231 8.08801 11.4238 8.08743 11.4244C8.08711 11.4247 8.08676 11.425 8.0865 11.4253C8.08621 11.4256 8.08595 11.4258 8.08565 11.4261C8.08525 11.4266 8.08478 11.427 8.08437 11.4274C8.08423 11.4275 8.08402 11.4277 8.08388 11.4279C8.08335 11.4284 8.0828 11.429 8.08225 11.4295C8.08222 11.4295 8.08222 11.4295 8.08219 11.4295C8.07302 11.4385 8.06373 11.4472 8.05424 11.4556C7.98447 11.5178 7.90674 11.5664 7.82456 11.601C7.82427 11.6011 7.82404 11.6012 7.82375 11.6014C7.82343 11.6015 7.82317 11.6016 7.82285 11.6017C7.72352 11.6436 7.61448 11.6666 7.49998 11.6666Z"
                fill="#34363B"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        v-if="data.comment"
        class="tw-mb-[20px]"
      >
        <b class="tw-block tw-mb-[8px] tw-text-an-flat-black-main tw-text-[16px] tw-font-bold">Примечание</b>
        <div class="tw-text-an-flat-gray-4">
          {{ data.comment }}
        </div>
      </div>
      <div
        v-if="infoReservationType"
        class="tw-mb-[17px] tw-border-b-[2px] tw-border-white tw-pb-[17px] last:tw-mb-0 last:tw-border-b-0 last:tw-pb-0"
      >
        <b class="tw-block tw-text-[16px] tw-font-bold tw-leading-[22px] tw-mb-[8px]">Способ бронирования</b>
        <span class="tw-block">
          <div class="tw-font-medium">
            {{ infoReservationType.label }}
            <span
              v-if="infoReservationType.paid"
              class="tw-font-medium"
            >
              - Стоимость: <b>{{ (infoReservationType.price || 0).toLocaleString('ru') }}</b> руб.
            </span>
          </div>
          {{ infoReservationType.description }}
        </span>
      </div>
      <div class="tw-mb-[17px] tw-border-b-[2px] tw-border-white tw-pb-[17px] last:tw-mb-0 last:tw-border-b-0 last:tw-pb-0">
        <b class="tw-block tw-text-[16px] tw-font-bold tw-leading-[22px] tw-mb-[8px]">Способ оплаты</b>
        <div class="tw-pt-[16px] tw-px-[30px] tw-pb-[17px] tw-rounded-[20px] tw-bg-white">
          <div class="tw-text-h5">
            {{ infoPaymentMethod || 'Нет информации об оплате' }}
          </div>
        </div>
      </div>
      <div
        v-if="data.builderSettings && data.builderSettings.days"
        class="tw-mb-[20px]"
      >
        <b class="tw-block tw-mb-[8px] tw-text-an-flat-black-main tw-text-[16px] tw-font-bold">Срок бронирования</b>
        <div class="tw-text-an-flat-gray-4">
          {{ data.builderSettings.days }} дн.
        </div>
      </div>
      <div class="tw-mb-[20px]">
        <b class="tw-block tw-mb-[8px] tw-text-an-flat-black-main tw-text-[16px] tw-font-bold">Информация для ответственного</b>
        <div class="tw-text-an-flat-gray-4">
          {{ data.builderSettings && data.builderSettings.forResponsible || 'Отсутствует' }}
        </div>
      </div>
      <div
        v-if="data.builderSettings"
        class="tw-mb-[20px]"
      >
        <div>
          <b class="tw-block tw-mb-[8px] tw-text-an-flat-black-main tw-text-[16px] tw-font-bold">Способ связи с застройщиком</b>
          <div
            v-if="data.builderSettings.communication"
            class="tw-flex tw-gap-[6px] tw-items-center"
          >
            <div class="tw-text-an-flat-gray-4">
              {{ data.builderSettings.communication.method.label }}:
            </div>
            <div class="tw-font-semibold">
              {{ data.builderSettings.communication.contact || 'Незаполнен' }}
              <DeveloperRequestEmailWrite
                v-if="data.builderSettings.communication.contact && data.builderSettings.communication.method.value === 'email'"
                :request-id="data.id"
              />
            </div>
          </div>
          <div
            v-else
            class="tw-text-an-flat-gray-4"
          >
            Не заполнен
          </div>
          <div
            v-if="data.builderSettings.communication"
            class="tw-text-an-flat-gray-4"
          >
            {{ data.builderSettings.communication.description || '' }}
          </div>
        </div>
        <div>
          <div v-if="data.builderSettings.communication.contact && data.builderSettings.communication.method.value === 'email'">
            <TwTableEmailBlock
              :entity-id="data.id"
              entity="request_to_developer"
            >
              <template #header>
                <div>
                  Отправленные сообщения
                </div>
              </template>
            </TwTableEmailBlock>
          </div>
        </div>
      </div>
      <div class="tw-mb-[17px] tw-border-b-[2px] tw-border-white tw-pb-[17px] last:tw-mb-0 last:tw-border-b-0 last:tw-pb-0">
        <b class="tw-block tw-text-[16px] tw-font-bold tw-leading-[22px] tw-mb-[8px]">Документы</b>
        <div>
          <TwFilesListViewByIds
            :file-ids="data.personFileId"
            :is-drop-down="false"
            title="Прикрепленные документы"
            class="tw-pt-[25px] tw-px-[30px] tw-pb-[23px] tw-rounded-[20px] tw-bg-white tw-mb-[17px] last:tw-mb-0"
          />
          <b class="tw-block tw-text-[16px] tw-font-bold tw-leading-[22px] tw-mb-[8px]">Ходатайство</b>
          <TwFilesListViewByIds
            v-if="Array.isArray(petitionFileId) && petitionFileId.length"
            :file-ids="petitionFileId"
            :api="rtdFileApi"
            :is-drop-down="false"
            class="tw-mt-[10px] tw-pt-[25px] tw-px-[30px] tw-pb-[23px] tw-rounded-[20px] tw-bg-white tw-mb-[17px] last:tw-mb-0"
          />
          <div
            v-else
            class="tw-text-center tw-text-an-flat-disabled-text"
          >
            Не загружено
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rtdFileApi from '@/Api/Rtd/File'
import TwFilesListViewByIds
  from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'
import InformationCommitsReservation
  from '@/views/Newbuildings/Management/Request/ApplicationReservation/InformationCommitsReservation.vue'
import DeveloperRequestGeneralInfo
  from '@/views/Newbuildings/Management/Request/ApplicationComponents/DeveloperRequestGeneralInfo.vue'
import ClickBoard from '@/components/button/ClickBoard.vue'
import DeveloperRequestEmailWrite
  from '@/views/Newbuildings/Management/Request/ApplicationComponents/DeveloperRequestEmailWrite.vue'
import TwTableEmailBlock from '@/components/tw-ui/list/TwTableEmailBlock/TwTableEmailBlock.vue'

export default {
  name: 'ApplicationReservationWrapper',
  components: { TwTableEmailBlock, DeveloperRequestEmailWrite, ClickBoard, DeveloperRequestGeneralInfo, InformationCommitsReservation, TwFilesListViewByIds },
  props: {
    data: {
      type: Object,
      default: null
    },
    dictionary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      rtdFileApi,
      petitionFileId: this.data?.petitionFileId
    }
  },
  computed: {
    infoReservationType () {
      return this.dictionary?.reservationType?.find(item => item?.value === this.data?.params?.reservationType)
    },
    infoPaymentMethod () {
      const result = this.dictionary.paymentMethod.find(item => item?.value === this.data?.params?.paymentMethod)
      return result?.label
    }
  }
}
</script>

<style scoped>

</style>
