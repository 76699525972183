import { AxiosResponse } from 'axios'
import HTTP, { downloadFile, buildInitialHttp } from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Email'

const path = '/file'

export default {
  types (): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/types`, $config)
  },
  upload (data: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      headers: {
        'Content-Type': 'multipart/form-data;charset=utf-8'
      }
    }
    return HTTP.post(`${path}`, data, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },
  // Старый вариант скачивания
  download (url: string, fileName: string) {
    downloadFile(url, fileName)
  },
  // Скачивание - с открытием в новой вкладке
  downloadById (id: number, fileName?: any) {
    let tmpHTTP:any = buildInitialHttp({ ...AuthAxiosDefaultConfig })
    const link = document.createElement('a')
    link.href = `${tmpHTTP.defaults.baseURL}${path}/${id}/download`
    link.download = fileName || undefined
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    // удаляю ссылку и чищу память
    document.body.removeChild(link)
    // Чищу память от axios
    tmpHTTP = null
  }
}
