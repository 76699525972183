<template>
  <div
    v-if="formProcessing"
    class="tw-py-3 tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    class="tw-flex"
  >
    <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-[12px]">
      <div style="display: none">
        {{ isEdited }}
      </div>
      <TwSelectButton
        v-model="form.commercial"
        label="Тип планировки"
        :multi="false"
        :disabled="formProcessing"
        :disable-error-border="true"
        :disable-unselect-last-item="true"
        color="green"
        :options="plansType"
        @input="changeCommercialType"
      />

      <TwSelectRelations
        v-model="form.builderId"
        class="tw-mt-[12px]"
        :disabled="formProcessing || formType !== 'create' || !!defaultFormFields.builderId"
        :multi="false"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        label="Застройщик"
        placeholder="Выбрать застройщика"
        list-label="Найти застройщика"
        :error-text="$v.form.builderId.$invalid && 'Обязательное поле'"
        relation-name="developer"
      />

      <TwSelectRelations
        v-model="form.complexId"
        class="tw-mt-[12px]"
        :disabled="formProcessing || formType !== 'create' || !!defaultFormFields.complexId"
        :multi="false"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        label="Жилой массив"
        placeholder="Выбрать жилой массив"
        list-label="Найти ЖМ"
        :default-filters="complexFilters"
        :error-text="$v.form.complexId.$invalid && 'Обязательное поле'"
        relation-name="complex"
      />

      <TwInputText
        v-model="form.name"
        class="tw-mt-[12px]"
        type="text"
        autocomplete="off"
        placeholder="Название планировки"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        :error-text="$v.form.name.$invalid ? 'Обязательное поле':''"
      />

      <div
        v-if="!form.commercial"
        class="tw-mt-[18px] tw-flex tw-gap-[15px]"
      >
        <TwCheckBox
          v-model="form.studio"
          :disabled="formProcessing"
          background-and-border-color="tw-bg-an-flat-green-btn-bg tw-border-an-flat-green-btn-bg"
          :label="`Студия (${form.studio ? 'Да':'Нет'})`"
        />
      </div>

      <div class="tw-mt-[12px] tw-flex tw-gap-[15px] tw-items-center tw-justify-between">
        <TwInputText
          v-model.number="form.totalArea"
          type="text"
          max-length="6"
          sub-type="float"
          class="tw-flex-1"
          autocomplete="off"
          placeholder="Общая площадь"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          :error-text="$v.form.totalArea.$invalid ? 'Обязательное поле':''"
        >
          <template #append>
            м²
          </template>
        </TwInputText>
        <TwInputText
          v-if="!form.commercial"
          v-model="form.residentialArea"
          type="text"
          max-length="6"
          sub-type="float"
          class="tw-flex-1"
          autocomplete="off"
          placeholder="Жилая площадь"
          :disabled="formProcessing"
          :show-label="true"
          :dense="true"
          :persistent-label="true"
          :error-text="$v.form.residentialArea.$invalid ? 'Обязательное поле':''"
        >
          <template #append>
            м²
          </template>
        </TwInputText>
      </div>
      <div class="tw-mt-[15px]">
        <div class="tw-text-an-flat-blue-btn-active tw-text-sm2 tw-mb-[5px] tw-mt-[10px]">
          Список комнат:
        </div>
        <TwCheckBox
          v-model="form.connectingRooms"
          class="tw-my-[15px]"
          :disabled="formProcessing"
          background-and-border-color="tw-bg-an-flat-green-btn-bg tw-border-an-flat-green-btn-bg"
          :label="`Проходные комнаты (${form.connectingRooms ? 'Да':'Нет'})`"
        />

        <div>
          <table class="tw-w-full">
            <thead>
              <tr class="tw-text-main-xs tw-bg-an-flat-bg-main tw-text-an-flat-gray-4">
                <th class="tw-p-[7px] tw-text-center">
                  Тип
                </th>
                <th class="tw-p-[7px] tw-text-center">
                  Площадь, м²
                </th>
                <th class="tw-p-[7px] tw-text-center">
                  Отапливаемая
                </th>
                <th class="tw-p-[7px] tw-text-center" />
              </tr>
            </thead>
            <tbody class="tw-bg-white">
              <template v-if="Array.isArray(form.rooms) && form.rooms.length">
                <tr
                  v-for="(roomInfo, index) in form.rooms"
                  :key="index"
                >
                  <td class="tw-min-w-[200px] tw-p-2">
                    <TwSelect
                      v-model="form.rooms[index].type"
                      class="tw-mt-0"
                      placeholder="Тип"
                      :multi="false"
                      :dense="true"
                      :options="dictionary.type"
                      :error-text="form.rooms[index].type ? '' : ' '"
                      :disabled="formProcessing"
                      :show-label="false"
                      :persistent-label="false"
                    />
                  </td>
                  <td class="tw-p-2 tw-w-[120px]">
                    <TwInputText
                      v-model="form.rooms[index].area"
                      type="text"
                      max-length="6"
                      sub-type="float"
                      class="tw-mt-0 tw-flex-1"
                      autocomplete="off"
                      placeholder="0"
                      :disabled="formProcessing"
                      :dense="true"
                      :error-text="form.rooms[index].area ? '' : ' '"
                      :persistent-label="false"
                      :show-label="false"
                    />
                  </td>
                  <td class="tw-p-2">
                    <div class="tw-flex tw-justify-center">
                      <TwCheckBox
                        v-model="form.rooms[index].heated"
                        :disabled="formProcessing"
                        background-and-border-color="tw-bg-an-flat-dark-blue-link tw-border-an-flat-dark-blue-link"
                        class="tw-block"
                        label=""
                      />
                    </div>
                  </td>
                  <td class="tw-p-2">
                    <button
                      class="tw-group"
                      title="Удалить комнату"
                      @click.prevent="removeRoomByIndex(index)"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="white"
                          class="tw-fill-an-flat-disabled-text group-hover:tw-fill-an-flat-red-main"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM4.82878 4.82879C5.05954 4.59803 5.43369 4.59803 5.66445 4.82879L6.5 5.66434L7.33555 4.82879C7.56631 4.59803 7.94045 4.59803 8.17122 4.82879C8.40198 5.05956 8.40198 5.4337 8.17122 5.66446L7.33567 6.50001L8.17147 7.33581C8.40223 7.56657 8.40223 7.94072 8.17147 8.17148C7.9407 8.40225 7.56656 8.40225 7.33579 8.17148L6.5 7.33568L5.6642 8.17148C5.43344 8.40224 5.0593 8.40224 4.82853 8.17148C4.59777 7.94071 4.59777 7.56657 4.82853 7.33581L5.66433 6.50001L4.82878 5.66447C4.59802 5.4337 4.59802 5.05956 4.82878 4.82879Z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td
                  colspan="99"
                  class="tw-pt-[30px] tw-pb-[15px] tw-text-an-flat-red-main tw-text-center"
                >
                  Комнаты не добавлены
                </td>
              </tr>
              <tr>
                <td
                  colspan="99"
                  class="tw-py-[15px] tw-text-center"
                >
                  <button
                    class="tw-w-full tw-py-[8px] tw-text-an-flat-green-btn-bg tw-bg-an-flat-green-btn-bg/5 tw-border tw-border-an-flat-green-btn-bg tw-border-dashed tw-rounded-md enabled:hover:tw-bg-an-flat-green-btn-bg/20"
                    @click.prevent="addNewRoom"
                  >
                    Добавить комнату
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="tw-mt-[15px]">
        <div class="tw-text-an-flat-blue-btn-active tw-text-sm2 tw-mb-[5px] tw-mt-[10px]">
          Изображение планировки:
        </div>
        <TwUploadFilesList
          label="Файл планировки"
          :value="form.genPlanFilesId"
          :api="osFileAPI"
          file-type="mainPlanImage"
          :has-inline="false"
          :multiple="true"
          @input="form.genPlanFilesId = $event"
        />
      </div>
      <TwCardSimpleFormSaveBlock
        class="tw-mt-[15px]"
        :loading="formProcessing"
        :cancel-confirm="isEdited"
        :is-sticky="true"
        :disable-success="formProcessing || $v.form.$invalid"
        :disable-cancel="formProcessing"
        :visible="true"
        @success="handleSubmit"
        @cancel="handleCancel"
      />
    </div>
    <div class="tw-flex-1 tw-pl-[20px] tw-relative">
      <span class="tw-text-an-flat-gray-5">Предпросмотр планировки:</span>
      <div
        v-if="Array.isArray(form.genPlanFilesId) && form.genPlanFilesId.length"
        class="tw-sticky tw-top-0 tw-left-0 tw-flex tw-flex-col tw-items-center"
      >
        <TwSimpleImageViewById
          v-for="fileId in form.genPlanFilesId"
          :key="fileId"
          class="tw-mt-[5px]"
          :value="[fileId]"
          :is-public="false"
          :multiple="false"
          :api="osFileAPI"
        />
      </div>
      <div
        v-else
        class="tw-p-3 tw-text-an-flat-disabled-text"
      >
        Нет загруженных изображений планировок
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import osFileAPI from '@/Api/ObjectStorage/File'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import { required } from 'vuelidate/lib/validators'
import TwCheckBox from '@/components/tw-ui/ui-kit/input/TwCheckBox.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwSelectButton from '@/components/tw-ui/ui-kit/button/TwSelectButton.vue'
import TwSimpleImageViewById from '@/components/tw-ui/ui-kit/files/TwSimpleImageViewById.vue'

export default {
  name: 'RealtyComplexPlanMainForm',
  components: {
    TwSimpleImageViewById,
    TwSelectButton,
    TwSelect,
    TwCircleLoader,
    TwSelectRelations,
    TwCheckBox,
    TwInputText,
    TwUploadFilesList,
    TwCardSimpleFormSaveBlock
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      plansType: [
        {
          label: 'Жилая',
          value: false
        },
        {
          label: 'Коммерческая',
          value: true
        }
      ],
      osFileAPI,
      API: API.Editor.Plan,
      form: {
        name: null,
        studio: true,
        genPlanFilesId: null,
        builderId: null,
        complexId: null,
        totalArea: null,
        residentialArea: null,
        commercial: true,
        rooms: []
      }
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        totalArea: {
          required
        },
        residentialArea: {
          ...(!this.form.commercial) && {
            required
          }
        },
        complexId: {
          ...(!this.form.complexId && !this.form.builderId) && {
            required
          }
        },
        builderId: {
          ...(!this.form.complexId && !this.form.builderId) && {
            required
          }
        }
      }
    }
  },
  methods: {
    changeCommercialType (eventData) {
      if (eventData) {
        this.form.studio = false
        this.form.residentialArea = null
      }
    },
    addNewRoom () {
      if (!this.form.rooms || !Array.isArray(this.form.rooms) || !this.form.rooms.length) {
        this.$set(this.form, 'rooms', [])
      }
      this.form.rooms.push({
        type: null,
        area: null,
        heated: true
      })
    },
    removeRoomByIndex (targetIndex) {
      if (this.form.rooms?.[targetIndex]) {
        this.form.rooms?.splice(targetIndex, 1)
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            genPlanFiles: 'genPlanFilesId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (adaperMap[key].indexOf('Id') !== -1) {
                this.$set(this.form, adaperMap[key], (Array.isArray(this.preset[key]) && this.preset[key].map(i => i.id)))
              }
            } else if (key === 'rooms') {
              if (this.preset[key] && Array.isArray(this.preset[key])) {
                this.$set(this.form, key, this.preset[key].map(r => {
                  return {
                    ...r,
                    type: r.type?.value || null
                  }
                }))
              } else {
                this.addNewRoom()
              }
            } else {
              this.$set(this.form, key, this.defaultFormFields[key] || this.preset[key])
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      // console.log('handleSubmit ', this.$v)
      if (!this.$v.form.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.form?.builderId || undefined
      }
    }
  }
}
</script>
