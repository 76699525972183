<template>
  <div :title="title">
    <slot v-bind="{ copyText, clipboardSuccessHandler, clipboardErrorHandler }">
      <button
        v-clipboard="() => value"
        v-clipboard:success="clipboardSuccessHandler"
        v-clipboard:error="clipboardErrorHandler"
        :title="title"
        :disabled="disabled"
        class="tw-p-[4px] tw-flex tw-items-center tw-gap-[6px] disabled:tw-opacity-50 enabled:hover:tw-text-an-flat-dark-blue-link"
      >
        <TwIconWrapper file-name="copy-1" />
        <slot name="button-text" />
      </button>
    </slot>
  </div>
</template>

<script>
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'

export default {
  name: 'ClickBoard',
  components: { TwIconWrapper },
  props: {
    disabled: {
      default: true
    },
    value: { // Значение которое будет скопировано
      default: ''
    },
    titleActive: {
      default: 'Копировать'
    },
    titleDisabled: {
      default: 'Недоступно'
    },
    showAlert: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    title () {
      return this.disabled ? this.titleDisabled : this.titleActive
    }
  },
  methods: {
    copyText (targetText) {
      return targetText
    },
    clipboardSuccessHandler () {
      if (this.showAlert) {
        this.$store.commit('notify/ADD', {
          title: 'Скопировано',
          type: 'info',
          timeout: 1500,
          visible: true
        })
      }
      this.$emit('success')
    },
    clipboardErrorHandler () {
      if (this.showAlert) {
        this.$store.commit('notify/ADD', {
          title: 'Ошибка копирования',
          type: 'danger',
          timeout: 1500,
          visible: true
        })
      }
      this.$emit('error')
    }
  }
}
</script>

<style scoped>

</style>
