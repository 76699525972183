<template>
  <div v-if="!formProcessing">
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div class="mt-5">
      <TwSelect
        ref="theme"
        v-model="form.theme"
        label="Тема обращения"
        placeholder="Выбрать"
        :options="themeOptions"
        :error-text="form.theme !== null ? '':themeErrors"
        class="tw-mt-[24px]"
        :dense="true"
        :persistent-label="true"
        @input="$v.form.theme.$touch()"
      />
      <TwSelect
        v-show="form.theme"
        ref="subTheme"
        v-model="form.subTheme"
        label="Уточнение тематики"
        placeholder="Выбрать"
        :options="subThemeOptions"
        :error-text="form.subTheme !== null ? '':subThemeErrors"
        class="tw-mt-[12px]"
        :dense="true"
        :persistent-label="true"
        @input="$v.form.subTheme.$touch()"
      />
    </div>
    <div>
      <TwPanelInfoBlock
        type="warning"
        class="tw-my-[12px]"
      >
        <template #title />
        <template #text>
          <div>
            По возможности укажите ссылку и/или добавьте скриншот - это поможет быстрее решить ваш вопрос
          </div>
        </template>
      </TwPanelInfoBlock>
    </div>
    <div>
      <TwTextarea
        ref="description"
        v-model="form.description"
        rows="5"
        resize="y"
        class="tw-mt-[12px]"
        autocomplete="off"
        persistent-label
        placeholder="Описание"
        :disabled="formProcessing"
        :error-text="Array.isArray(descriptionErrors) && descriptionErrors.length ? descriptionErrors[0] : '' "
        :show-label="true"
        @input="$v.form.description.$touch()"
        @blur="$v.form.description.$touch()"
      />
    </div>
    <div
      ref="fileId"
      class="tw-my-[24px]"
    >
      <div class="tw-font-bold tw-mb-[10px]">
        Прикреплённые файлы:
      </div>
      <TwUploadFilesList
        v-model="form.fileId"
        label="Загрузить файлы"
        height="120px"
        :api="supportFileAPI"
      />
    </div>
    <TwCardSimpleFormSaveBlock
      bg-color="transparent"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :disable-cancel="formType === 'create'"
      :is-sticky="false"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
  <div
    v-else
    class="tw-py-3 tw-text-center tw-m-auto"
  >
    <TwCircleLoader />
  </div>
</template>

<script>
import API from '@/Api/Support/Task'
import { required } from 'vuelidate/lib/validators'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import supportFileAPI from '@/Api/Support/File'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelInfoBlock from '@/components/tw-ui/panels/TwPanelInfoBlock.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'

export default {
  name: 'SupportMainForm',
  components: {
    TwSelect,
    TwPanelInfoBlock,
    TwCircleLoader,
    TwTextarea,
    TwCardSimpleFormSaveBlock,
    TwUploadFilesList
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      supportFileAPI,
      API: API,
      form: {
        description: '',
        fileId: [],
        theme: null,
        subTheme: null
      }
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations: {
    form: {
      theme: {
        required: function (v) {
          if (!this.form.theme && this.form.theme !== 0) {
            return !!v
          }
          return true
        }
      },
      subTheme: {
        required: function (v) {
          if (this.form.theme && this.form.subTheme !== 0) {
            return !!v
          }
          return true
        }
      },
      description: {
        required
      }
    }
  },
  computed: {
    themeOptions () {
      return [
        ...(this.dictionary.theme || [])
      ]
    },
    subThemeOptions () {
      return [
        ...((Array.isArray(this.dictionary.subTheme) && this.dictionary.subTheme.filter(item => item.themeId === this.form.theme)) || []),
        ...[
          {
            label: 'Другое',
            value: 0
          }
        ]
      ]
    },
    themeErrors () {
      const errors = []
      if (!this.$v.form.theme.$dirty) return errors
      if (!this.$v.form.theme.required) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs.theme?.$el)
      }
      return errors
    },
    subThemeErrors () {
      const errors = []
      if (!this.$v.form.subTheme.$dirty) return errors
      if (!this.$v.form.subTheme.required) {
        errors.push('Обязательное поле')
        if (this.$refs.subTheme) {
          this.$Global.scrollToElem(this.$refs.subTheme?.$el)
        }
      }
      return errors
    },
    descriptionErrors () {
      const errors = []
      if (!this.$v.form.description.$dirty) return errors
      if (!this.$v.form.description.required) {
        errors.push('Обязательное поле')
        this.$Global.scrollToElem(this.$refs.description?.$el)
      }
      return errors
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            files: 'fileId'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files' && Array.isArray(this.preset[key])) {
                this.$set(this.form, adaperMap[key], this.preset[key].map(file => file.id))
              }
            } else {
              if (key === 'theme' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key]?.id || null)
              } else if (key === 'subTheme' && this.preset[key]) {
                this.$set(this.form, key, this.preset[key]?.id || null)
              } else {
                this.$set(this.form, key, this.preset[key])
              }
            }
          }
          if (this.formType === 'create' && this.defaultFormFields?.description) {
            this.$set(this.form, 'description', `Технические данные:
            ${this.defaultFormFields.description}`)
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.log('handleSubmit ', this.$v)
      // console.log('$invalid ', this.$v.form.$invalid)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const formData = this.form

        if (!formData.theme) {
          formData.theme = null
        }
        if (!formData.subTheme) {
          formData.subTheme = null
        }

        const request = this.formType === 'create'
          ? API.store(formData)
          : API.update(this.targetId, formData)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            this.$Global.YandexMetrika?.reach(this.$Global.YandexMetrika.ReachEventId.SupportTaskCreate, { taskId: data.data?.id })
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
