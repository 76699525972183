/*eslint-disable */
/* Сгенерирован скритом: utils/permissionRules/fetchNamespaces.js */
export default {
    "AppServicePermissionRulesACCOUNTINGActManagementRule": "App\\Service\\Permission\\Rules\\ACCOUNTING\\ActManagementRule",
    "AppServicePermissionRulesACCOUNTINGBuyNbConfirmCommissionRule": "App\\Service\\Permission\\Rules\\ACCOUNTING\\BuyNbConfirmCommissionRule",
    "AppServicePermissionRulesACCOUNTINGUserCalculationManagementRule": "App\\Service\\Permission\\Rules\\ACCOUNTING\\UserCalculationManagementRule",
    "AppServicePermissionRulesSITECompanySiteCompanyRule": "App\\Service\\Permission\\Rules\\SITE\\Company\\SiteCompanyRule",
    "AppServicePermissionRulesSITEContentSiteArticlesRule": "App\\Service\\Permission\\Rules\\SITE\\Content\\SiteArticlesRule",
    "AppServicePermissionRulesSITEContentSiteCatalogSeoPageServiceRule": "App\\Service\\Permission\\Rules\\SITE\\Content\\SiteCatalogSeoPageServiceRule",
    "AppServicePermissionRulesSITEContentSiteContentRule": "App\\Service\\Permission\\Rules\\SITE\\Content\\SiteContentRule",
    "AppServicePermissionRulesSITEContentSiteFaqRule": "App\\Service\\Permission\\Rules\\SITE\\Content\\SiteFaqRule",
    "AppServicePermissionRulesSITEContentSiteLegalServiceRule": "App\\Service\\Permission\\Rules\\SITE\\Content\\SiteLegalServiceRule",
    "AppServicePermissionRulesSITEContentSiteMortgageSeoPageServiceRule": "App\\Service\\Permission\\Rules\\SITE\\Content\\SiteMortgageSeoPageServiceRule",
    "AppServicePermissionRulesSITEDocumentationDocumentationRule": "App\\Service\\Permission\\Rules\\SITE\\Documentation\\DocumentationRule",
    "AppServicePermissionRulesSITEReportsReportFormStatistic": "App\\Service\\Permission\\Rules\\SITE\\Reports\\ReportFormStatistic",
    "AppServicePermissionRulesSITEReviewCreateReviewRule": "App\\Service\\Permission\\Rules\\SITE\\Review\\CreateReviewRule",
    "AppServicePermissionRulesSITEReviewDeleteReviewRule": "App\\Service\\Permission\\Rules\\SITE\\Review\\DeleteReviewRule",
    "AppServicePermissionRulesSITEReviewReadReviewRule": "App\\Service\\Permission\\Rules\\SITE\\Review\\ReadReviewRule",
    "AppServicePermissionRulesSITEReviewUpdateReviewRule": "App\\Service\\Permission\\Rules\\SITE\\Review\\UpdateReviewRule",
    "AppServicePermissionRulesAuthReportsReportUserBonusTransactions": "App\\Service\\Permission\\Rules\\Auth\\Reports\\ReportUserBonusTransactions",
    "AppServicePermissionRulesAuthReportsReportUserOnSite": "App\\Service\\Permission\\Rules\\Auth\\Reports\\ReportUserOnSite",
    "AppServicePermissionRulesAuthRulesEditRulesRule": "App\\Service\\Permission\\Rules\\Auth\\Rules\\EditRulesRule",
    "AppServicePermissionRulesAuthRulesEditStructurePositionRule": "App\\Service\\Permission\\Rules\\Auth\\Rules\\EditStructurePositionRule",
    "AppServicePermissionRulesAuthRulesReadRulesRule": "App\\Service\\Permission\\Rules\\Auth\\Rules\\ReadRulesRule",
    "AppServicePermissionRulesAuthStructureChangeStructureRule": "App\\Service\\Permission\\Rules\\Auth\\Structure\\ChangeStructureRule",
    "AppServicePermissionRulesAuthStructureOfficeCreateOfficeRule": "App\\Service\\Permission\\Rules\\Auth\\StructureOffice\\CreateOfficeRule",
    "AppServicePermissionRulesAuthStructureOfficeUpdateOfficeRule": "App\\Service\\Permission\\Rules\\Auth\\StructureOffice\\UpdateOfficeRule",
    "AppServicePermissionRulesCOURSECourseEditorRule": "App\\Service\\Permission\\Rules\\COURSE\\CourseEditorRule",
    "AppServicePermissionRulesCRMAccountingCreateAccountingCommissionRule": "App\\Service\\Permission\\Rules\\CRM\\Accounting\\CreateAccountingCommissionRule",
    "AppServicePermissionRulesCRMAccountingDeleteAccountingCommissionRule": "App\\Service\\Permission\\Rules\\CRM\\Accounting\\DeleteAccountingCommissionRule",
    "AppServicePermissionRulesCRMAccountingReadAccountingCommissionRule": "App\\Service\\Permission\\Rules\\CRM\\Accounting\\ReadAccountingCommissionRule",
    "AppServicePermissionRulesCRMAccountingReportsReportTransactionRule": "App\\Service\\Permission\\Rules\\CRM\\Accounting\\Reports\\ReportTransactionRule",
    "AppServicePermissionRulesCRMAccountingUpdateAccountingCommissionRule": "App\\Service\\Permission\\Rules\\CRM\\Accounting\\UpdateAccountingCommissionRule",
    "AppServicePermissionRulesCRMAdvertisingPhonesEditPhoneRule": "App\\Service\\Permission\\Rules\\CRM\\Advertising\\Phones\\EditPhoneRule",
    "AppServicePermissionRulesCRMAdvertisingPhonesReadPhoneRule": "App\\Service\\Permission\\Rules\\CRM\\Advertising\\Phones\\ReadPhoneRule",
    "AppServicePermissionRulesCRMNewsEditNewsRule": "App\\Service\\Permission\\Rules\\CRM\\News\\EditNewsRule",
    "AppServicePermissionRulesCRMCompanyDeveloperEditCompanyDeveloperRule": "App\\Service\\Permission\\Rules\\CRM\\Company\\Developer\\EditCompanyDeveloperRule",
    "AppServicePermissionRulesCRMCompanyDistributionChangeComplexRule": "App\\Service\\Permission\\Rules\\CRM\\Company\\DistributionChangeComplexRule",
    "AppServicePermissionRulesCRMCompanyEditBankInsuranceCommissionRule": "App\\Service\\Permission\\Rules\\CRM\\Company\\EditBankInsuranceCommissionRule",
    "AppServicePermissionRulesCRMCompanyEditCompanyRule": "App\\Service\\Permission\\Rules\\CRM\\Company\\EditCompanyRule",
    "AppServicePermissionRulesCRMDealsContractContractDealTypeArchiveRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\Contract\\ContractDealTypeArchiveRule",
    "AppServicePermissionRulesCRMDealsContractContractDealTypeToOfficeRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\Contract\\ContractDealTypeToOfficeRule",
    "AppServicePermissionRulesCRMDealsCreateDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\CreateDealRule",
    "AppServicePermissionRulesCRMDealsDeactivatedRealtorDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\DeactivatedRealtorDealRule",
    "AppServicePermissionRulesCRMDealsDealBounceHandingRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\DealBounceHandingRule",
    "AppServicePermissionRulesCRMDealsDealClientRefusalRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\DealClientRefusalRule",
    "AppServicePermissionRulesCRMDealsDealDistributeEditUserList": "App\\Service\\Permission\\Rules\\CRM\\Deals\\DealDistributeEditUserList",
    "AppServicePermissionRulesCRMDealsDealDistributeSetUser": "App\\Service\\Permission\\Rules\\CRM\\Deals\\DealDistributeSetUser",
    "AppServicePermissionRulesCRMDealsDeleteDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\DeleteDealRule",
    "AppServicePermissionRulesCRMDealsEditContractRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\EditContractRule",
    "AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultAdminRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\Nb\\DealNbCompanyPeriodResultAdminRule",
    "AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultManagementActRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\Nb\\DealNbCompanyPeriodResultManagementActRule",
    "AppServicePermissionRulesCRMDealsNbDealNbCompanyPeriodResultManagementRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\Nb\\DealNbCompanyPeriodResultManagementRule",
    "AppServicePermissionRulesCRMDealsNbDealNbResultManagementRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\Nb\\DealNbResultManagementRule",
    "AppServicePermissionRulesCRMDealsReadDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\ReadDealRule",
    "AppServicePermissionRulesCRMDealsReportDealBounceHandlingRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\ReportDealBounceHandlingRule",
    "AppServicePermissionRulesCRMDealsReportDealDistributionProcessRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\ReportDealDistributionProcessRule",
    "AppServicePermissionRulesCRMDealsReportHungDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\ReportHungDealRule",
    "AppServicePermissionRulesCRMDealsStatusPerformedFromRefusalDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\StatusPerformedFromRefusalDealRule",
    "AppServicePermissionRulesCRMDealsStatusPerformedFromSuccessDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\StatusPerformedFromSuccessDealRule",
    "AppServicePermissionRulesCRMDealsUpdateDealRule": "App\\Service\\Permission\\Rules\\CRM\\Deals\\UpdateDealRule",
    "AppServicePermissionRulesCRMDocumentDeleteDocumentRule": "App\\Service\\Permission\\Rules\\CRM\\Document\\DeleteDocumentRule",
    "AppServicePermissionRulesCRMDocumentReadDocumentRule": "App\\Service\\Permission\\Rules\\CRM\\Document\\ReadDocumentRule",
    "AppServicePermissionRulesCRMDocumentUpdateDocumentRule": "App\\Service\\Permission\\Rules\\CRM\\Document\\UpdateDocumentRule",
    "AppServicePermissionRulesCRMLeadsCreateLeadRule": "App\\Service\\Permission\\Rules\\CRM\\Leads\\CreateLeadRule",
    "AppServicePermissionRulesCRMLeadsDeleteLeadRule": "App\\Service\\Permission\\Rules\\CRM\\Leads\\DeleteLeadRule",
    "AppServicePermissionRulesCRMLeadsIntegrationLeadExclusiveRule": "App\\Service\\Permission\\Rules\\CRM\\Leads\\IntegrationLeadExclusiveRule",
    "AppServicePermissionRulesCRMLeadsReadLeadRule": "App\\Service\\Permission\\Rules\\CRM\\Leads\\ReadLeadRule",
    "AppServicePermissionRulesCRMLeadsUpdateLeadRule": "App\\Service\\Permission\\Rules\\CRM\\Leads\\UpdateLeadRule",
    "AppServicePermissionRulesCRMMortgageProgramsCreateProgramRule": "App\\Service\\Permission\\Rules\\CRM\\Mortgage\\Programs\\CreateProgramRule",
    "AppServicePermissionRulesCRMMortgageProgramsDeleteProgramRule": "App\\Service\\Permission\\Rules\\CRM\\Mortgage\\Programs\\DeleteProgramRule",
    "AppServicePermissionRulesCRMMortgageProgramsReadProgramRule": "App\\Service\\Permission\\Rules\\CRM\\Mortgage\\Programs\\ReadProgramRule",
    "AppServicePermissionRulesCRMMortgageProgramsUpdateProgramRule": "App\\Service\\Permission\\Rules\\CRM\\Mortgage\\Programs\\UpdateProgramRule",
    "AppServicePermissionRulesCRMPersonContactsReadContactRule": "App\\Service\\Permission\\Rules\\CRM\\PersonContacts\\ReadContactRule",
    "AppServicePermissionRulesCRMToolsStatementReportGeneratorRule": "App\\Service\\Permission\\Rules\\CRM\\Tools\\StatementReportGeneratorRule",
    "AppServicePermissionRulesCRMUsersCreateUserRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\CreateUserRule",
    "AppServicePermissionRulesCRMUsersDeleteUserRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\DeleteUserRule",
    "AppServicePermissionRulesCRMUsersDisplayDeactivatedUserRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\DisplayDeactivatedUserRule",
    "AppServicePermissionRulesCRMUsersReadUserRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\ReadUserRule",
    "AppServicePermissionRulesCRMUsersUpdateUserAboutRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\UpdateUserAboutRule",
    "AppServicePermissionRulesCRMUsersUpdateUserRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\UpdateUserRule",
    "AppServicePermissionRulesCRMUsersUpdateUserSiteRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\UpdateUserSiteRule",
    "AppServicePermissionRulesCRMUsersUpdateUserSocialMediaRule": "App\\Service\\Permission\\Rules\\CRM\\Users\\UpdateUserSocialMediaRule",
    "AppServicePermissionRulesCVDocumentDocumentAnalyzeRule": "App\\Service\\Permission\\Rules\\CV\\Document\\DocumentAnalyzeRule",
    "AppServicePermissionRulesDOCChangeLogManagementChangeLogRule": "App\\Service\\Permission\\Rules\\DOC\\ChangeLog\\ManagementChangeLogRule",
    "AppServicePermissionRulesDOCChangeLogReadChangeLogRule": "App\\Service\\Permission\\Rules\\DOC\\ChangeLog\\ReadChangeLogRule",
    "AppServicePermissionRulesDOCManagementBaseKnowledgeRule": "App\\Service\\Permission\\Rules\\DOC\\ManagementBaseKnowledgeRule",
    "AppServicePermissionRulesDOCReadDocumentationRule": "App\\Service\\Permission\\Rules\\DOC\\ReadDocumentationRule",
    "AppServicePermissionRulesDocumentCheckKonturPersonCheckRule": "App\\Service\\Permission\\Rules\\DocumentCheck\\KonturPersonCheckRule",
    "AppServicePermissionRulesLANDINGLandingAdminRule": "App\\Service\\Permission\\Rules\\LANDING\\LandingAdminRule",
    "AppServicePermissionRulesMESSENGEREditPlatformAccountRule": "App\\Service\\Permission\\Rules\\MESSENGER\\EditPlatformAccountRule",
    "AppServicePermissionRulesMESSENGERExternalAccountEditExternalAccountRule": "App\\Service\\Permission\\Rules\\MESSENGER\\ExternalAccount\\EditExternalAccountRule",
    "AppServicePermissionRulesMESSENGERReportsReportNoticeClientSMS": "App\\Service\\Permission\\Rules\\MESSENGER\\Reports\\ReportNoticeClientSMS",
    "AppServicePermissionRulesMESSENGERReportsReportNoticeRealtorTg": "App\\Service\\Permission\\Rules\\MESSENGER\\Reports\\ReportNoticeRealtorTg",
    "AppServicePermissionRulesOBJECTSAdvertingEditComplexPlatformRule": "App\\Service\\Permission\\Rules\\OBJECTS\\Adverting\\EditComplexPlatformRule",
    "AppServicePermissionRulesOBJECTSAdvertingUploadAdsSettingsRule": "App\\Service\\Permission\\Rules\\OBJECTS\\Adverting\\UploadAdsSettingsRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderBuilder": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\Builder",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderBuilderNews": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\BuilderNews",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderBuilderStock": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\BuilderStock",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileManagementBuilderFileRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\File\\ManagementBuilderFileRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderFileReadBuilderNBFileRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\File\\ReadBuilderNBFileRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderReportStock": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\ReportStock",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderRequestBuilderRequestDistributionSettingsRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\Request\\BuilderRequestDistributionSettingsRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderRequestBuilderRequestManagementRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\Request\\BuilderRequestManagementRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsBuilderRequestBuilderRequestSettingsRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Builder\\Request\\BuilderRequestSettingsRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsComplexCreateComplexNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Complex\\CreateComplexNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsComplexDeleteComplexNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Complex\\DeleteComplexNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsComplexReadComplexNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Complex\\ReadComplexNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsComplexUpdateComplexNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Complex\\UpdateComplexNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsNewsCreateNewsNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\News\\CreateNewsNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsNewsDeleteNewsNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\News\\DeleteNewsNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsNewsReadNewsNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\News\\ReadNewsNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsNewsUpdateNewsNBRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\News\\UpdateNewsNBRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsRealtyEditRealtyNbRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Realty\\EditRealtyNbRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsRealtyEditRealtyRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Realty\\EditRealtyRule",
    "AppServicePermissionRulesOBJECTSNewBuildingsSeoEditNbSeoRule": "App\\Service\\Permission\\Rules\\OBJECTS\\NewBuildings\\Seo\\EditNbSeoRule",
    "AppServicePermissionRulesPASSPORTCheckTerroristRule": "App\\Service\\Permission\\Rules\\PASSPORT\\CheckTerroristRule",
    "AppServicePermissionRulesREVIEWDealReviewManagementRule": "App\\Service\\Permission\\Rules\\REVIEW\\DealReviewManagementRule",
    "AppServicePermissionRulesREVIEWDealReviewStatisticViewRule": "App\\Service\\Permission\\Rules\\REVIEW\\DealReviewStatisticViewRule",
    "AppServicePermissionRulesREVIEWReportBuyNbMinMaxPrice": "App\\Service\\Permission\\Rules\\REVIEW\\ReportBuyNbMinMaxPrice",
    "AppServicePermissionRulesREVIEWSiteReviewManagementRule": "App\\Service\\Permission\\Rules\\REVIEW\\SiteReviewManagementRule",
    "AppServicePermissionRulesRMPricePriceReportRule": "App\\Service\\Permission\\Rules\\RM\\Price\\PriceReportRule",
    "AppServicePermissionRulesRMReportLandAdsReportRule": "App\\Service\\Permission\\Rules\\RM\\Report\\LandAdsReportRule",
    "AppServicePermissionRulesRMReportReportEstimationRequestCountByUser": "App\\Service\\Permission\\Rules\\RM\\Report\\ReportEstimationRequestCountByUser",
    "AppServicePermissionRulesRMReportReportParsedAdsFromOwner": "App\\Service\\Permission\\Rules\\RM\\Report\\ReportParsedAdsFromOwner",
    "AppServicePermissionRulesROIReportsReportCalllsRule": "App\\Service\\Permission\\Rules\\ROI\\Reports\\ReportCalllsRule",
    "AppServicePermissionRulesROIReportsReportDealMortgage": "App\\Service\\Permission\\Rules\\ROI\\Reports\\ReportDealMortgage",
    "AppServicePermissionRulesROIReportsReportDealsRule": "App\\Service\\Permission\\Rules\\ROI\\Reports\\ReportDealsRule",
    "AppServicePermissionRulesROIReportsReportRatingsRule": "App\\Service\\Permission\\Rules\\ROI\\Reports\\ReportRatingsRule",
    "AppServicePermissionRulesROIReportsReportTopRule": "App\\Service\\Permission\\Rules\\ROI\\Reports\\ReportTopRule",
    "AppServicePermissionRulesROIReportsReportUTM": "App\\Service\\Permission\\Rules\\ROI\\Reports\\ReportUTM",
    "AppServicePermissionRulesRootRule": "App\\Service\\Permission\\Rules\\RootRule",
    "AppServicePermissionRulesServerStatusRule": "App\\Service\\Permission\\Rules\\ServerStatusRule",
    "AppServicePermissionRulesShowManagementPanel": "App\\Service\\Permission\\Rules\\ShowManagementPanel",
    "AppServicePermissionRulesTELEPHONYCallsWithClientRule": "App\\Service\\Permission\\Rules\\TELEPHONY\\CallsWithClientRule",
    "AppServicePermissionRulesTELEPHONYCallsWithoutClientRule": "App\\Service\\Permission\\Rules\\TELEPHONY\\CallsWithoutClientRule"
}