<template>
  <TwPanelContent>
    <TwPanelContentBlock
      class="tw-flex-1 tw-mb-[6px]"
      no-data-text="Нет добавленых документов"
    >
      <template #title>
        <div>
          Набор загруженных документов клиента
        </div>
      </template>
      <template #action>
        <div :title="editDocIndex !== null ? 'Завершите редактирование других документов для создания нового' : ''">
          <button
            :disabled="editDocIndex !== null || loading"
            class="tw-font-manrope tw-font-bold tw-uppercase tw-text-main-xs tw-text-white tw-bg-an-flat-green-btn-bg tw-rounded-sm tw-whitespace-nowrap tw-px-[20px] tw-py-[6px] enabled:hover:tw-bg-an-flat-green-btn-bg-active enabled:hover:tw-shadow disabled:tw-opacity-50"
            @click="addNewDocument"
          >
            Добавить документ
          </button>
        </div>
      </template>
      <div
        v-if="loading"
        class="person-documents-expansion tw-text-center tw-flex"
      >
        <TwCircleLoader class="tw-m-auto" />
      </div>
      <div
        v-else-if="list.length"
        class="person-documents-expansion"
      >
        <TwSpoiler
          v-for="(item, index) in list"
          :key="index"
          :pre-opened="panel.includes(index)"
          :has-shadow="true"
          shadow-style="box-shadow: 0 1px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .12);"
          title-classes="tw-px-[24px] tw-py-[16px] tw-font-semibold tw-text-white tw-text-main-sm tw-w-full"
          content-classes="tw-px-[24px] tw-py-[16px] tw-bg-white tw-w-full"
          title-variant="block"
          :class="panel.includes(index) ? 'tw-bg-an-flat-blue-dark-2/80':'tw-bg-an-flat-blue-dark-2'"
          @onOpen="panelOnOpen(index, $event)"
        >
          <template #title>
            <div class="tw-font-semibold">
              <div>
                {{ (item.type && item.type.label) || ('Новый документ') }}
                <span
                  v-if="!item.id"
                  class="tw-text-an-flat-red-light"
                >
                  (Не сохранён)
                </span>
              </div>
            </div>
          </template>
          <div>
            <div
              style="position:relative;height: 35px"
              :title="editDocIndex !== null && editDocIndex !== index ? 'Завершите предыдущие изменения чтобы отредактировать этот документ' : ''"
            >
              <TwPanelActionButton
                style="position:absolute;right: 0"
                :disabled="editDocIndex !== null && editDocIndex !== index"
                :color="editDocIndex !== null && editDocIndex === index ? 'red':'blue'"
                @click.prevent="editDocument(index)"
              >
                {{ editDocIndex !== null && editDocIndex === index ? 'отменить':'изменить' }}
              </TwPanelActionButton>
            </div>
            <PersonDocumentShow
              v-if="editDocIndex !== index"
              :info="item"
            />
            <PersonDocumentForm
              v-else
              :id="item.id"
              :key="index+'- doc - ' + item.id"
              :exist-doc-types="existDocTypes"
              :person-id="personId"
              @create="getDocuments"
              @update="getDocuments"
            />
          </div>
        </TwSpoiler>
      </div>
    </TwPanelContentBlock>
  </TwPanelContent>
</template>

<script>
import PersonDocumentForm from '@/views/Person/documents/PersonDocumentForm'
import API from '@/Api/Crm/Person/document'
import PersonDocumentShow from '@/views/Person/documents/PersonDocumentsShow'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwSpoiler from '@/components/tw-ui/other/TwSpoiler.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'PersonDocumentsTab',
  components: {
    TwCircleLoader,
    TwPanelActionButton,
    TwSpoiler,
    TwPanelContentBlock,
    TwPanelContent,
    PersonDocumentShow,
    PersonDocumentForm
  },
  props: {
    personId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      list: [],
      panel: [],

      editDocIndex: null
    }
  },
  computed: {
    existDocTypes () {
      return (Array.isArray(this.list) && this.list.map(doc => doc?.type?.value)) || []
    }
  },
  watch: {
    personId (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getDocuments()
      }
    }
  },
  mounted () {
    this.getDocuments()
  },
  methods: {
    panelOnOpen (index, eventValue) {
      if (eventValue) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.findIndex(i => i === index), 1)
      }
    },
    editDocument (index) {
      this.editDocIndex = this.editDocIndex !== index ? index : null
      console.log('editDocument', index, this.editDocIndex)
      if (!this.list[index]?.id) {
        this.list.splice(index, 1)
        this.panel = []
      }
    },
    addNewDocument () {
      this.list.unshift({ id: 0 })
      this.panel.push(0)
      this.editDocIndex = 0
    },
    getDocuments () {
      this.loading = true
      API.getListByPersonId({ personId: this.personId })
        .then(({ data }) => {
          console.warn(this.$options.name, data)
          this.list = data.data || []
          this.panel = []
        })
        .catch((error) => {
          console.error(this.$options.name, error)
        })
        .finally(() => {
          this.loading = false
          this.editDocIndex = null
        })
    }
  }
}
</script>

<style scoped>

</style>
