<template>
  <div
    v-cloak
    v-if="info"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView v-if="false">
      <template #title>
        Адрес электронного профиля
      </template>
      <div v-if="info.slug">
        <a
          target="_blank"
          :href="`${siteUrl}/employees/${info.slug}`"
        >
          {{ `${siteUrl}/employees/${info.slug}` }}
        </a>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView v-if="info.publish">
      <template #title>
        Адрес электронной визитки
      </template>
      <div v-if="info.slug">
        <a
          target="_blank"
          :href="`${siteUrl}/card/${info.slug}`"
        >
          {{ `${siteUrl}/card/${info.slug}` }}
        </a>
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Опубликован на сайте
      </template>
      <div>
        {{ info.publish ? 'Да' : 'Нет' }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Кол-во текстовых отзывов
      </template>
      <div>
        {{ info.reviewsCount || 0 }}
      </div>
    </TwPanelContentBlockFieldView>
    <TwPanelContentBlockFieldView>
      <template #title>
        Кол-во видео отзывов
      </template>
      <div>
        {{ info.videoReviewsCount || 0 }}
      </div>
    </TwPanelContentBlockFieldView>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Не заполнено
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import { SERVICES } from '@/Api/Services'

export default {
  name: 'EmployeeSiteShow',
  components: { TwPanelContentBlockFieldView },
  props: {
    info: {
      default: () => {
        return {}
      }
    }
  },
  computed: {
    siteUrl () {
      return SERVICES.SITE.host
    }
  }
}
</script>
