<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    no-data-text="Нет привязанных актов"
  >
    <template #title>
      <div>
        Начисления
      </div>
    </template>
    <div v-if="Array.isArray(info) && info.length">
      <div
        v-for="item in info"
        :key="item.id"
        class="tw-rounded-sm tw-flex tw-px-[16px] tw-py-[8px] tw-gap-[12px] tw-items-center odd:tw-bg-an-flat-bg-main/50"
      >
        <div class="tw-flex-1">
          <ViewPanelLink
            :id="item.id"
            label="Начисление"
            component="accounting-user-calc-show"
          >
            Начисление № <b>{{ item.id }}</b> от {{ $Global.DateFormat.DateShort(item.createdAt, true) }}
          </ViewPanelLink>
        </div>
        <div class="tw-text-right">
          <div
            class="tw-font-medium tw-text-main-xs2"
          >
            <span
              v-if="item.payStatus && item.payStatus.value"
              :class="item.payStatus.value === 'payed' ? 'tw-text-an-flat-green':'tw-bg-an-flat-red-main'"
            >
              {{ item.payStatus.label }}
            </span>
            <span
              v-else
              class="tw-text-an-flat-gray-4"
            >
              Не выплачено
            </span>
          </div>
          <div class="tw-font-semibold tw-text-main-lg">
            {{ (item.commission || 0).toLocaleString() }} <Rouble />
          </div>
        </div>
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import Rouble from '@/components/symbol/Rouble.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  components: {
    Rouble,
    ViewPanelLink,
    TwPanelContentBlock
  },
  props: {
    info: {
      type: Array,
      default: null
    }
  }
}
</script>
