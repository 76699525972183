import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'
import { AuthAxiosDefaultConfig } from '@/Api/Email'

const path = '/email/mail'

export default {
  getList (param?: object): Promise<AxiosResponse> {
    const $config: any = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}`, $config)
  },

  getListByEntity (param?: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig,
      params: param
    }
    return HTTP.get(`${path}/by-entity`, $config)
  },

  create (): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/create`, $config)
  },

  edit (id: number): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },

  update (id: number, data: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}`, data, $config)
  },

  show (id: number): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}`, $config)
  },

  delete (id: number): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.delete(`${path}/${id}`, $config)
  },

  send_draft (id: string, data?: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}/send_draft`, data || {}, $config)
  },

  reply (id: string, data = {}): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.put(`${path}/${id}/reply`, data, $config)
  },

  store (data: object): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.post(`${path}`, data, $config)
  },

  children (id: any): Promise<AxiosResponse> {
    const $config = {
      ...AuthAxiosDefaultConfig
    }
    return HTTP.get(`${path}/${id}/children`, $config)
  }
}
