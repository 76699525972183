import { RouteKeys } from '@/router/types'
import dictPermissionRules from '@/Permissions/dictRules'
import service from './service'
import section from './section'

export default {
  path: `${RouteKeys.siteServices}`,
  name: RouteKeys.siteServices,
  redirect: { name: `${RouteKeys.siteServiceItem}-list` },
  meta: { name: 'Юридические услуги', icon: 'admin-service', isAdmin: false, ruleNamespace: dictPermissionRules.AppServicePermissionRulesSITEContentSiteLegalServiceRule },
  component: () => import('@/views/Site/Admin/Index.vue'),
  children: [
    service,
    section
  ]
}
