<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    no-data-text="Не указано"
  >
    <template #title>
      <div>
        Отказ выплаты
      </div>
    </template>
    <template #action>
      <TwPanelContentBlockActionButton
        :disabled="formProcessing"
        :type="hasEdit ? '':'edit'"
        @click.prevent="hasEdit = !hasEdit"
      >
        {{ hasEdit ? 'отмена':'изменить' }}
      </TwPanelContentBlockActionButton>
      <TwPanelContentBlockActionButton
        v-if="hasEdit"
        :disabled="formProcessing"
        type="delete"
        @click.prevent="deleteRefusal"
      >
        удалить
      </TwPanelContentBlockActionButton>
    </template>
    <div
      v-if="info && !hasEdit"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <TwPanelContentBlockFieldView no-data-text="Нет примечания">
        <template #title>
          Причина отказа
        </template>
        <div v-if="info.comment">
          {{ info.comment }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Создал
        </template>
        <div v-if="info.creatorId">
          <ViewPanelLink
            :id="info.creatorId"
            label="Сотрудник"
            component="employee-show"
          >
            <EmployeeName
              :id="info.creatorId"
              is-avatar
            />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.updateUserId && info.creatorId !== info.updateUserId">
        <template #title>
          Изменил
        </template>
        <div>
          <ViewPanelLink
            :id="info.updateUserId"
            label="Сотрудник"
            component="employee-show"
          >
            <EmployeeName
              :id="info.updateUserId"
              is-avatar
            />
          </ViewPanelLink>
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView no-data-text="Нет данных">
        <template #title>
          Дата изменения
        </template>
        <div>
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt || info.createdAt) }}
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div
      v-else-if="hasEdit"
      class="tw-flex tw-flex-col tw-gap-[12px]"
    >
      <TwTextarea
        v-model="form.comment"
        rows="3"
        label="Причина отказа"
        placeholder="Кратко опишите ситуацию с отказом"
        maxlength="250"
        show-length
        resize="y"
        persistent-label
        :disabled="formProcessing"
        :show-label="true"
      />
      <div
        v-if="serverErrorMessage"
        class="tw-text-an-flat-red-main"
      >
        {{ serverErrorMessage }}
      </div>

      <TwCardSimpleFormSaveBlock
        :loading="formProcessing"
        :cancel-confirm="true"
        :is-sticky="false"
        :disable-success="formProcessing"
        :disable-cancel="formProcessing"
        :visible="true"
        :dense="true"
        @success="handleSubmit"
        @cancel="handleCancel"
      />
    </div>
  </TwPanelContentBlock>
</template>

<script>
import API from '@/Api/Accounting/BuyNb/Result/PaymentRefusal'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import TwTextarea from '@/components/tw-ui/ui-kit/input/TwTextarea.vue'

export default {
  components: {
    ViewPanelLink,
    EmployeeName,
    TwTextarea,
    TwPanelContentBlockFieldView,
    TwPanelContentBlockActionButton,
    TwPanelContentBlock,
    TwCardSimpleFormSaveBlock
  },
  props: {
    resultId: {
      type: Number,
      required: true
    },
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      hasEdit: false,
      formProcessing: false,
      form: {
        comment: this.info?.comment || null
      },
      serverErrorMessage: null
    }
  },
  methods: {
    refreshParent () {
      this.hasEdit = false
      this.$emit('refresh')
    },
    handleCancel () {
      this.$emit('cancel')
    },
    handleSubmit () {
      this.serverErrorMessage = null
      this.formProcessing = true
      const request = this.info?.id ? API.update : API.store
      request(this.resultId, this.form).then(() => {
        this.refreshParent()
      }).catch((error) => {
        this.serverErrorMessage = error.serverErrorMessage
      }).finally(() => {
        this.formProcessing = false
      })
    },
    deleteRefusal () {
      if (confirm('Вы уверены что хотите удалить запись?')) {
        this.serverErrorMessage = null
        this.formProcessing = true
        API.delete(this.resultId).then(() => {
          this.refreshParent()
        }).catch((error) => {
          this.serverErrorMessage = error.serverErrorMessage
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
