import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { OsState } from '../types'
import osNbSelectionAPI from '@/Api/ObjectStorage/Realty/Selection/NewBuilding'
import SelectionFilterAPI from '@/Api/Crm/Deal/BuyNb/SelectionFilter'
import Vue from 'vue'

const namespaced = true

let listFetchController: AbortController = new AbortController()

export const nbSelection: Module<OsState, RootState> = {
  namespaced,
  state: {
    loadingFilters: false,
    loadingList: false,
    usageFilters: {}, // Фильтры которые используются при получении списка
    filters: null, // структура фильтров и справочники с сервера
    sortingList: {}, // Инструкции полей сортировки подбора
    data: {
      items: [],
      additionalInfo: {},
      meta: {}
    }
  },
  actions: {
    // Получение списка инструкций и полей для фильтров
    fetchFilters: async function ({ state, commit }, payload = {}) {
      // console.warn('fetchFilters: ', payload)
      if (state.loading) {
        return
      }
      commit('SET_LOADING_FILTERS', true)
      await osNbSelectionAPI.filters()
        .then(({ data: result }: any) => {
          // console.log('NbSelection fetchFilters', result)
          commit('SET_FILTERS', result || {})
        })
        .catch((error: any) => {
          console.error(error)
        })
      if (payload?.dealId) {
        await SelectionFilterAPI.show(payload.dealId)
          .then(({ data: result }: any) => {
            // console.log('SelectionFilterAPI fetchFilters', result.data, state.filters.data)
            if (result?.data) {
              for (const fieldKey of Object.keys(state.filters.data)) {
                // console.log('SelectionFilterAPI fetchFilters fieldKey', fieldKey, state.filters.data[fieldKey], result.data[fieldKey])
                // Нужно сверить сохраненные фильтры с изначальными и внести изменение в стейт
                if (result.data[fieldKey] !== undefined && result.data[fieldKey] !== null) {
                  commit('SET_FILTER_VALUE', { fieldKey, fieldValue: result.data[fieldKey] })
                }
              }
            }
          })
          .catch((error: any) => {
            console.error(error)
          })
      }
      commit('SET_LOADING_FILTERS', false)
    },
    // Сохранение списка полей для фильтров к сделке
    saveFilters: async function ({ state, commit }, payload = {}) {
      // console.warn('saveFilters: ', payload, state.usageFilters)
      return new Promise((resolve, reject) => {
        if (payload?.dealId) {
          commit('SET_LOADING_FILTERS', true)
          const filtersToSave = { ...state.filters.data, ...{} }
          for (const fieldKey of Object.keys(state.usageFilters)) {
            filtersToSave[fieldKey] = state.usageFilters[fieldKey]
          }
          // console.warn('saveFilters filtersToSave: ', filtersToSave)
          SelectionFilterAPI.change(payload.dealId, filtersToSave || {})
            .then(({ data: result }: any) => {
              // console.log('SelectionFilterAPI saveFilters', result)
              resolve(result)
            })
            .catch((error: any) => {
              console.error(error)
              reject(error)
            })
            .finally(() => {
              commit('SET_LOADING_FILTERS', false)
            })
        } else {
          console.error('saveFilters payload dealId NOT FOUND', payload)
          reject(payload)
        }
      })
    },
    downloadPdf ({ getters }, filterParams = {}) {
      const sort = getters.currentSortingList?.find((i: any) => i.value)
      const requestParams = {
        ...filterParams,
        sortField: sort?.key || undefined,
        sortDirection: sort?.value || undefined
      }
      osNbSelectionAPI.selectionDownloadPdf({ ...requestParams })
    },
    // Получение списка найденных по фильтрам объектов
    fetchList: function ({ state, commit, getters }, payloadParams = {}) {
      const sort = getters.currentSortingList?.find((i: any) => i.value)
      const requestParams = {
        ...state.usageFilters,
        ...payloadParams,
        sortField: sort?.key || undefined,
        sortDirection: sort?.value || undefined
      }
      if (state.loadingList) {
        listFetchController.abort()
      }
      listFetchController = new AbortController()
      commit('SET_LOADING_LIST', true)
      return osNbSelectionAPI.search({ ...requestParams }, { signal: listFetchController.signal })
        .then(({ data }: any) => {
          // console.log('fetchList', data.data)
          commit('SET_LIST_DATA', {
            items: data.data || [],
            meta: data.meta || {},
            additionalInfo: data.additionalInfo || {},
            isAppend: !!payloadParams?.isAppend // передавать если нужно загрузить следующую страницу
          })
        })
        .catch((error: any) => {
          console.error(error)
          // TODO возможно понадобится показывать ошибки для этого воздать доп объект с состоянием ошибки
        })
        .finally(() => {
          commit('SET_LOADING_LIST', false)
        })
    }
  },
  mutations: {
    // Изменение сортировки
    SET_SORT_VALUE (state, payload) {
      if (state.sortingList[state.usageFilters?.responseFormat]) {
        state.sortingList[state.usageFilters.responseFormat].forEach((sortOption: any) => {
          if (sortOption.key === payload.key) {
            sortOption.value = payload.value === null ? 'asc' : (payload.value).toLowerCase() === 'asc' ? 'desc' : (payload.value).toLowerCase() === 'desc' ? null : 'asc'
            // console.warn(sortOption.key, sortOption.value)
          } else {
            sortOption.value = null
          }
        })
      }
    },
    // Изменение полей применяемых фильтров
    SET_FILTER_VALUE (state, { fieldKey, fieldValue = undefined }) {
      // console.log('SET_FILTER_VALUE', state.usageFilters, fieldKey, fieldValue)
      // Если назначаемое значение равно значению при инициализации - убираю поле из запроса
      if (state.filters?.data?.[fieldKey] === fieldValue) {
        Vue.delete(state.usageFilters, fieldKey)
      } else {
        Vue.set(state.usageFilters, fieldKey, fieldValue)
      }
    },
    // Сброс полей формы фильтрации
    RESET_FILTERS (state) {
      // console.log('RESET_FILTERS', state.usageFilters)
      // Поле представления(responseFormat) оставляю, оно нужно всегда
      Vue.set(state, 'usageFilters', {
        responseFormat: state.usageFilters?.responseFormat
      })
    },
    SET_LOADING_FILTERS (state, status) {
      // console.log('SET_LOADING_FILTERS', status)
      state.loadingFilters = !!status
    },
    SET_LOADING_LIST (state, status) {
      // console.log('SET_LOADING_LIST', status)
      state.loadingList = !!status
    },
    SET_FILTERS (state, payload) {
      // console.log('SET_FILTERS', payload)
      state.filters = payload
      state.sortingList = payload?.dictionary?.sortingList || {}
    },
    SET_LIST_DATA (state, { items, meta, additionalInfo, isAppend }) {
      // console.log('SET_LIST_DATA', { items, meta, isAppend })
      if (isAppend) {
        state.data.items = [
          ...state.data.items,
          ...items
        ]
      } else {
        state.data.items = items || []
      }
      state.data.meta = meta || {}
      state.data.additionalInfo = additionalInfo || {}
    }
  },
  getters: {
    usageFiltersFields (state: OsState): any {
      return state.usageFilters || {}
    },
    usageFiltersNotEmpty (state: OsState): any {
      return Object.keys(state.usageFilters)?.some((key) => {
        if (key === 'responseFormat') {
          return false
        }
        const val = state.usageFilters[key]
        console.warn(val, key)
        if (Array.isArray(val)) {
          return !!val.length
        }
        return !!val
      })
    },
    filtersDefaultFields (state: OsState): any {
      return state.filters?.data || {}
    },
    filtersObject (state: OsState): any {
      return state.filters
    },
    dataObject (state: OsState): any {
      return state.data
    },
    loadingFilters (state: OsState): any {
      return !!state.loadingFilters
    },
    loadingList (state: OsState): any {
      return !!state.loadingList
    },
    // Текущий объект полей сортировки подбора
    currentSortingList (state: OsState): any {
      if (state.sortingList && state.usageFilters?.responseFormat) {
        const targetArray = state.sortingList[state.usageFilters.responseFormat]
        return Array.isArray(targetArray) && targetArray.length ? targetArray : null
      }
      return null
    }
  }
}
