<template>
  <TwFlexCol>
    <TwFlexCell>
      <TwInputText
        v-model="fields.number"
        type="number"
        placeholder="СНИЛС номер"
        :persistent-label="true"
        :show-label="true"
        :error-text="$v.fields.number.$invalid ? $v.fields.number.errorValue : []"
        :dense="true"
      />
    </TwFlexCell>
  </TwFlexCol>
</template>

<script>
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import validateSnils from '@/plugins/helpers/validateSnils'

export default {
  name: 'SnilsType',
  components: {
    TwFlexCell,
    TwFlexCol,
    TwInputText
  },
  props: {
    // набор значений fields из формы
    formType: {},
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  validations () {
    return {
      fields: {
        number: {
          required: (v) => {
            const errors = {}
            validateSnils(v, errors) // валидный пример 71250586272
            if (v) {
              this.$v.fields.number.errorValue = errors.message || null
              return !errors.message
            } else {
              return true
            }
          }
        }
      }
    }
  },
  computed: {
    fields: {
      get () {
        return this.value
      }
    }
  }
}
</script>
