import { RouteKeys } from '@/router/types'

export default {
  path: `/${RouteKeys.training}`,
  name: RouteKeys.training,
  meta: { name: 'Обучение', icon: 'knowledge-base' },
  component: () => import('@/views/Training/TrainingPage.vue'),
  children: [
    {
      path: '/',
      name: `${RouteKeys.training}`,
      meta: { name: 'Список разделов', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/Training/Section/SectionList.vue'),
      props: true
    },
    {
      path: 'section/:id',
      name: `${RouteKeys.training}-section`,
      meta: { name: 'Список модулей', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/Training/Section/SectionShow.vue'),
      props: (route: any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'course/:courseId',
      name: `${RouteKeys.training}-course`,
      meta: { name: 'Список уроков', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/Training/Course/CourseShow.vue'),
      props: (route: any) => ({ courseId: Number(route.params.courseId) })
    },
    {
      path: 'course/:courseId/lesson/:lessonId',
      name: `${RouteKeys.training}-lesson`,
      meta: { name: 'Урок', icon: 'knowledge-base', hideInMenu: true },
      component: () => import('@/views/Training/Lesson/LessonView.vue'),
      props: (route: any) => ({ lessonId: Number(route.params.lessonId), courseId: Number(route.params.courseId), hasEdit: Boolean(route.params.hasEdit) })
    }
  ]
}
