<template>
  <div class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen">
    <template v-if="isCardContentVisible">
      <!--   ЗАГОЛОВОК   -->
      <TwPanelTitle>
        <template #left>
          <div class="tw-text-h4 tw-font-bold">
            <div
              v-if="isNew"
              class="title text-muted"
            >
              Новый
            </div>
            <div v-else-if="data">
              <span>Результат</span>
              <span class="tw-text-an-flat-disabled-text tw-font-medium"> # {{ data.id }}</span>
            </div>
            <div v-else>
              ~
            </div>
          </div>
        </template>
      </TwPanelTitle>

      <!--   ВКЛАДКИ   -->
      <TwPanelTabsFull
        v-if="data"
        v-model="currentTab"
        :items="tabList"
      >
        <template #content.main>
          <TwPanelContent>
            <div class="tw-flex-1">
              <TwPanelContentBlock class="tw-mb-[6px]">
                <template #title>
                  <div>
                    О результате
                  </div>
                </template>
                <ResultMainShow
                  :info="data"
                  :dictionary="dictionary"
                />
              </TwPanelContentBlock>
              <AccountingResultActRecordsBlock
                :info="otherData.actRecords"
              />
            </div>
            <div class="tw-flex-1">
              <AccountingResultConfirmBlock
                :result-id="data.id"
                :info="otherData.resultConfirm"
                @refresh="refresh"
              />
              <AccountingResultPartialPaymentBlock
                :result-id="data.id"
                :info="otherData.partialPayment"
                @refresh="refresh"
              />
              <AccountingResultPaymentRefusalBlock
                :result-id="data.id"
                :info="otherData.paymentRefusal"
                @refresh="refresh"
              />
              <AccountingResultUserCalculationsBlock
                :info="otherData.userCalculations"
                @refresh="refresh"
              />
            </div>
          </TwPanelContent>
        </template>
      </TwPanelTabsFull>
    </template>
    <!--
      Блок заполняющий пространство при отсутствуии контента
      для прижатия блока сохранения к нижней части страницы
    -->
    <div class="tw-flex tw-justify-center tw-items-center">
      <TwCircleLoader
        v-if="loading && !isCardContentVisible"
        width="50px"
        height="50px"
      />
      <div v-else-if="error">
        <PermissionDenied :text="error === 403 ? 'Недостаточно прав для просмотра!' : serverErrorMessage || 'Ошибка соединения - Обратитесь к администратору!'" />
      </div>
    </div>
    <div v-if="isAdmin">
      <TwSpoiler
        title-classes="tw-mb-[6px] tw-px-[15px] tw-py-[8px] tw-font-medium tw-bg-an-flat-gray-light tw-text-main-sm tw-w-full"
        content-classes="tw-w-full"
        title-variant="block"
      >
        <template #title>
          DEBUG isAdmin
        </template>
        <div class="">
          DATA:
          <JsonPretty :json="data" />
          OTHER:
          <JsonPretty :json="otherData" />
        </div>
      </TwSpoiler>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/Api/Accounting/BuyNb/Result'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import PermissionDenied from '@/views/PermissionDenied.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import JsonPretty from '@/components/development/JsonPretty.vue'
import TwSpoiler from '@/components/tw-ui/other/TwSpoiler.vue'
import AccountingResultConfirmBlock from './Confirm/AccountingResultConfirmBlock.vue'
import AccountingResultPartialPaymentBlock from './PartialPayment/AccountingResultPartialPaymentBlock.vue'
import ResultMainShow from './main/ResultMainShow.vue'
import AccountingResultPaymentRefusalBlock from './PaymentRefusal/AccountingResultPaymentRefusalBlock.vue'
import AccountingResultActRecordsBlock from './ActRecords/AccountingResultActRecordsBlock.vue'
import AccountingResultUserCalculationsBlock from './UserCalc/AccountingResultUserCalculationsBlock.vue'

export default {
  components: {
    JsonPretty,
    TwSpoiler,
    ResultMainShow,
    AccountingResultUserCalculationsBlock,
    AccountingResultPaymentRefusalBlock,
    AccountingResultPartialPaymentBlock,
    AccountingResultConfirmBlock,
    TwPanelTabsFull,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    PermissionDenied,
    AccountingResultActRecordsBlock
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {},
  data () {
    return {
      API: API,
      currentTab: 'main'
    }
  },
  methods: {
    refresh () {
      this.fetch()
      this.$emit('callback')
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    }
  }
}
</script>
