<template>
  <div
    v-if="formProcessing"
    class="py-3 text-center ma-auto"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    class="tw-font-manrope"
  >
    <div
      v-if="demoDataList"
      class="tw-mb-[20px]"
    >
      <TwSelect
        label="Демо данные для тестирования"
        dense
        :return-object="true"
        :options="demoDataList"
        :disabled="formProcessing"
        :disable-error-border="true"
        @input-object="onInputDemo"
      />
    </div>
    <div style="display: none">
      {{ isEdited }}
    </div>

    <!-- Физ. лица -->
    <TwFlexCol v-if="type === 'person'">
      <TwFlexRow class="tw-mt-[10px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Основное
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.surname"
            type="text"
            label="Фамилия"
            placeholder="Сидоров"
            dense
            :show-label="true"
            :persistent-label="true"
            :disabled="formProcessing"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.name"
            type="text"
            label="Имя"
            placeholder="Иван"
            dense
            :show-label="true"
            :persistent-label="true"
            :disabled="formProcessing"
          />
        </TwFlexCell>
      </TwFlexRow>

      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.patronymic"
            type="text"
            label="Отчество"
            placeholder="Петрович"
            dense
            :disabled="formProcessing"
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputText
            v-model="form.birthDate"
            type="date"
            persistent-label
            dense
            label="Дата рождения"
            :max="new Date().toISOString().slice(0, 10)"
            min="1920-01-01"
            :disabled="formProcessing"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow class="tw-mt-[20px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Паспорт
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputPassportFields
            v-model="form.passportSeria"
            label="Серия"
            dense
            persistent-label
            type="series"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputPassportFields
            v-model="form.passportNumber"
            label="Номер"
            dense
            persistent-label
            type="number"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.passportDate"
            type="date"
            label="Дата выдачи"
            dense
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell>
          <TwInputPassportFields
            v-model="form.passportCode"
            label="Код подразделения"
            dense
            persistent-label
            type="code"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow class="tw-mt-[20px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Др. документы
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.inn"
            type="number"
            label="ИНН"
            placeholder="ИНН"
            dense
            has-false-return-null
            :show-label="true"
            :persistent-label="true"
          />
        </TwFlexCell>
        <TwFlexCell class="tw-flex tw-items-center">
          <div class="tw-mt-[21px] tw-flex-1">
            <TwPanelActionButton
              class="tw-w-full"
              :disabled="fetchInnProccess || notValidFormForFetchInn"
              @click="fetchInnNumber"
            >
              Найти ИНН по паспорту
            </TwPanelActionButton>
            <small
              v-if="notValidFormForFetchInn"
              class="tw-text-an-flat-orange-warning"
            >
              * Заполните ФИО и паспортные данные
            </small>
          </div>
        </TwFlexCell>
        <div class="tw-text-main-xs tw-ml-[10px]">
          1. ИНН нужен для проверки коммерческой деятельности и арбитража.<br>
          2. Из проверки банкротства исключит однофамильцев.
        </div>
      </TwFlexRow>

      <TwFlexRow class="tw-mt-[20px]">
        <TwFlexCell class="tw-font-medium tw-text-main-md">
          Иное
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexRow>
        <TwFlexCell>
          <TwSelect
            label="Регион проверки"
            placeholder="Все регионы"
            dense
            :multi="true"
            persistent-label
            :return-object="true"
            :options="dictionary.regions"
            :disabled="formProcessing"
            :disable-error-border="true"
            :value="form.regions || []"
            @input="form.regions = Array.isArray($event) && $event.length ? $event:null"
          />
        </TwFlexCell>
      </TwFlexRow>
    </TwFlexCol>

    <!-- Юр. лица -->
    <TwFlexCol v-if="type === 'organization'">
      <TwFlexRow>
        <TwFlexCell>
          <TwInputText
            v-model="form.inn"
            type="number"
            label="ИНН"
            placeholder="ИНН"
            dense
            :show-label="true"
            :cleanable="true"
            :persistent-label="true"
            :disabled="!!form.ogrn"
            :error-text="$v.form.inn.$invalid ? '* обязательное поле':''"
          />
        </TwFlexCell>
        <div class="tw-mt-[31px] tw-text-an-flat-disabled-text">
          ИЛИ
        </div>
        <TwFlexCell>
          <TwInputText
            v-model="form.ogrn"
            type="number"
            label="ОГРН"
            placeholder="ОГРН"
            dense
            :show-label="true"
            :cleanable="true"
            :persistent-label="true"
            :disabled="!!form.inn"
            :error-text="$v.form.ogrn.$invalid ? '* обязательное поле':''"
          />
        </TwFlexCell>
      </TwFlexRow>
      <TwFlexCell>
        <TwInputText
          v-model="form.kpp"
          type="number"
          label="КПП"
          placeholder="КПП"
          dense
          :show-label="true"
          :cleanable="true"
          :persistent-label="true"
          :disabled="!!form.ogrn"
          :error-text="$v.form.kpp.$invalid ? '* обязательное поле':''"
        />
      </TwFlexCell>
    </TwFlexCol>
    <TwFlexCol>
      <TwFlexCell
        v-if="serverErrorMessage"
        class="tw-text-an-flat-red-main tw-mt-[20px] tw-text-center"
      >
        {{ serverErrorMessage }}
      </TwFlexCell>
    </TwFlexCol>
    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      :loading="formProcessing"
      :cancel-confirm="isEdited"
      :is-sticky="false"
      :disable-success="!isEdited || formProcessing"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
// TODO Валидации будут?
// import validateInn from '@/plugins/helpers/validateInn'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import API from '@/Api/DocCheck/Kontur/Check'
import fetchInnAPI from '@/Api/DocCheck/Kontur/Subject/RequisiteInn'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'
import TwInputPassportFields from '@/components/tw-ui/ui-kit/custom/TwInputPassportFields.vue'
import TwFlexCell from '@/components/tw-ui/ui-kit/flex-block/TwFlexCell.vue'
import TwFlexCol from '@/components/tw-ui/ui-kit/flex-block/TwFlexCol.vue'
import TwFlexRow from '@/components/tw-ui/ui-kit/flex-block/TwFlexRow.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwSelect from '@/components/tw-ui/ui-kit/input/TwSelect.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'DocCheckKonturForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwCircleLoader,
    TwInputPassportFields,
    TwFlexRow,
    TwFlexCell,
    TwFlexCol,
    TwSelect,
    TwPanelActionButton,
    TwInputText
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'person'
    }
  },
  data () {
    return {
      API: API,
      createQueryParams: {},
      form: {
        subjectId: '',
        name: '',
        surname: '',
        patronymic: '',
        birthDate: '',
        inn: '',
        ogrn: '',
        kpp: '',
        passportSeria: '',
        passportNumber: '',
        passportDate: '',
        passportCode: ''
      },
      fetchInnProccess: false,
      fetchInnRequiredFields: ['name', 'surname', 'patronymic', 'birthDate', 'passportSeria', 'passportNumber', 'passportDate', 'passportCode']
    }
  },
  // Модель для валидации, которую Vuelidate превратит в computed-поле $v
  validations () {
    return {
      form: {
        ...(this.type === 'organization') && {
          inn: {
            ...(!this.form.ogrn) && {
              required
            }
          },
          ogrn: {
            ...(!this.form.inn) && {
              required
            }
          },
          kpp: {
            ...(this.form.inn) && {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    fetchInnNumber () {
      this.fetchInnProccess = true
      fetchInnAPI.fetchPersonInn(this.form).then(({ data }) => {
        this.form.inn = data.data?.inn || null
      }).finally(() => {
        this.fetchInnProccess = false
      })
    },
    onInputDemo (eventData) {
      if (eventData?.data) {
        for (const key in eventData.data) {
          this.$set(this.form, key, eventData.data[key])
        }
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            this.$set(this.form, key, this.preset[key])
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      this.$v.$reset()
      this.$v.$touch()
      // console.warn('handleSubmit', this.$v.form.$invalid === false, this.formProcessing)
      if (!this.$v.$invalid) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? API.store(this.form)
          : API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).catch((error) => {
          this.serverErrorMessage = error?.serverErrorMessage
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'profile/isAdmin'
    }),
    notValidFormForFetchInn () {
      return this.fetchInnRequiredFields.some((field) => {
        return !this.form[field]
      })
    },
    demoDataList () {
      const list = []
      if (Array.isArray(this.allData?.['test-data']) && this.allData['test-data'].length) {
        this.allData['test-data'].forEach((i, index) => {
          list.push({
            label: i.name,
            value: `${index}_num`,
            data: i.data
          })
        })
        list.push({
          label: 'СБРОС',
          value: '-1_num',
          data: this.preset
        })
      }
      return list.length ? list : null
    }
  },
  created () {
    this.createQueryParams = {
      'with-test-data': this.isAdmin ? 1 : 0,
      type: this.type
    }
  }
}
</script>
