<template>
  <div
    v-cloak
    v-if="info"
  >
    <TwPanelContentBlockFieldView
      v-if="info.id"
      :horizontal="true"
    >
      <template #title>
        Контакт
      </template>
      <div class="tw-w-full">
        <ViewPanelLink
          :id="info.id"
          label="Контакт"
          component="person-show"
        >
          {{ $Global.peopleName(info, 'fi') }}
        </ViewPanelLink>
        <div
          v-if="info"
          class="tw-flex tw-mt-[4px]"
        >
          <template v-if="Array.isArray(info.phones) && info.phones.length">
            <div
              v-for="(phone, index) in info.phones"
              :key="index"
              class=""
            >
              <div class="tw-flex">
                <SipCallLink
                  v-if="phone.phone"
                  :class="phone.main ? 'tw-font-medium' : ''"
                  :phone="phone.phone"
                />
                <ClickBoard
                  class="tw-ml-1"
                  :disabled="!phone.phone"
                  :value="`+${phone.phone}`"
                />
                <template v-if="index < info.phones.length - 1">
                  <span class="tw-mr-[7px]">,</span>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </TwPanelContentBlockFieldView>
  </div>
</template>

<script>
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import SipCallLink from '@/components/telephony/SipCallLink.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ClickBoard from '@/components/button/ClickBoard.vue'
export default {
  name: 'LeadPersonShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink, SipCallLink, ClickBoard },
  mixins: [ViewPanelShowMixin],
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
