<template>
  <div>
    <div
      v-if="loading"
      class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-py-5"
    >
      <TwCircleLoader
        height="50px"
        width="50px"
      />
    </div>
    <div v-else>
      <div
        :class="fromPanel ? 'tw-px-[26px] tw-py-[18px]':''"
        class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-bg-white tw-min-h-screen"
      >
        <TwPanelTitle v-if="fromPanel">
          <template #left>
            <div class="tw-text-h4 tw-font-bold">
              <div v-if="data">
                {{ data.name }}
              </div>
              <div v-else>
                Новая матрица
              </div>
            </div>
          </template>
        </TwPanelTitle>
        <TwPanelTabsFull
          v-model="currentTab"
          :items="tabList"
          :disabled="editMain"
        >
          <template #content.main>
            <TwPanelContent>
              <div>
                <TwPanelContentBlock
                  v-if="data && data.id"
                  class="tw-mb-[6px]"
                >
                  <template #title>
                    <div>
                      О матрице
                    </div>
                  </template>
                  <template #action>
                    <TwPanelContentBlockActionButton
                      class="tw-flex tw-gap-[6px] tw-items-center"
                      :disabled="isNew || loading"
                      type="edit"
                      @click.prevent="createCopy"
                    >
                      <TwIconWrapper file-name="copy-1" />
                      Создать копию
                    </TwPanelContentBlockActionButton>
                  </template>
                  <div>
                    <DiscountMatrixMainShow
                      v-if="data"
                      :dictionary="dictionary"
                      :info="data"
                      @refresh="refresh"
                    />
                  </div>
                </TwPanelContentBlock>

                <DiscountMatrixEditor
                  :dictionary="dictionary"
                  :info="data"
                  :company-id="companyId"
                  :copy-from-id="isNew && copyFromId ? copyFromId : null"
                  @refresh="refresh"
                  @create="onCreated"
                />
              </div>
            </TwPanelContent>
          </template>
        </TwPanelTabsFull>
      </div>
      <div class="card-container">
        <div
          v-if="!loading"
          class="px-3"
        >
          <div class="py-2 body-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/Api/Mortgage/DiscountMatrix/index'
import CardMixin from '@/mixins/CardMixin'
import ViewPanelShowMixin from '@/mixins/ViewPanelShowMixin'
import DiscountMatrixMainShow from '@/views/Mortgage/DiscountMatrix/main/DiscountMatrixMainShow'
import DiscountMatrixEditor from '@/views/Mortgage/DiscountMatrix/main/DiscountMatrixEditor'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelTabsFull from '@/components/tw-ui/panels/TwPanelTabsFull.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockActionButton from '@/components/tw-ui/panels/TwPanelContentBlockActionButton.vue'
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'

export default {
  name: 'DiscountMatrixCard',
  components: {
    TwIconWrapper,
    TwPanelContentBlock,
    TwPanelContentBlockActionButton,
    TwPanelTabsFull,
    TwPanelContent,
    TwPanelTitle,
    TwCircleLoader,
    DiscountMatrixEditor,
    DiscountMatrixMainShow
  },
  mixins: [CardMixin, ViewPanelShowMixin],
  props: {
    companyId: {
      type: [String, Number],
      default: ''
    },
    copyFromId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      API: API,
      currentTab: 'main'
    }
  },
  computed: {
    tabList () {
      return [
        {
          label: 'Общая информация',
          key: 'main'
        }
      ]
    }
  },
  methods: {
    createCopy () {
      console.warn('copyData', this.data?.items)
      this.showDiscountMatrixCard({
        id: 0,
        companyId: this.data?.company?.id || this.companyId,
        copyFromId: this.data?.id
      }, () => this.$emit('callback'))
    }
  }
}
</script>
