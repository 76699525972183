<template>
  <div v-if="info">
    <div :class="wrapperClasses">
      <template v-if="info.curatorVerified">
        <TwPanelContentBlockFieldView>
          <template #title>
            Проверено куратором
          </template>
          <div class="tw-flex tw-gap-[8px] tw-items-center tw-font-medium">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM12.2312 6.95981C12.6383 6.58662 12.6658 5.95405 12.2926 5.54693C11.9194 5.13981 11.2868 5.11231 10.8797 5.4855L6.70696 9.31053L5.12006 7.85587C4.71294 7.48268 4.08037 7.51018 3.70718 7.9173C3.33399 8.32442 3.36149 8.95699 3.76861 9.33018L6.03124 11.4043L6.70696 12.0237L7.38269 11.4043L12.2312 6.95981Z"
                fill="#27AA54"
              />
            </svg>
            Проверено
          </div>
        </TwPanelContentBlockFieldView>
        <div />
      </template>
      <TwPanelContentBlockFieldView>
        <template #title>
          Тип объекта
        </template>
        <div v-if="info.objectType">
          {{ info.objectType && info.objectType.label }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Кадастровый номер
        </template>
        <div v-if="info.cadastralNumber">
          {{ info.cadastralNumber }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.landArea">
        <template #title>
          Площадь участка
        </template>
        <div v-if="info.landArea">
          {{ info.landArea }} сот.
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.houseArea">
        <template #title>
          Площадь дома
        </template>
        <div v-if="info.houseArea">
          {{ info.houseArea }} кв.м
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.houseCompletedDate">
        <template #title>
          Дата готовности дома
        </template>
        <div>
          {{ $Global.DateFormat.DateShort(info.houseCompletedDate, true) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Застройщик
        </template>
        <div v-if="info.company">
          {{ info.company && info.company.name }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Стоимость объекта
        </template>
        <div v-if="info.objectPrice">
          {{ (info.objectPrice || 0).toLocaleString('ru') }} руб.
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата сделки
        </template>
        <div v-if="info.dealDate">
          {{ $Global.DateFormat.DateShort(info.dealDate, true) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Способ оплаты
        </template>
        <div v-if="payMethod">
          {{ payMethod.label }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Номер договора
        </template>
        <div v-if="info.contractNum">
          {{ info.contractNum }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Крайняя дата полной оплаты ДДУ
        </template>
        <div v-if="info.deadlinePayDDU">
          {{ $Global.DateFormat.DateShort(info.deadlinePayDDU, true) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Предварительная комиссия
        </template>
        <div v-if="info.preliminaryCommission">
          {{ (info.preliminaryCommission || 0).toLocaleString('ru') }} руб.
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="info.fiasGuid && info.fiasGuid.fullAddress">
        <template #title>
          Адрес
        </template>
        <div v-if="info.fiasGuid && info.fiasGuid.fullAddress">
          {{ info.fiasGuid.fullAddress }}
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div class="tw-mt-[12px]">
      <TwFilesListViewByIds
        :file-ids="info.contractFileIds || []"
        title="Список файлов договора:"
        :is-drop-down="false"
      />
    </div>
  </div>
  <div
    v-else
    class="tw-text-an-flat-disabled-text tw-text-center"
  >
    Не заполнена
  </div>
</template>

<script>
import TwFilesListViewByIds from '@/components/tw-ui/ui-kit/files/TwFilesListViewByIds.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import { GridWrapperForPanelShowAndForm } from '@/utils/tw-classes'

export default {
  name: 'DealBuyNbLandHouseResultShow',
  components: { TwPanelContentBlockFieldView, TwFilesListViewByIds },
  props: {
    info: {
      type: Object,
      default: null
    },
    dictionary: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      wrapperClasses: GridWrapperForPanelShowAndForm.threeCols
    }
  },
  computed: {
    purposePurchase () {
      return this.dictionary.purposePurchase.find(i => i.value === this.info.purposePurchase)
    },
    payMethod () {
      return this.dictionary.payMethod.find(i => i.value === this.info.payMethod)
    },
    reservation () {
      return this.dictionary.reservation.find(i => i.value === this.info.reservationId)
    }
  }
}
</script>
