<template>
  <div
    v-if="info"
    class="tw-flex tw-gap-[30px]"
  >
    <div class="tw-flex-1 tw-flex tw-gap-[12px] tw-flex-col">
      <TwPanelContentBlockFieldView>
        <template #title>
          Название
        </template>
        <div v-if="info.name">
          {{ info.name }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Банк
        </template>
        <div
          v-if="info.company"
          :class="info.company.active ? '':'tw-text-an-flat-red-main'"
        >
          <ViewPanelLink
            :id="info.company.id"
            label="Компания"
            component="company-show"
          >
            {{ info.company.name }}
          </ViewPanelLink>
          <span v-if="!info.company.active">
            (неактивен)
          </span>
        </div>
      </TwPanelContentBlockFieldView>
    </div>
    <div class="tw-flex-1 tw-flex tw-gap-[12px] tw-flex-col">
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата создания
        </template>
        <div v-if="info.createdAt">
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.createdAt) }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата изменения
        </template>
        <div v-if="info.updatedAt">
          {{ $Global.DateFormat.DateTimeShortMonthLong(info.updatedAt) }}
        </div>
      </TwPanelContentBlockFieldView>
    </div>
  </div>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DiscountMatrixMainShow',
  components: { TwPanelContentBlockFieldView, ViewPanelLink },
  props: {
    info: {},
    dictionary: {}
  }
}
</script>
