<template>
  <div>
    <hr class="tw-my-[20px]">
    <div>
      <div
        v-if="defaultEmail"
      >
        <div class="tw-text-h5">
          Быстрый ответ
        </div>
        <div>
          <div class="tw-text-left tw-my-[8px]">
            <div>
              Получатели:
              <i
                v-for="(contact, c_index) of replyFrom.contacts"
                :key="contact.id"
              >
                <span v-text="`${contact.name} <${contact.email}>`" />
                <span v-if="c_index < replyFrom.contacts.length - 1">, </span>
              </i>
            </div>
          </div>
        </div>
        <div>
          <QuillEmailEditor
            v-model="form.body"
            min-height="150px"
            max-height="250px"
          />
        </div>
        <div class="tw-my-[20px]">
          <div class="tw-text-h6 tw-mb-[10px]">
            Прикреплённые файлы:
          </div>
          <TwUploadFilesList
            v-model="form.filesId"
            :api="emailFileAPI"
            label="Прикрепленные файлы"
            file-type="email_attachment"
            :no-data-text="false"
            :has-inline="true"
            :multiple="true"
          />
        </div>
        <div
          style="position: sticky;bottom: 0;"
          class="tw-bg-white"
        >
          <hr>
          <div class="tw-py-[10px] tw-flex tw-gap-[10px]">
            <TwPanelActionButton
              color="blue"
              :disabled="formProcessing || !form.body"
              @click.prevent="handleSubmit"
            >
              Отправить ответ
            </TwPanelActionButton>
            <TwPanelActionButton
              color="gray"
              :disabled="formProcessing"
              @click.prevent="handleCancel"
            >
              Отменить
            </TwPanelActionButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import emailAPI from '@/Api/Email/Mail'
import emailFileAPI from '@/Api/Email/File'
import QuillEmailEditor from '@/components/text-editor/QuillEmailEditor.vue'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwPanelActionButton from '@/components/tw-ui/panels/TwPanelActionButton.vue'

export default {
  name: 'EmailMainReply',
  components: { QuillEmailEditor, TwUploadFilesList, TwPanelActionButton },
  mixins: [FormCreateEditMixin],
  props: {
    replyId: {
      type: String,
      required: true
    },
    replyFrom: {
      type: Object
    }
  },
  data () {
    return {
      emailFileAPI,
      API: emailAPI,
      form: {
        folderId: '',
        theme: '',
        body: '',
        contacts: [],
        filesId: []
      }
    }
  },
  methods: {
    handleCancel () {
      this.$emit('hide')
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          const adaperMap = {
            // from(show): to(send)
            files: 'filesId',
            contacts: 'contacts',
            body: 'body',
            theme: 'theme'
          }
          for (const key in this.preset) {
            if (adaperMap[key] !== undefined) {
              if (key === 'files') {
                this.$set(this.form, adaperMap[key], this.preset[key] || [])
              } else if (key === 'contacts') {
                this.$set(this.form,
                  adaperMap[key],
                  Array.isArray(this.preset[key]) && this.preset[key].length ? this.preset[key].map(c => {
                    return {
                      id: c.id,
                      name: c.name,
                      email: c.email
                    }
                  }) : [])
              } else {
                this.$set(this.form, adaperMap[key], this.preset[key])
              }
            }
          }
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.form.body.trim()) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true

        this.API.reply(this.replyId, this.form)
          .then((data) => {
            console.warn(this.$options.name, data)
            this.handleCancel()
          })
          .catch((error) => {
            console.error(this.$options.name, error)
          })
          .finally(() => {
            this.formProcessing = false
          })
      }
    },
    initProps () {
      if (Array.isArray(this.toPerson?.additionalContacts)) {
        const emails = this.toPerson.additionalContacts.filter(contact => contact.type === 'email')
        if (emails.length) {
          this.form.to.push(emails[0].id)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUserName: 'profile/fullName',
      emails: 'profile/emails'
    }),
    defaultEmail () {
      if (this.emails && this.emails.length) {
        return this.emails[0]
      }
      return null
    }
  },
  mounted () {
    this.initProps()
  }
}
</script>
