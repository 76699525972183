<template>
  <div
    v-if="data"
    class="tw-font-manrope tw-text-main-sm2 tw-max-w-[100%] tw-px-[26px] tw-py-[18px] tw-bg-white tw-min-h-screen"
  >
    <TwPanelTitle>
      <template #left>
        <div class="tw-text-h4 tw-font-bold">
          Отзыв по заявке №{{ dealId }} <span
            v-if="data.id"
            class="tw-text-an-flat-disabled-text tw-font-medium"
          ># {{ data.id }}</span>
        </div>
      </template>
      <template #right />
    </TwPanelTitle>
    <TwPanelTabs>
      <button class="tw-bg-white tw-rounded-sm tw-px-[14px] tw-py-[8px]">
        Общая информация
      </button>
    </TwPanelTabs>
    <TwPanelContent>
      <div class="tw-flex-1">
        <TwPanelContentBlock class="tw-mb-[6px]">
          <template #title>
            О отзыве
          </template>
          <div class="tw-flex tw-gap-[12px] tw-flex-col">
            <TwPanelContentBlockFieldView>
              <template #title>
                Статус
              </template>
              <div
                v-if="data.status"
                class="tw-font-semibold"
              >
                {{ data.status.label }}
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Сделка
              </template>
              <div v-if="dealId">
                <ViewPanelLink
                  :id="dealId"
                  label="Заявка"
                  component="deal-show"
                  :callback="refresh"
                >
                  {{ data.deal.type.label }} # {{ dealId }}
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Дата закрытия сделки
              </template>
              <div v-if="data.deal">
                <template v-if="data.deal.closeDate">
                  {{ $Global.DateFormat.DateShort(data.deal.closeDate, true) }}
                </template>
                <strong
                  v-else
                  class="tw-text-an-flat-red-main"
                >Не указано</strong>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Клиент
              </template>
              <div v-if="data.personId">
                <ViewPanelLink
                  :id="data.personId"
                  label="Клиент"
                  component="person-show"
                  :callback="refresh"
                >
                  <PersonName
                    :id="data.personId"
                    format="fio"
                  />
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Ответственный по сделке
              </template>
              <div v-if="data.realtorId">
                <ViewPanelLink
                  :id="data.realtorId"
                  label="Сотрудник"
                  component="employee-show"
                  :callback="refresh"
                >
                  <EmployeeName :id="data.realtorId" />
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView no-data-text="Отсутствует">
              <template #title>
                Юрист
              </template>
              <div v-if="data.juristId">
                <ViewPanelLink
                  :id="data.juristId"
                  label="Сотрудник"
                  component="employee-show"
                  :callback="refresh"
                >
                  <EmployeeName :id="data.juristId" />
                </ViewPanelLink>
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView>
              <template #title>
                Дата создания
              </template>
              <div v-if="data.createdAt">
                {{ $Global.DateFormat.DateTimeShortMonthLong(data.createdAt) }}
              </div>
            </TwPanelContentBlockFieldView>
            <TwPanelContentBlockFieldView v-if="data.createdAt !== data.updatedAt">
              <template #title>
                Дата изменения
              </template>
              <div v-if="data.updatedAt">
                {{ $Global.DateFormat.DateTimeShortMonthLong(data.updatedAt) }}
              </div>
            </TwPanelContentBlockFieldView>
          </div>
        </TwPanelContentBlock>
        <template v-if="dealTypeValue !== 'mortgage'">
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              Отзыв через СМС
            </template>
            <template #default>
              <div class="tw-flex tw-gap-[12px] tw-flex-col">
                <TwPanelContentBlockFieldView>
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Состояние
                    </div>
                  </template>
                  <div v-if="pollData && pollData.status">
                    {{ pollData.status.label }}
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView>
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Дата отправки СМС
                    </div>
                  </template>
                  <div v-if="pollData && pollData.sendDate">
                    {{ pollData.sendDate }}
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView>
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Ссылка для для отзыва
                    </div>
                  </template>
                  <div v-if="pollData && pollData.pollCode">
                    <a
                      :href="`${siteReviewUrl}/my/deal?c=${pollData.pollCode}`"
                      target="_blank"
                    >Открыть в новой вкладке</a>
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView v-if="pollData && pollData.status && pollData.status.value === 'waitingForSendSms'">
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Отправка СМС
                    </div>
                  </template>
                  <div v-if="pollData && pollData.status">
                    <button
                      :disabled="pollProcess"
                      class="tw-rounded-md tw-px-[10px] tw-py-[4px] tw-font-medium tw-text-main-xs tw-text-white tw-bg-an-flat-dark-blue-link enabled:hover:tw-bg-an-flat-dark-blue-link-active disabled:tw-opacity-50"
                      @click.prevent="sendPoll"
                    >
                      Отправить СМС
                    </button>
                  </div>
                </TwPanelContentBlockFieldView>
              </div>
            </template>
          </TwPanelContentBlock>
          <TwPanelContentBlock class="tw-mb-[6px]">
            <template #title>
              Отзыв через бота
            </template>
            <template #default>
              <div class="tw-flex tw-gap-[12px] tw-flex-col">
                <TwPanelContentBlockFieldView v-if="data.noAnswerCall">
                  <template #title>
                    Неотвеченный вызов
                  </template>
                  <div class="tw-text-an-flat-red-main">
                    Клиент не взял трубку
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView
                  v-if="otherData.settings.autoEnable"
                  no-data-text="Выкл."
                >
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Автообзвон
                    </div>
                  </template>
                  <div
                    v-if="otherData.settings.willBotProcessAt"
                    class="tw-flex tw-gap-[6px]"
                  >
                    Дата звонка: {{ $Global.DateFormat.DateTimeShort(otherData.settings.willBotProcessAt) }}
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView no-data-text="Не звонили">
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Обзвон ботом
                    </div>
                  </template>
                  <div>
                    <div
                      v-if="botCallInfo"
                      class="tw-flex tw-gap-[6px]"
                    >
                      <div
                        v-if="botCallInfo.status"
                        class="tw-font-semibold"
                      >
                        {{ botCallInfo.status.label }}
                      </div>
                      <div v-if="botCallInfo.statusDate">
                        {{ $Global.DateFormat.DateTimeShort(botCallInfo.statusDate) }}
                      </div>
                    </div>
                    <button
                      v-else
                      class="tw-rounded-md tw-px-[10px] tw-py-[2px] tw-font-medium tw-text-main-xs tw-text-white tw-bg-an-flat-dark-blue-link enabled:hover:tw-bg-an-flat-dark-blue-link-active disabled:tw-opacity-50"
                      @click.prevent="registerBotCall"
                    >
                      Прозвонить ботом
                    </button>
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView
                  v-if="botCallInfo"
                  no-data-text="Нет данных"
                >
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Результат автообзвона
                    </div>
                  </template>
                  <div>
                    <div v-if="botCallInfo && Array.isArray(botCallInfo.result) && botCallInfo.result.length">
                      <div
                        v-for="(item, index) in botCallInfo.result"
                        :key="index"
                        class="tw-my-[7px]"
                      >
                        <span class="tw-font-medium">{{ item.label }}</span>: <span class="tw-text-an-flat-black-main">{{ item.value }}</span>
                      </div>
                    </div>
                    <div
                      v-else
                      class="tw-text-an-flat-disabled-text"
                    >
                      Нет данных
                      <button
                        v-if="!botCallInfo.processed"
                        class="tw-ml-[12px] tw-font-medium tw-text-main-xs tw-text-an-flat-dark-blue-link enabled:hover:tw-text-an-flat-dark-blue-link-active disabled:tw-opacity-50"
                        @click.prevent="showBotCall"
                      >
                        Обновить результат
                      </button>
                    </div>
                  </div>
                </TwPanelContentBlockFieldView>
                <TwPanelContentBlockFieldView no-data-text="Нет звонков">
                  <template #title>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      Записи звонков
                    </div>
                  </template>
                  <div>
                    <div class="tw-flex tw-flex-col tw-gap-[8px] tw-max-h-[300px] tw-overflow-auto">
                      <table v-if="Array.isArray(callsList) && callsList.length">
                        <tr
                          v-for="call in callsList"
                          :key="call.id"
                        >
                          <td class="tw-p-[6px]">
                            <small
                              v-if="call.status !== 'answered'"
                              class="tw-text-an-flat-red-main"
                            >
                              Неотвеченный
                            </small>
                            <div>
                              {{ call.type === 'outgoing' ? 'Исходящий':'Входящий' }}
                            </div>
                          </td>
                          <td class="tw-text-right tw-p-[6px]">
                            <small class="tw-text-an-flat-disabled-text">
                              {{ $Global.timeSecondsFormat(call.duration) }}
                            </small>
                            <div>
                              {{ $Global.DateFormat.DateShort(call.date) }}
                            </div>
                          </td>
                          <td class="tw-p-[6px] tw-text-an-flat-disabled-text">
                            <audio
                              v-if="call.file"
                              style="height: 30px;width: 100%;min-width: 400px"
                              :src="call.file.url"
                              controls
                              preload="none"
                            />
                            <div v-else>
                              {{ call.description }}
                            </div>
                          </td>
                        </tr>
                      </table>
                      <div
                        v-else
                        class="tw-text-an-flat-disabled-text"
                      >
                        Нет данных
                        <button
                          v-if="botCallInfo"
                          class="tw-ml-[12px] tw-font-medium tw-text-main-xs tw-text-an-flat-dark-blue-link enabled:hover:tw-text-an-flat-dark-blue-link-active disabled:tw-opacity-50"
                          @click.prevent="callByPerson"
                        >
                          Обновить записи
                        </button>
                      </div>
                    </div>
                  </div>
                </TwPanelContentBlockFieldView>
              </div>
            </template>
          </TwPanelContentBlock>
        </template>
      </div>
      <div class="tw-flex-1">
        <div
          v-for="item in dictionary.rater"
          :key="item.value"
        >
          <DealReviewTypeBlock
            :info="data[item.value]"
            :type="item"
            :dictionary="dictionary"
            :review-id="data.id"
            @refresh="onChangeTypeBlock"
          />
        </div>
        <div v-if="data">
          <DealReviewCommentsDialog :deal-review-id="data.id" />
        </div>
      </div>
    </TwPanelContent>
  </div>
  <div
    v-else-if="loading"
    class="tw-flex tw-my-[30px]"
  >
    <TwCircleLoader class="tw-m-auto" />
  </div>
  <div
    v-else
    class="tw-text-an-flat-red-main tw-text-center tw-p-[20px]"
  >
    {{ error === 403 ? 'Недостаточно прав доступа':'Не удалось получить объект данных' }}
  </div>
</template>

<script>
import { SERVICES } from '@/Api/Services'
import { DealReview, Sipuni, Poll } from '@/Api/Review'
import CardMixin from '@/mixins/CardMixin'
import TwPanelTitle from '@/components/tw-ui/panels/TwPanelTitle.vue'
import TwPanelTabs from '@/components/tw-ui/panels/TwPanelTabs.vue'
import TwPanelContent from '@/components/tw-ui/panels/TwPanelContent.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import PersonName from '@/components/other/person/PersonName.vue'
import EmployeeName from '@/components/other/employee/EmployeeName.vue'
import DealReviewTypeBlock from '@/views/Review/DealReview/DealReviewTypeBlock.vue'
import DealReviewCommentsDialog from '@/views/Review/DealReview/DealReviewCommentsDialog.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'

export default {
  name: 'DealReviewCard',
  components: {
    TwCircleLoader,
    DealReviewCommentsDialog,
    DealReviewTypeBlock,
    EmployeeName,
    PersonName,
    ViewPanelLink,
    TwPanelContentBlockFieldView,
    TwPanelContentBlock,
    TwPanelContent,
    TwPanelTabs,
    TwPanelTitle
  },
  mixins: [CardMixin],
  data () {
    return {
      API: DealReview,
      pollProcess: false,
      pollData: null,
      botCallInfo: null,
      callsList: []
    }
  },
  computed: {
    siteReviewUrl () {
      return SERVICES.REVIEW.host
    },
    dealId () {
      return this.data?.deal?.id || 0
    },
    dealTypeValue () {
      return this.data?.deal?.type?.value || null
    }
  },
  methods: {
    onChangeTypeBlock () {
      this.refresh()
      this.$emit('callback')
    },
    getPollStatus () {
      this.pollProcess = true
      Poll.getStatusByDealId(this.dealId).then(({ data: result }) => {
        console.warn(this.$options.name, 'getPollStatus', result)
        this.pollData = result?.data || null
      }).catch((error) => {
        console.error(this.$options.name, 'getPollStatus', error)
        this.pollData = null
      }).finally(() => {
        this.pollProcess = false
      })
    },
    sendPoll () {
      this.pollProcess = true
      Poll.send({ dealId: this.dealId }).then(({ data: result }) => {
        console.warn(this.$options.name, 'sendPoll', result)
      }).catch((error) => {
        console.error(this.$options.name, 'sendPoll', error)
      }).finally(() => {
        this.pollProcess = false
        this.getPollStatus()
      })
    },
    callByPerson () {
      Sipuni.callByPerson(this.data.personId).then(({ data: result }) => {
        this.callsList = result?.data || []
      }).catch(() => {
        this.callsList = []
      })
    },
    showBotCall () {
      DealReview.botProcessShow(this.dealId).then(({ data: result }) => {
        this.botCallInfo = result?.data || null
      }).catch(() => {
        this.botCallInfo = null
      })
    },
    registerBotCall () {
      if (confirm('Подтердите запрос звонка ботом!')) {
        DealReview.botProcessRegister(this.dealId, { dealId: this.dealId }).finally(() => {
          this.refresh()
        })
      } else {
        console.warn('Отмена - не подтвержено')
      }
    },
    postFetch () {
      this.getPollStatus()
      this.callByPerson()
      if (this.data?.status?.value !== 'new') {
        this.showBotCall()
      }
    }
  }
}
</script>
