<template>
  <TwPanelContentBlock
    class="tw-mb-[6px]"
    no-data-text="Нет привязанных актов"
  >
    <template #title>
      <div>
        Акты
      </div>
    </template>
    <div v-if="Array.isArray(info) && info.length">
      <div
        v-for="item in info"
        :key="item.id"
        class="tw-rounded-sm tw-flex tw-px-[16px] tw-py-[8px] tw-gap-[12px] tw-items-center odd:tw-bg-an-flat-bg-main/50"
      >
        <div class="tw-flex-1">
          <ViewPanelLink
            :id="item.actId"
            label="Акт"
            component="accounting-nb-act-show"
          >
            Акт № <b>{{ item.actId }}</b> от {{ item.actCreatedAt }}
          </ViewPanelLink>
        </div>
        <div>
          <span :class="item.actBlocked ? 'tw-text-an-flat-green2':'tw-text-an-flat-disabled-text'">
            Сформирован
          </span> &bull;
          <span :class="item.actReceived ? 'tw-text-an-flat-green2':'tw-text-an-flat-disabled-text'">
            Получен
          </span> &bull;
          <span :class="item.actPayed ? 'tw-text-an-flat-green2':'tw-text-an-flat-disabled-text'">
            Оплачен
          </span>
        </div>
        <div class="tw-text-right">
          <div class="tw-font-semibold tw-text-main-lg">
            {{ (item.amount).toLocaleString() }} <Rouble />
          </div>
        </div>
      </div>
    </div>
  </TwPanelContentBlock>
</template>

<script>
import ViewPanelLink from '@/components/core/ViewPanel/ViewPanelLink.vue'
import Rouble from '@/components/symbol/Rouble.vue'
import TwPanelContentBlock from '@/components/tw-ui/panels/TwPanelContentBlock.vue'

export default {
  components: {
    Rouble,
    ViewPanelLink,
    TwPanelContentBlock
  },
  props: {
    info: {
      type: Array,
      default: null
    }
  }
}
</script>
