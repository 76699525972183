import { AxiosResponse } from 'axios'
import HTTP from '@/Api/http'

const path = '/deal/buy-nb/result/management'

export default {
  listInit (): Promise<AxiosResponse> {
    const $config: any = {}
    return HTTP.get(`${path}/list-init`, $config)
  },
  getList (param?: object, otherConfig?: object): Promise<AxiosResponse> {
    const $config = {
      params: param,
      ...(otherConfig || {})
    }
    return HTTP.get(`${path}`, $config)
  },
  show (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}`, $config)
  },
  update (id: number, data: any): Promise<AxiosResponse> {
    return HTTP.put(`${path}/${id}`, data)
  },
  showStatus (id: number): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.get(`${path}/${id}/status`, $config)
  },
  storeStatus (id: number, data: any): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.post(`${path}/${id}/status`, data, $config)
  },
  updateStatus (id: number, data: any): Promise<AxiosResponse> {
    const $config = {}
    return HTTP.put(`${path}/${id}/status`, data, $config)
  }
}
