<template>
  <div
    v-if="formProcessing"
    class="tw-font-manrope tw-p-[30px] tw-flex tw-justify-center"
  >
    <TwCircleLoader />
  </div>
  <div
    v-else
    class="tw-p-[15px]"
  >
    <div style="display: none">
      {{ isEdited }}
    </div>
    <div>
      <TwInputText
        v-model="form.date"
        type="date"
        placeholder="Ход строительства на дату"
        :max="currentDate"
        :disabled="formProcessing"
        :show-label="true"
        :dense="true"
        :persistent-label="true"
        :error-text="!form.date ? 'Обязательное поле' : ''"
      />
      <div class="tw-mt-[15px]">
        <div class="tw-text-an-flat-blue-btn-active tw-text-main-sm2 tw-mb-[5px] tw-mt-[10px]">
          Прикреплённые файлы:
        </div>
        <TwUploadFilesList
          v-model="form.filesId"
          label="Фото процесса строительства"
          :api="osFileAPI"
          file-type="construction_progress"
          :has-inline="false"
          :multiple="true"
        />
      </div>
    </div>
    <div
      v-if="!Array.isArray(form.filesId) || !form.filesId.length"
      class="tw-text-center tw-text-an-flat-red-main tw-mt-[15px] tw-text-main-sm2"
    >
      Загрузите фото процесса строительства
    </div>

    <TwCardSimpleFormSaveBlock
      class="tw-mt-[15px]"
      bg-color="transparent"
      :loading="formProcessing"
      :cancel-confirm="false"
      :is-sticky="false"
      :disable-success="formProcessing || !isValidForm"
      :disable-cancel="formProcessing"
      :visible="true"
      @success="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { API } from '@/Api/ObjectStorage'
import osFileAPI from '@/Api/ObjectStorage/File'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import { RouteKeys } from '@/router/types'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwInputText from '@/components/tw-ui/ui-kit/input/TwInputText.vue'
import TwCircleLoader from '@/components/tw-ui/ui-kit/TwCircleLoader.vue'
import TwCardSimpleFormSaveBlock from '@/components/tw-ui/forms/TwCardSimpleFormSaveBlock.vue'

export default {
  name: 'RealtyComplexConstructionProgressForm',
  components: {
    TwCardSimpleFormSaveBlock,
    TwCircleLoader,
    TwInputText,
    TwUploadFilesList
  },
  mixins: [FormCreateEditMixin],
  props: {
    disableRedirect: {
      type: Boolean,
      default: false
    },
    defaultFormFields: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      osFileAPI,
      API: API.ConstructionProgress,
      currentDate: new Date().toISOString().slice(0, 10),
      form: {
        id: null,
        date: null,
        filesId: null
      }
    }
  },
  computed: {
    isValidForm () {
      return !!(this.form.date && Array.isArray(this.form.filesId) && this.form.filesId.length)
    }
  },
  methods: {
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'files' && Array.isArray(this.preset[key])) {
              this.$set(this.form, 'filesId', this.preset[key].map(f => f.id) || [])
            } else {
              this.$set(this.form, key, this.preset[key] || '')
            }
          }
        }
        if (this.defaultFormFields.complexId) {
          this.$set(this.form, 'complexId', this.defaultFormFields.complexId)
        }
        resolve()
      })
    },
    handleSubmit () {
      if (this.isValidForm) {
        if (this.formProcessing === true) {
          return
        }

        this.formProcessing = true
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
            // если текущий путь равен пути создания то редирект в просмотр
            if (this.$route.name === `${RouteKeys.lead}-create`) {
              this.$router.replace({
                name: `${RouteKeys.lead}-show`,
                params: { id: data.data.id }
              })
            }
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    }
  }
}
</script>
