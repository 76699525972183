import { RouteKeys } from '@/router/types'

export default {
  path: `${RouteKeys.dealReview}`,
  name: RouteKeys.dealReview,
  redirect: { name: `${RouteKeys.dealReview}-list` },
  meta: { name: 'Отзывы по заявкам', icon: 'reviews-dealReview' },
  component: () => import('@/views/Review/Index.vue'),
  children: [
    {
      path: 'list',
      name: `${RouteKeys.dealReview}-list`,
      meta: { name: 'Список отзывов по заявкам', icon: 'reviews-dealReview', hideInMenu: true, hText: 'Отзывы по заявкам' },
      component: () => import('@/views/Review/DealReview/List.vue')
    },
    {
      path: ':id/edit',
      name: `${RouteKeys.dealReview}-edit`,
      meta: { name: 'Редактирование отзыва', icon: 'reviews-dealReview', hideInMenu: true },
      component: () => import('@/views/Review/DealReview/List.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    },
    {
      path: 'create',
      name: `${RouteKeys.dealReview}-create`,
      meta: { name: 'Добавление отзыва', icon: 'reviews-dealReview', hideInMenu: true },
      component: () => import('@/views/Review/DealReview/List.vue'),
      props: (route:any) => ({ id: Number(route.params.id) })
    }
  ]
}
