<template>
  <div
    v-cloak
    v-if="subjectInfo"
    class="tw-flex tw-gap-[12px] tw-flex-col"
  >
    <TwPanelContentBlockFieldView>
      <template #title>
        Тип проверки
      </template>
      <div v-if="subjectInfo.type">
        {{ subjectInfo.type.label }}
      </div>
    </TwPanelContentBlockFieldView>
    <template v-if="subjectInfo.type && subjectInfo.type.value === 'person'">
      <div class="tw-font-semibold tw-mt-[10px]">
        Основное:
      </div>
      <TwPanelContentBlockFieldView>
        <template #title>
          Фамилия
        </template>
        <div v-if="subjectInfo.surname">
          {{ subjectInfo.surname }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Имя
        </template>
        <div v-if="subjectInfo.name">
          {{ subjectInfo.name }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Отчество
        </template>
        <div v-if="subjectInfo.patronymic">
          {{ subjectInfo.patronymic }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата рождения
        </template>
        <div v-if="subjectInfo.birthDate">
          {{ $Global.DateFormat.DateFull(subjectInfo.birthDate) }}
          <span class="tw-text-an-flat-gray-4">
            ({{ $Global.calculateAge(subjectInfo.birthDate) }}
            {{ $Global.textPlural($Global.calculateAge(subjectInfo.birthDate), 'year') }})
          </span>
        </div>
      </TwPanelContentBlockFieldView>
      <div class="tw-font-semibold tw-mt-[10px]">
        Паспортные данные:
      </div>
      <TwPanelContentBlockFieldView>
        <template #title>
          Серия
        </template>
        <div v-if="subjectInfo.passportSeria">
          {{ subjectInfo.passportSeria }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Номер
        </template>
        <div v-if="subjectInfo.passportNumber">
          {{ subjectInfo.passportNumber }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Код подразделения
        </template>
        <div v-if="subjectInfo.passportCode">
          {{ subjectInfo.passportCode }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          Дата выдачи
        </template>
        <div v-if="subjectInfo.passportDate">
          {{ $Global.DateFormat.DateShort(subjectInfo.passportDate) }}
        </div>
      </TwPanelContentBlockFieldView>
      <div class="tw-font-semibold tw-mt-[10px]">
        Другие документы:
      </div>
      <TwPanelContentBlockFieldView>
        <template #title>
          ИНН
        </template>
        <div v-if="subjectInfo.inn">
          {{ subjectInfo.inn }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView>
        <template #title>
          СНИЛС
        </template>
        <div v-if="subjectInfo.snils">
          {{ subjectInfo.snils }}
        </div>
      </TwPanelContentBlockFieldView>
      <div class="tw-font-semibold tw-mt-[10px]">
        Иное:
      </div>
      <TwPanelContentBlockFieldView>
        <template #title>
          Регион проверки
        </template>
        <div>
          {{ Array.isArray(subjectInfo.regions) && subjectInfo.regions.length ? 'Татарстан':'Все регионы' }}
        </div>
      </TwPanelContentBlockFieldView>
    </template>
    <template v-else-if="subjectInfo.type && subjectInfo.type.value === 'organization'">
      <TwPanelContentBlockFieldView no-data-text="Не получено">
        <template #title>
          Наименование организации
        </template>
        <div v-if="subjectInfo.organizationName">
          {{ subjectInfo.organizationName }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="subjectInfo.ogrn">
        <template #title>
          ОГРН
        </template>
        <div>
          {{ subjectInfo.ogrn }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="subjectInfo.inn">
        <template #title>
          ИНН
        </template>
        <div v-if="subjectInfo.inn">
          {{ subjectInfo.inn }}
        </div>
      </TwPanelContentBlockFieldView>
      <TwPanelContentBlockFieldView v-if="subjectInfo.kpp">
        <template #title>
          КПП
        </template>
        <div v-if="subjectInfo.kpp">
          {{ subjectInfo.kpp }}
        </div>
      </TwPanelContentBlockFieldView>
    </template>
    <template v-else>
      Неизвестный тип субьекта
      <pre>
        {{ subjectInfo }}
      </pre>
    </template>
  </div>
</template>

<script>
import TwPanelContentBlockFieldView from '@/components/tw-ui/panels/TwPanelContentBlockFieldView.vue'

export default {
  name: 'DocCheckKonturShow',
  components: { TwPanelContentBlockFieldView },
  props: {
    info: {},
    dictionary: {}
  },
  methods: {
    refreshParent (id) {
      console.warn(this.$options.name, ' refreshParent ', id)
      this.$emit('refresh')
    }
  },
  computed: {
    subjectInfo () {
      return this.info?.subject || null
    }
  }
}
</script>

<style scoped>

</style>
