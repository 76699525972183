<template>
  <div>
    <div
      v-if="!hideTitle"
      class="tw-font-medium"
    >
      Прикреплённые файлы:
    </div>
    <div
      v-if="Array.isArray(filesImages)"
      class="tw-flex tw-flex-wrap"
    >
      <div
        v-for="(file, f_index) in filesImages"
        :key="file.id"
        class="tw-ma-1"
      >
        <img
          class="tw-cursor-pointer"
          style="border: 1px solid lightgrey"
          :width="imageSize"
          :height="imageSize"
          :style="{ width: imageSize + 'px', height: imageSize + 'px' }"
          :src="file.preview"
          @click="imageShow(f_index)"
        >
        <small
          v-if="file.size"
          class="tw-text-an-flat-disabled-text"
        >
          {{ $Global.formatBytes(file.size) }}
        </small>
      </div>
    </div>
    <div
      v-if="Array.isArray(filesForDownloads)"
      class="tw-flex tw-flex-wrap"
    >
      <div
        v-for="(file) in filesForDownloads"
        :key="file.id"
      >
        <div
          class="tw-flex tw-items-center file-download-link tw-pa-1"
          style="max-width: 190px;line-height: normal;overflow-wrap: anywhere"
          @click="download(file)"
        >
          <TwIconWrapper
            class="tw-text-an-flat-blue-btn"
            file-name="document-1"
            :size="20"
          />
          <small>
            {{ file.name }}
            <span
              v-if="file.size"
              class="tw-text-an-flat-disabled-text tw-whitespace-nowrap"
            >
              {{ $Global.formatBytes(file.size) }}
            </span>
          </small>
        </div>
      </div>
    </div>
    <small
      v-if="!filesImages.length && !filesForDownloads.length"
      class="tw-text-an-flat-disabled-text"
    >Отсутствуют</small>
  </div>
</template>

<script>
import API from '@/Api/Crm/File'
import TwIconWrapper from '@/components/tw-ui/svg/TwIconWrapper.vue'
import FileShowMixin from '@/mixins/FileShowMixin'

export default {
  name: 'EmailFilesAttached',
  mixins: [FileShowMixin],
  components: { TwIconWrapper },
  data () {
    return {
      API: API
    }
  }
}
</script>
