<template>
  <div>
    <TwSelectRelations
      v-model="form.complexId"
      class=""
      :disabled="formProcessing"
      label="Все ЖК"
      list-label="Выбрать ЖК"
      relation-name="complex"
      :default-filters="complexFilters"
    />
    <div class="tw-mt-[12px]">
      <div class="tw-mb-[15px]">
        Файлы шаблонов ходатайства:
      </div>
      <TwUploadFilesList
        v-model="form.filesId"
        label="Загрузить шаблоны"
        :api="rtdFileApi"
        file-type="petition_template"
        :multiple="true"
        :disabled="formProcessing"
      />
      <div
        v-if="$v.form.filesId.$invalid"
        class="tw-text-an-flat-red-main tw-text-main-sm2 tw-my-[10px]"
      >
        * Прикрепите шаблон ходатайства
      </div>
    </div>
    <div>
      <TwCardBlockFormActions
        :title-submit="formType === 'create' ? 'Создать' : 'Изменить'"
        :handle-submit="handleSubmit"
        :handle-cancel="handleCancel"
        :disable-submit="!isEdited || formProcessing || $v.form.$invalid"
        :disable-cancel="formProcessing"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { required } from 'vuelidate/lib/validators'
import SettingsPetitionTemplate from '@/Api/Rtd/RequestToDeveloper/Settings/PetitionTemplate'
import rtdFileApi from '@/Api/Rtd/File'
import FormCreateEditMixin from '@/mixins/FormCreateEditMixin'
import TwUploadFilesList from '@/components/tw-ui/ui-kit/files/TwUploadFilesList.vue'
import TwSelectRelations from '@/components/tw-ui/ui-kit/input/select-relations/TwSelectRelations.vue'
import TwCardBlockFormActions from '@/components/core/ViewPanel/Card/TwCardBlockFormActions.vue'

export default defineComponent({
  name: 'BuilderPetitionTemplatesForm',
  components: { TwCardBlockFormActions, TwSelectRelations, TwUploadFilesList },
  mixins: [FormCreateEditMixin],
  props: {
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      rtdFileApi,
      API: SettingsPetitionTemplate,
      form: {}
    }
  },
  validations () {
    return {
      form: {
        filesId: {
          required
        }
      }
    }
  },
  computed: {
    complexFilters () {
      return {
        builderId: this.companyId ? [this.companyId] : []
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$v.$reset()
      this.$v.form.$touch()
      if (this.formProcessing === true) {
        return
      }
      if (!this.$v.form.$invalid) {
        this.formProcessing = true
        this.form.companyId = this.companyId
        const request = this.formType === 'create'
          ? this.API.store(this.form)
          : this.API.update(this.targetId, this.form)

        request.then(({ data }) => {
          if (this.formType === 'create') {
            this.$emit('create', data.data)
          } else {
            this.$emit('update', data.data)
          }
        }).finally(() => {
          this.formProcessing = false
        })
      }
    },
    presetData () {
      return new Promise((resolve) => {
        if (this.preset) {
          for (const key in this.preset) {
            if (key === 'files') {
              this.$set(this.form, 'filesId', this.preset[key].map(i => i.id))
            } else {
              this.$set(this.form, key, this.preset[key])
            }
          }
        }
        resolve()
      })
    }
  }
})
</script>

<style scoped>

</style>
